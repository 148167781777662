/**
 * A reusable input field component for numerical values that debounces the onChangeEvent.
 * The debounced processing helps minimize the number of submit events.
 */
import { TextField, InputAdornment, TextFieldProps } from '@mui/material';
import { NumberFormatCustom } from 'components/user_inputs/NumberFormatCustom';
import { ChangeEvent } from 'react';

// Ask Copilot why this is made like this
type NumericTextInputProps = TextFieldProps & {
  value: string;
  missingValue?: number | undefined;
  // Handler of onChange event which directly gets access to the parsed numeric value
  // if
  onChangeCustom?: (value: number | undefined) => void;
  disabled?: boolean;
};

export const NumericTextInput = ({
  value,
  // setValue,
  //eslint-disable-next-line
  onChangeCustom = (_v: number | undefined) => {},
  missingValue = 0,
  disabled = false,
  sx = { maxWidth: '200px' },
  inputProps = { style: { textAlign: 'right' } },
  InputProps = {
    inputComponent: NumberFormatCustom as any
  },
  ...props
}: NumericTextInputProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '') {
      onChangeCustom(missingValue);
    } else {
      const parsedValue = parseFloat(value);
      onChangeCustom(isNaN(parsedValue) ? missingValue : parsedValue);
    }
    // debouncedChange(event.target.value);
  };
  const { color, ...otherProps } = props;
  // Ensure color is one of the expected values
  const validColors = [
    'error',
    'primary',
    'secondary',
    'info',
    'success',
    'warning'
  ];
  const textFieldColor = validColors.includes(color as string)
    ? (color as
        | 'error'
        | 'primary'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning')
    : undefined;

  return (
    <TextField
      {...otherProps}
      color={textFieldColor}
      value={value}
      onChange={handleChange}
      size="medium"
      sx={sx}
      inputProps={inputProps}
      InputProps={InputProps}
      disabled={disabled}
    />
  );
};

type RateInputProps = TextFieldProps & {
  value: string;
  minRate?: number;
  maxRate?: number;
  step?: number;
  // Handler of onChange event which directly gets access to the parsed numeric value
  onChangeCustom: (value: number) => void;
};

export const RateInput = ({
  value,
  minRate = -10,
  maxRate = 10,
  step = 0.1,
  onChangeCustom,
  sx = { maxWidth: '100px' },
  ...props
}: RateInputProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '') {
      // missing rate is always equivalent to rate 0
      onChangeCustom(0);
    } else {
      const parsedValue = parseFloat(value);

      // TODO: check the min and max is a debounced way?
      if (parsedValue < minRate) {
        onChangeCustom(minRate);
      } else if (parsedValue > maxRate) {
        onChangeCustom(maxRate);
      } else {
        onChangeCustom(parsedValue);
      }
    }
  };
  const { color, ...otherProps } = props;
  // Ensure color is one of the expected values
  const validColors = [
    'error',
    'primary',
    'secondary',
    'info',
    'success',
    'warning'
  ];
  const textFieldColor = validColors.includes(color as string)
    ? (color as
        | 'error'
        | 'primary'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning')
    : undefined;

  return (
    <TextField
      {...otherProps}
      color={textFieldColor}
      value={value}
      onChange={handleChange}
      size="medium"
      sx={sx}
      inputProps={{ style: { textAlign: 'right' } }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        inputProps: {
          step: step,
          min: minRate,
          max: maxRate,
          type: 'number'
        }
      }}
    />
  );
};
