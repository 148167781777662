import { Grid, Slider, Typography } from '@mui/material';
// import NumberFormatCustom from './NumberFormatCustom'; // Import this from where it's defined
import { UserInputFormState } from 'clipper/project';
import {
  NumericTextInput,
  RateInput
} from 'components/user_inputs/NumericTextInput';
// import { useEffect, useState, useCallback } from 'react';
// import { debounce } from 'lodash';
// import { useTranslation } from 'react-i18next';

export interface MortgageFormState {
  principal: number;
  rate: number;
  monthly_payment: number;
  duration: number;
  principalErrorMessage: string;
}

interface MortgageFormProps {
  mortgage: MortgageFormState;
  updateMortgage: (newMortgage: MortgageFormState) => void;
}

const computeMonthlyPayment = (mortgage: MortgageFormState) => {
  const principal = mortgage.principal;
  const rate = mortgage.rate;
  const duration_years = mortgage.duration;
  const r = rate / 100 / 12;
  const n = duration_years * 12;
  if (r === 0) {
    return principal / n;
  }
  // TODO: verify this.
  return Math.round((principal * r * (1 + r) ** n) / ((1 + r) ** n - 1));
};

function MortgageForm({ mortgage, updateMortgage }: MortgageFormProps) {
  const monthly_payment = computeMonthlyPayment(mortgage);

  const updatePrincipal = (value: number | undefined) => {
    if (value === undefined) {
      throw new Error('Principal value here cannot be undefined');
    }
    const new_state = { ...mortgage, principal: value };
    updateMortgage(new_state);
  };

  const updateDuration = (
    event: Event,
    value: number | number[],
    _activeThumb: number
  ) => {
    if (Array.isArray(value)) {
      return;
    }
    const new_state = { ...mortgage, duration: value };
    updateMortgage(new_state);
  };

  const updateRate = (value: number) => {
    const new_state = { ...mortgage, rate: value };
    updateMortgage(new_state);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <NumericTextInput
          label="Principal"
          value={mortgage.principal.toString()}
          onChangeCustom={updatePrincipal}
          helperText={mortgage.principalErrorMessage}
          error={mortgage.principalErrorMessage !== ''}
        />
      </Grid>
      <Grid item>
        <RateInput
          label="Interest Rate"
          value={mortgage.rate.toString()}
          onChangeCustom={updateRate}
        />
      </Grid>
      <Grid item>
        <NumericTextInput
          label="Monthly Payment"
          value={monthly_payment.toString()}
          defaultValue={monthly_payment.toString()}
          disabled
        />
      </Grid>
      <Grid item>
        <Typography id="duration-slider" gutterBottom>
          Duration
        </Typography>
        <Slider
          value={mortgage.duration}
          onChange={updateDuration}
          aria-labelledby="duration-slider"
          valueLabelDisplay="on"
          step={5}
          marks
          min={5}
          max={35}
        />
      </Grid>
    </Grid>
  );
}

export interface FinancingPlanFormState extends UserInputFormState {
  name: string;
  inputs: {
    equity: number;
    equityErrorMessage: string;
    mortgage: MortgageFormState;
  };
  isValid: boolean;
}

export interface FinancingPlanFormProps {
  formState: FinancingPlanFormState;
  onFormStateUpdate: (newState: FinancingPlanFormState) => void;
  // The value of property for which financing is done.
  // THis is not part of the state, since is only used for validation
  target_value: number;
}

function FinancingPlanForm({
  formState,
  onFormStateUpdate,
  //eslint-disable-next-line
  target_value
}: FinancingPlanFormProps) {
  const handleEquityChange = (value: number | undefined) => {
    if (value === undefined) {
      throw new Error('Equity value here cannot be undefined');
    }
    const newState = {
      ...formState,
      inputs: {
        ...formState.inputs,
        equity: value
      }
    };
    onFormStateUpdate(newState);
  };

  const updateMortgage = (newMortgage: MortgageFormState) => {
    const newState = {
      ...formState,
      inputs: { ...formState.inputs, mortgage: newMortgage }
    };
    onFormStateUpdate(newState);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <NumericTextInput
          label="Equity"
          value={formState.inputs.equity.toString()}
          onChangeCustom={handleEquityChange}
          helperText={formState.inputs.equityErrorMessage}
          error={formState.inputs.equityErrorMessage !== ''}
        />
      </Grid>
      <Grid item>
        <MortgageForm
          mortgage={formState.inputs.mortgage}
          updateMortgage={updateMortgage}
        />
      </Grid>
    </Grid>
  );
}

export default FinancingPlanForm;

export { MortgageForm };
