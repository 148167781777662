import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Fab } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
// import { Typography } from '@mui/material';
import { ScenarioStatus, SimulationStatus } from 'clipper/project';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import { selectProjectStatus } from 'store/reducers/project';
import { throttle, isEqual } from 'lodash';
import Tooltip from '@mui/material/Tooltip';

function SimulationStatusAvatar() {
  const theme = useTheme();
  const simulationStatusFromStore: ScenarioStatus =
    useSelector(selectProjectStatus);
  const UPDATE_INTERVAL_MS = 1000;
  // this approach is used to make sure that the status changes smoothly
  const [internalSimulationStatus, setSimulationStatus] = useState(
    simulationStatusFromStore
  );

  const throttledUpdate = useRef(
    throttle(
      (status: ScenarioStatus) => setSimulationStatus(status),
      UPDATE_INTERVAL_MS,
      {
        leading: true,
        trailing: true
      }
    )
  ).current;

  useEffect(() => {
    // compare by value
    if (!isEqual(internalSimulationStatus, simulationStatusFromStore)) {
      throttledUpdate(simulationStatusFromStore);
    }
  }, [internalSimulationStatus, simulationStatusFromStore, throttledUpdate]);

  const status = internalSimulationStatus.status;
  const status_message = internalSimulationStatus.status_message;
  const color =
    status === SimulationStatus.UP_TO_DATE
      ? theme.palette.success.light
      : status === SimulationStatus.STALE
      ? theme.palette.warning.light
      : status === SimulationStatus.PENDING_UPDATE
      ? theme.palette.success.light
      : theme.palette.error.light;

  const StatusFab = styled(Fab)(() => ({
    backgroundColor: color,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: color
    },
    // TODO: Get rid of these hardcoded values and replace by something.
    width: '22px',
    height: '22px',
    minWidth: '22px',
    minHeight: '22px',
    // boxSizing: 'border-box',
    boxShadow: theme.shadows[0]
  }));

  // const [open, setOpen] = useState(false);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   // Prevent the default action of the click event
  //   event.preventDefault();
  //   // Toggle the open state
  //   setOpen(!open);
  // };

  return (
    <>
      <Tooltip title={status_message} enterDelay={100}>
        <StatusFab sx={{ ml: 1, mt: 1 }}>
          {status === SimulationStatus.PENDING_UPDATE ? (
            <CircularProgress color="success" size={22} thickness={8} />
          ) : status === SimulationStatus.UP_TO_DATE ? (
            <DoneIcon />
          ) : status === SimulationStatus.STALE ? (
            <WarningIcon />
          ) : (
            <ErrorIcon />
          )}
        </StatusFab>
      </Tooltip>
    </>
  );
}
SimulationStatusAvatar.whyDidYouRender = true;
export default SimulationStatusAvatar;
