
import AuthLogin from './auth-forms/AuthLogin';
import { Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const LoginForm = () => {
  return (
    <>
      <Grid container spacing={3} sx={{ maxWidth: 500 }}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              sx={{ mb: { xs: -0.5, sm: 0.5 } }}
            >
              <Typography variant="h3">Login</Typography>
              <Typography
                component={Link}
                to="/register"
                variant="body1"
                sx={{ textDecoration: 'none' }}
                color="primary"
              >
                Don&apos;t have an account?
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <AuthLogin />
          </Grid>
        </Grid>
    </>
    );
};

export default LoginForm;
