/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.21.12
 * source: generated_simulation_model.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
export namespace clipper {
    export enum ElementType {
        ASSET = 0,
        LIABILITY = 1,
        EXPENSE = 2,
        REVENUE = 3,
        OTHER = 4
    }
    export class ElementMetadata extends pb_1.Message {
        #one_of_decls: number[][] = [[2], [5]];
        constructor(data?: any[] | ({
            id?: number;
            type?: ElementType;
            export_final_state?: boolean;
        } & (({
            name?: string;
        }) | ({
            time_period?: ElementMetadata.TimePeriod;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("type" in data && data.type != undefined) {
                    this.type = data.type;
                }
                if ("export_final_state" in data && data.export_final_state != undefined) {
                    this.export_final_state = data.export_final_state;
                }
                if ("time_period" in data && data.time_period != undefined) {
                    this.time_period = data.time_period;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set id(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
        }
        get has_name() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get type() {
            return pb_1.Message.getFieldWithDefault(this, 3, ElementType.ASSET) as ElementType;
        }
        set type(value: ElementType) {
            pb_1.Message.setField(this, 3, value);
        }
        get export_final_state() {
            return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
        }
        set export_final_state(value: boolean) {
            pb_1.Message.setField(this, 4, value);
        }
        get time_period() {
            return pb_1.Message.getWrapperField(this, ElementMetadata.TimePeriod, 5) as ElementMetadata.TimePeriod;
        }
        set time_period(value: ElementMetadata.TimePeriod) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[1], value);
        }
        get has_time_period() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get _name() {
            const cases: {
                [index: number]: "none" | "name";
            } = {
                0: "none",
                2: "name"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        get _time_period() {
            const cases: {
                [index: number]: "none" | "time_period";
            } = {
                0: "none",
                5: "time_period"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5])];
        }
        static fromObject(data: {
            id?: number;
            name?: string;
            type?: ElementType;
            export_final_state?: boolean;
            time_period?: ReturnType<typeof ElementMetadata.TimePeriod.prototype.toObject>;
        }): ElementMetadata {
            const message = new ElementMetadata({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.type != null) {
                message.type = data.type;
            }
            if (data.export_final_state != null) {
                message.export_final_state = data.export_final_state;
            }
            if (data.time_period != null) {
                message.time_period = ElementMetadata.TimePeriod.fromObject(data.time_period);
            }
            return message;
        }
        toObject() {
            const data: {
                id?: number;
                name?: string;
                type?: ElementType;
                export_final_state?: boolean;
                time_period?: ReturnType<typeof ElementMetadata.TimePeriod.prototype.toObject>;
            } = {};
            if (this.id != null) {
                data.id = this.id;
            }
            if (this.name != null) {
                data.name = this.name;
            }
            if (this.type != null) {
                data.type = this.type;
            }
            if (this.export_final_state != null) {
                data.export_final_state = this.export_final_state;
            }
            if (this.time_period != null) {
                data.time_period = this.time_period.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id != 0)
                writer.writeInt32(1, this.id);
            if (this.has_name)
                writer.writeString(2, this.name);
            if (this.type != ElementType.ASSET)
                writer.writeEnum(3, this.type);
            if (this.export_final_state != false)
                writer.writeBool(4, this.export_final_state);
            if (this.has_time_period)
                writer.writeMessage(5, this.time_period, () => this.time_period.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ElementMetadata {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ElementMetadata();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readInt32();
                        break;
                    case 2:
                        message.name = reader.readString();
                        break;
                    case 3:
                        message.type = reader.readEnum();
                        break;
                    case 4:
                        message.export_final_state = reader.readBool();
                        break;
                    case 5:
                        reader.readMessage(message.time_period, () => message.time_period = ElementMetadata.TimePeriod.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ElementMetadata {
            return ElementMetadata.deserialize(bytes);
        }
    }
    export namespace ElementMetadata {
        export class TimePeriod extends pb_1.Message {
            #one_of_decls: number[][] = [[1], [2]];
            constructor(data?: any[] | ({} & (({
                start_time?: number;
            }) | ({
                end_time?: number;
            })))) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("start_time" in data && data.start_time != undefined) {
                        this.start_time = data.start_time;
                    }
                    if ("end_time" in data && data.end_time != undefined) {
                        this.end_time = data.end_time;
                    }
                }
            }
            get start_time() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set start_time(value: number) {
                pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0], value);
            }
            get has_start_time() {
                return pb_1.Message.getField(this, 1) != null;
            }
            get end_time() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set end_time(value: number) {
                pb_1.Message.setOneofField(this, 2, this.#one_of_decls[1], value);
            }
            get has_end_time() {
                return pb_1.Message.getField(this, 2) != null;
            }
            get _start_time() {
                const cases: {
                    [index: number]: "none" | "start_time";
                } = {
                    0: "none",
                    1: "start_time"
                };
                return cases[pb_1.Message.computeOneofCase(this, [1])];
            }
            get _end_time() {
                const cases: {
                    [index: number]: "none" | "end_time";
                } = {
                    0: "none",
                    2: "end_time"
                };
                return cases[pb_1.Message.computeOneofCase(this, [2])];
            }
            static fromObject(data: {
                start_time?: number;
                end_time?: number;
            }): TimePeriod {
                const message = new TimePeriod({});
                if (data.start_time != null) {
                    message.start_time = data.start_time;
                }
                if (data.end_time != null) {
                    message.end_time = data.end_time;
                }
                return message;
            }
            toObject() {
                const data: {
                    start_time?: number;
                    end_time?: number;
                } = {};
                if (this.start_time != null) {
                    data.start_time = this.start_time;
                }
                if (this.end_time != null) {
                    data.end_time = this.end_time;
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.has_start_time)
                    writer.writeInt32(1, this.start_time);
                if (this.has_end_time)
                    writer.writeInt32(2, this.end_time);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TimePeriod {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TimePeriod();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.start_time = reader.readInt32();
                            break;
                        case 2:
                            message.end_time = reader.readInt32();
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static deserializeBinary(bytes: Uint8Array): TimePeriod {
                return TimePeriod.deserialize(bytes);
            }
        }
    }
    export class Employment extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            metadata?: ElementMetadata;
            salary?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("metadata" in data && data.metadata != undefined) {
                    this.metadata = data.metadata;
                }
                if ("salary" in data && data.salary != undefined) {
                    this.salary = data.salary;
                }
            }
        }
        get metadata() {
            return pb_1.Message.getWrapperField(this, ElementMetadata, 1) as ElementMetadata;
        }
        set metadata(value: ElementMetadata) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_metadata() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get salary() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set salary(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
            salary?: number;
        }): Employment {
            const message = new Employment({});
            if (data.metadata != null) {
                message.metadata = ElementMetadata.fromObject(data.metadata);
            }
            if (data.salary != null) {
                message.salary = data.salary;
            }
            return message;
        }
        toObject() {
            const data: {
                metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
                salary?: number;
            } = {};
            if (this.metadata != null) {
                data.metadata = this.metadata.toObject();
            }
            if (this.salary != null) {
                data.salary = this.salary;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_metadata)
                writer.writeMessage(1, this.metadata, () => this.metadata.serialize(writer));
            if (this.salary != 0)
                writer.writeDouble(2, this.salary);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Employment {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Employment();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.metadata, () => message.metadata = ElementMetadata.deserialize(reader));
                        break;
                    case 2:
                        message.salary = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Employment {
            return Employment.deserialize(bytes);
        }
    }
    export class EmploymentState extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            current_salary?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("current_salary" in data && data.current_salary != undefined) {
                    this.current_salary = data.current_salary;
                }
            }
        }
        get current_salary() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set current_salary(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            current_salary?: number;
        }): EmploymentState {
            const message = new EmploymentState({});
            if (data.current_salary != null) {
                message.current_salary = data.current_salary;
            }
            return message;
        }
        toObject() {
            const data: {
                current_salary?: number;
            } = {};
            if (this.current_salary != null) {
                data.current_salary = this.current_salary;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.current_salary != 0)
                writer.writeDouble(1, this.current_salary);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EmploymentState {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new EmploymentState();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.current_salary = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): EmploymentState {
            return EmploymentState.deserialize(bytes);
        }
    }
    export class CheckingAccount extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            metadata?: ElementMetadata;
            balance?: number;
            reinvest_savings?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("metadata" in data && data.metadata != undefined) {
                    this.metadata = data.metadata;
                }
                if ("balance" in data && data.balance != undefined) {
                    this.balance = data.balance;
                }
                if ("reinvest_savings" in data && data.reinvest_savings != undefined) {
                    this.reinvest_savings = data.reinvest_savings;
                }
            }
        }
        get metadata() {
            return pb_1.Message.getWrapperField(this, ElementMetadata, 1) as ElementMetadata;
        }
        set metadata(value: ElementMetadata) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_metadata() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get balance() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set balance(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get reinvest_savings() {
            return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
        }
        set reinvest_savings(value: boolean) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
            balance?: number;
            reinvest_savings?: boolean;
        }): CheckingAccount {
            const message = new CheckingAccount({});
            if (data.metadata != null) {
                message.metadata = ElementMetadata.fromObject(data.metadata);
            }
            if (data.balance != null) {
                message.balance = data.balance;
            }
            if (data.reinvest_savings != null) {
                message.reinvest_savings = data.reinvest_savings;
            }
            return message;
        }
        toObject() {
            const data: {
                metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
                balance?: number;
                reinvest_savings?: boolean;
            } = {};
            if (this.metadata != null) {
                data.metadata = this.metadata.toObject();
            }
            if (this.balance != null) {
                data.balance = this.balance;
            }
            if (this.reinvest_savings != null) {
                data.reinvest_savings = this.reinvest_savings;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_metadata)
                writer.writeMessage(1, this.metadata, () => this.metadata.serialize(writer));
            if (this.balance != 0)
                writer.writeDouble(2, this.balance);
            if (this.reinvest_savings != false)
                writer.writeBool(3, this.reinvest_savings);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CheckingAccount {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CheckingAccount();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.metadata, () => message.metadata = ElementMetadata.deserialize(reader));
                        break;
                    case 2:
                        message.balance = reader.readDouble();
                        break;
                    case 3:
                        message.reinvest_savings = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): CheckingAccount {
            return CheckingAccount.deserialize(bytes);
        }
    }
    export class CheckingAccountState extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            current_balance?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("current_balance" in data && data.current_balance != undefined) {
                    this.current_balance = data.current_balance;
                }
            }
        }
        get current_balance() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set current_balance(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            current_balance?: number;
        }): CheckingAccountState {
            const message = new CheckingAccountState({});
            if (data.current_balance != null) {
                message.current_balance = data.current_balance;
            }
            return message;
        }
        toObject() {
            const data: {
                current_balance?: number;
            } = {};
            if (this.current_balance != null) {
                data.current_balance = this.current_balance;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.current_balance != 0)
                writer.writeDouble(1, this.current_balance);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CheckingAccountState {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CheckingAccountState();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.current_balance = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): CheckingAccountState {
            return CheckingAccountState.deserialize(bytes);
        }
    }
    export class Investment extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            metadata?: ElementMetadata;
            balance?: number;
            interest_rate?: number;
            periodic_deposit?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("metadata" in data && data.metadata != undefined) {
                    this.metadata = data.metadata;
                }
                if ("balance" in data && data.balance != undefined) {
                    this.balance = data.balance;
                }
                if ("interest_rate" in data && data.interest_rate != undefined) {
                    this.interest_rate = data.interest_rate;
                }
                if ("periodic_deposit" in data && data.periodic_deposit != undefined) {
                    this.periodic_deposit = data.periodic_deposit;
                }
            }
        }
        get metadata() {
            return pb_1.Message.getWrapperField(this, ElementMetadata, 1) as ElementMetadata;
        }
        set metadata(value: ElementMetadata) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_metadata() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get balance() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set balance(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get interest_rate() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set interest_rate(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get periodic_deposit() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set periodic_deposit(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
            balance?: number;
            interest_rate?: number;
            periodic_deposit?: number;
        }): Investment {
            const message = new Investment({});
            if (data.metadata != null) {
                message.metadata = ElementMetadata.fromObject(data.metadata);
            }
            if (data.balance != null) {
                message.balance = data.balance;
            }
            if (data.interest_rate != null) {
                message.interest_rate = data.interest_rate;
            }
            if (data.periodic_deposit != null) {
                message.periodic_deposit = data.periodic_deposit;
            }
            return message;
        }
        toObject() {
            const data: {
                metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
                balance?: number;
                interest_rate?: number;
                periodic_deposit?: number;
            } = {};
            if (this.metadata != null) {
                data.metadata = this.metadata.toObject();
            }
            if (this.balance != null) {
                data.balance = this.balance;
            }
            if (this.interest_rate != null) {
                data.interest_rate = this.interest_rate;
            }
            if (this.periodic_deposit != null) {
                data.periodic_deposit = this.periodic_deposit;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_metadata)
                writer.writeMessage(1, this.metadata, () => this.metadata.serialize(writer));
            if (this.balance != 0)
                writer.writeDouble(2, this.balance);
            if (this.interest_rate != 0)
                writer.writeDouble(3, this.interest_rate);
            if (this.periodic_deposit != 0)
                writer.writeDouble(4, this.periodic_deposit);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Investment {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Investment();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.metadata, () => message.metadata = ElementMetadata.deserialize(reader));
                        break;
                    case 2:
                        message.balance = reader.readDouble();
                        break;
                    case 3:
                        message.interest_rate = reader.readDouble();
                        break;
                    case 4:
                        message.periodic_deposit = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Investment {
            return Investment.deserialize(bytes);
        }
    }
    export class InvestmentState extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            current_balance?: number;
            current_interest_rate?: number;
            current_periodic_deposit?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("current_balance" in data && data.current_balance != undefined) {
                    this.current_balance = data.current_balance;
                }
                if ("current_interest_rate" in data && data.current_interest_rate != undefined) {
                    this.current_interest_rate = data.current_interest_rate;
                }
                if ("current_periodic_deposit" in data && data.current_periodic_deposit != undefined) {
                    this.current_periodic_deposit = data.current_periodic_deposit;
                }
            }
        }
        get current_balance() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set current_balance(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get current_interest_rate() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set current_interest_rate(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get current_periodic_deposit() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set current_periodic_deposit(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            current_balance?: number;
            current_interest_rate?: number;
            current_periodic_deposit?: number;
        }): InvestmentState {
            const message = new InvestmentState({});
            if (data.current_balance != null) {
                message.current_balance = data.current_balance;
            }
            if (data.current_interest_rate != null) {
                message.current_interest_rate = data.current_interest_rate;
            }
            if (data.current_periodic_deposit != null) {
                message.current_periodic_deposit = data.current_periodic_deposit;
            }
            return message;
        }
        toObject() {
            const data: {
                current_balance?: number;
                current_interest_rate?: number;
                current_periodic_deposit?: number;
            } = {};
            if (this.current_balance != null) {
                data.current_balance = this.current_balance;
            }
            if (this.current_interest_rate != null) {
                data.current_interest_rate = this.current_interest_rate;
            }
            if (this.current_periodic_deposit != null) {
                data.current_periodic_deposit = this.current_periodic_deposit;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.current_balance != 0)
                writer.writeDouble(1, this.current_balance);
            if (this.current_interest_rate != 0)
                writer.writeDouble(2, this.current_interest_rate);
            if (this.current_periodic_deposit != 0)
                writer.writeDouble(3, this.current_periodic_deposit);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InvestmentState {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InvestmentState();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.current_balance = reader.readDouble();
                        break;
                    case 2:
                        message.current_interest_rate = reader.readDouble();
                        break;
                    case 3:
                        message.current_periodic_deposit = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): InvestmentState {
            return InvestmentState.deserialize(bytes);
        }
    }
    export class Expense extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            metadata?: ElementMetadata;
            amount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("metadata" in data && data.metadata != undefined) {
                    this.metadata = data.metadata;
                }
                if ("amount" in data && data.amount != undefined) {
                    this.amount = data.amount;
                }
            }
        }
        get metadata() {
            return pb_1.Message.getWrapperField(this, ElementMetadata, 1) as ElementMetadata;
        }
        set metadata(value: ElementMetadata) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_metadata() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get amount() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set amount(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data: {
            metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
            amount?: number;
        }): Expense {
            const message = new Expense({});
            if (data.metadata != null) {
                message.metadata = ElementMetadata.fromObject(data.metadata);
            }
            if (data.amount != null) {
                message.amount = data.amount;
            }
            return message;
        }
        toObject() {
            const data: {
                metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
                amount?: number;
            } = {};
            if (this.metadata != null) {
                data.metadata = this.metadata.toObject();
            }
            if (this.amount != null) {
                data.amount = this.amount;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_metadata)
                writer.writeMessage(1, this.metadata, () => this.metadata.serialize(writer));
            if (this.amount != 0)
                writer.writeDouble(2, this.amount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Expense {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Expense();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.metadata, () => message.metadata = ElementMetadata.deserialize(reader));
                        break;
                    case 2:
                        message.amount = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Expense {
            return Expense.deserialize(bytes);
        }
    }
    export class ExpenseState extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            current_amount?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("current_amount" in data && data.current_amount != undefined) {
                    this.current_amount = data.current_amount;
                }
            }
        }
        get current_amount() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set current_amount(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data: {
            current_amount?: number;
        }): ExpenseState {
            const message = new ExpenseState({});
            if (data.current_amount != null) {
                message.current_amount = data.current_amount;
            }
            return message;
        }
        toObject() {
            const data: {
                current_amount?: number;
            } = {};
            if (this.current_amount != null) {
                data.current_amount = this.current_amount;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.current_amount != 0)
                writer.writeDouble(1, this.current_amount);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ExpenseState {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ExpenseState();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.current_amount = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ExpenseState {
            return ExpenseState.deserialize(bytes);
        }
    }
    export class Appartment extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            metadata?: ElementMetadata;
            value?: number;
            growth_rate?: number;
            maintenance_cost?: number;
            purchase_fee?: number;
            tax?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("metadata" in data && data.metadata != undefined) {
                    this.metadata = data.metadata;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("growth_rate" in data && data.growth_rate != undefined) {
                    this.growth_rate = data.growth_rate;
                }
                if ("maintenance_cost" in data && data.maintenance_cost != undefined) {
                    this.maintenance_cost = data.maintenance_cost;
                }
                if ("purchase_fee" in data && data.purchase_fee != undefined) {
                    this.purchase_fee = data.purchase_fee;
                }
                if ("tax" in data && data.tax != undefined) {
                    this.tax = data.tax;
                }
            }
        }
        get metadata() {
            return pb_1.Message.getWrapperField(this, ElementMetadata, 1) as ElementMetadata;
        }
        set metadata(value: ElementMetadata) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_metadata() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get growth_rate() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set growth_rate(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get maintenance_cost() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set maintenance_cost(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get purchase_fee() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set purchase_fee(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get tax() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set tax(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data: {
            metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
            value?: number;
            growth_rate?: number;
            maintenance_cost?: number;
            purchase_fee?: number;
            tax?: number;
        }): Appartment {
            const message = new Appartment({});
            if (data.metadata != null) {
                message.metadata = ElementMetadata.fromObject(data.metadata);
            }
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.growth_rate != null) {
                message.growth_rate = data.growth_rate;
            }
            if (data.maintenance_cost != null) {
                message.maintenance_cost = data.maintenance_cost;
            }
            if (data.purchase_fee != null) {
                message.purchase_fee = data.purchase_fee;
            }
            if (data.tax != null) {
                message.tax = data.tax;
            }
            return message;
        }
        toObject() {
            const data: {
                metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
                value?: number;
                growth_rate?: number;
                maintenance_cost?: number;
                purchase_fee?: number;
                tax?: number;
            } = {};
            if (this.metadata != null) {
                data.metadata = this.metadata.toObject();
            }
            if (this.value != null) {
                data.value = this.value;
            }
            if (this.growth_rate != null) {
                data.growth_rate = this.growth_rate;
            }
            if (this.maintenance_cost != null) {
                data.maintenance_cost = this.maintenance_cost;
            }
            if (this.purchase_fee != null) {
                data.purchase_fee = this.purchase_fee;
            }
            if (this.tax != null) {
                data.tax = this.tax;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_metadata)
                writer.writeMessage(1, this.metadata, () => this.metadata.serialize(writer));
            if (this.value != 0)
                writer.writeFloat(2, this.value);
            if (this.growth_rate != 0)
                writer.writeFloat(3, this.growth_rate);
            if (this.maintenance_cost != 0)
                writer.writeFloat(4, this.maintenance_cost);
            if (this.purchase_fee != 0)
                writer.writeFloat(5, this.purchase_fee);
            if (this.tax != 0)
                writer.writeFloat(6, this.tax);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Appartment {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Appartment();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.metadata, () => message.metadata = ElementMetadata.deserialize(reader));
                        break;
                    case 2:
                        message.value = reader.readFloat();
                        break;
                    case 3:
                        message.growth_rate = reader.readFloat();
                        break;
                    case 4:
                        message.maintenance_cost = reader.readFloat();
                        break;
                    case 5:
                        message.purchase_fee = reader.readFloat();
                        break;
                    case 6:
                        message.tax = reader.readFloat();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Appartment {
            return Appartment.deserialize(bytes);
        }
    }
    export class AppartmentState extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            current_value?: number;
            current_maintenance_cost?: number;
            current_tax?: number;
            current_growth_rate?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("current_value" in data && data.current_value != undefined) {
                    this.current_value = data.current_value;
                }
                if ("current_maintenance_cost" in data && data.current_maintenance_cost != undefined) {
                    this.current_maintenance_cost = data.current_maintenance_cost;
                }
                if ("current_tax" in data && data.current_tax != undefined) {
                    this.current_tax = data.current_tax;
                }
                if ("current_growth_rate" in data && data.current_growth_rate != undefined) {
                    this.current_growth_rate = data.current_growth_rate;
                }
            }
        }
        get current_value() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set current_value(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get current_maintenance_cost() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set current_maintenance_cost(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get current_tax() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set current_tax(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get current_growth_rate() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set current_growth_rate(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            current_value?: number;
            current_maintenance_cost?: number;
            current_tax?: number;
            current_growth_rate?: number;
        }): AppartmentState {
            const message = new AppartmentState({});
            if (data.current_value != null) {
                message.current_value = data.current_value;
            }
            if (data.current_maintenance_cost != null) {
                message.current_maintenance_cost = data.current_maintenance_cost;
            }
            if (data.current_tax != null) {
                message.current_tax = data.current_tax;
            }
            if (data.current_growth_rate != null) {
                message.current_growth_rate = data.current_growth_rate;
            }
            return message;
        }
        toObject() {
            const data: {
                current_value?: number;
                current_maintenance_cost?: number;
                current_tax?: number;
                current_growth_rate?: number;
            } = {};
            if (this.current_value != null) {
                data.current_value = this.current_value;
            }
            if (this.current_maintenance_cost != null) {
                data.current_maintenance_cost = this.current_maintenance_cost;
            }
            if (this.current_tax != null) {
                data.current_tax = this.current_tax;
            }
            if (this.current_growth_rate != null) {
                data.current_growth_rate = this.current_growth_rate;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.current_value != 0)
                writer.writeFloat(1, this.current_value);
            if (this.current_maintenance_cost != 0)
                writer.writeFloat(2, this.current_maintenance_cost);
            if (this.current_tax != 0)
                writer.writeFloat(3, this.current_tax);
            if (this.current_growth_rate != 0)
                writer.writeFloat(4, this.current_growth_rate);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AppartmentState {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AppartmentState();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.current_value = reader.readFloat();
                        break;
                    case 2:
                        message.current_maintenance_cost = reader.readFloat();
                        break;
                    case 3:
                        message.current_tax = reader.readFloat();
                        break;
                    case 4:
                        message.current_growth_rate = reader.readFloat();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): AppartmentState {
            return AppartmentState.deserialize(bytes);
        }
    }
    export class Mortgage extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            metadata?: ElementMetadata;
            principal?: number;
            interest_rate?: number;
            duration_years?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("metadata" in data && data.metadata != undefined) {
                    this.metadata = data.metadata;
                }
                if ("principal" in data && data.principal != undefined) {
                    this.principal = data.principal;
                }
                if ("interest_rate" in data && data.interest_rate != undefined) {
                    this.interest_rate = data.interest_rate;
                }
                if ("duration_years" in data && data.duration_years != undefined) {
                    this.duration_years = data.duration_years;
                }
            }
        }
        get metadata() {
            return pb_1.Message.getWrapperField(this, ElementMetadata, 1) as ElementMetadata;
        }
        set metadata(value: ElementMetadata) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get has_metadata() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get principal() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set principal(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get interest_rate() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set interest_rate(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get duration_years() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set duration_years(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
            principal?: number;
            interest_rate?: number;
            duration_years?: number;
        }): Mortgage {
            const message = new Mortgage({});
            if (data.metadata != null) {
                message.metadata = ElementMetadata.fromObject(data.metadata);
            }
            if (data.principal != null) {
                message.principal = data.principal;
            }
            if (data.interest_rate != null) {
                message.interest_rate = data.interest_rate;
            }
            if (data.duration_years != null) {
                message.duration_years = data.duration_years;
            }
            return message;
        }
        toObject() {
            const data: {
                metadata?: ReturnType<typeof ElementMetadata.prototype.toObject>;
                principal?: number;
                interest_rate?: number;
                duration_years?: number;
            } = {};
            if (this.metadata != null) {
                data.metadata = this.metadata.toObject();
            }
            if (this.principal != null) {
                data.principal = this.principal;
            }
            if (this.interest_rate != null) {
                data.interest_rate = this.interest_rate;
            }
            if (this.duration_years != null) {
                data.duration_years = this.duration_years;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_metadata)
                writer.writeMessage(1, this.metadata, () => this.metadata.serialize(writer));
            if (this.principal != 0)
                writer.writeDouble(2, this.principal);
            if (this.interest_rate != 0)
                writer.writeDouble(3, this.interest_rate);
            if (this.duration_years != 0)
                writer.writeInt32(4, this.duration_years);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Mortgage {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Mortgage();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.metadata, () => message.metadata = ElementMetadata.deserialize(reader));
                        break;
                    case 2:
                        message.principal = reader.readDouble();
                        break;
                    case 3:
                        message.interest_rate = reader.readDouble();
                        break;
                    case 4:
                        message.duration_years = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Mortgage {
            return Mortgage.deserialize(bytes);
        }
    }
    export class MortgageState extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            current_principal?: number;
            current_interest_rate?: number;
            interest_paid?: number;
            principal_paid?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("current_principal" in data && data.current_principal != undefined) {
                    this.current_principal = data.current_principal;
                }
                if ("current_interest_rate" in data && data.current_interest_rate != undefined) {
                    this.current_interest_rate = data.current_interest_rate;
                }
                if ("interest_paid" in data && data.interest_paid != undefined) {
                    this.interest_paid = data.interest_paid;
                }
                if ("principal_paid" in data && data.principal_paid != undefined) {
                    this.principal_paid = data.principal_paid;
                }
            }
        }
        get current_principal() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set current_principal(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get current_interest_rate() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set current_interest_rate(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get interest_paid() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set interest_paid(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get principal_paid() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set principal_paid(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data: {
            current_principal?: number;
            current_interest_rate?: number;
            interest_paid?: number;
            principal_paid?: number;
        }): MortgageState {
            const message = new MortgageState({});
            if (data.current_principal != null) {
                message.current_principal = data.current_principal;
            }
            if (data.current_interest_rate != null) {
                message.current_interest_rate = data.current_interest_rate;
            }
            if (data.interest_paid != null) {
                message.interest_paid = data.interest_paid;
            }
            if (data.principal_paid != null) {
                message.principal_paid = data.principal_paid;
            }
            return message;
        }
        toObject() {
            const data: {
                current_principal?: number;
                current_interest_rate?: number;
                interest_paid?: number;
                principal_paid?: number;
            } = {};
            if (this.current_principal != null) {
                data.current_principal = this.current_principal;
            }
            if (this.current_interest_rate != null) {
                data.current_interest_rate = this.current_interest_rate;
            }
            if (this.interest_paid != null) {
                data.interest_paid = this.interest_paid;
            }
            if (this.principal_paid != null) {
                data.principal_paid = this.principal_paid;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.current_principal != 0)
                writer.writeDouble(1, this.current_principal);
            if (this.current_interest_rate != 0)
                writer.writeDouble(2, this.current_interest_rate);
            if (this.interest_paid != 0)
                writer.writeDouble(3, this.interest_paid);
            if (this.principal_paid != 0)
                writer.writeDouble(4, this.principal_paid);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): MortgageState {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new MortgageState();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.current_principal = reader.readDouble();
                        break;
                    case 2:
                        message.current_interest_rate = reader.readDouble();
                        break;
                    case 3:
                        message.interest_paid = reader.readDouble();
                        break;
                    case 4:
                        message.principal_paid = reader.readDouble();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): MortgageState {
            return MortgageState.deserialize(bytes);
        }
    }
    export class ModelElement extends pb_1.Message {
        #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6]];
        constructor(data?: any[] | ({} & (({
            employment?: Employment;
            checking_account?: never;
            investment?: never;
            expense?: never;
            appartment?: never;
            mortgage?: never;
        } | {
            employment?: never;
            checking_account?: CheckingAccount;
            investment?: never;
            expense?: never;
            appartment?: never;
            mortgage?: never;
        } | {
            employment?: never;
            checking_account?: never;
            investment?: Investment;
            expense?: never;
            appartment?: never;
            mortgage?: never;
        } | {
            employment?: never;
            checking_account?: never;
            investment?: never;
            expense?: Expense;
            appartment?: never;
            mortgage?: never;
        } | {
            employment?: never;
            checking_account?: never;
            investment?: never;
            expense?: never;
            appartment?: Appartment;
            mortgage?: never;
        } | {
            employment?: never;
            checking_account?: never;
            investment?: never;
            expense?: never;
            appartment?: never;
            mortgage?: Mortgage;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("employment" in data && data.employment != undefined) {
                    this.employment = data.employment;
                }
                if ("checking_account" in data && data.checking_account != undefined) {
                    this.checking_account = data.checking_account;
                }
                if ("investment" in data && data.investment != undefined) {
                    this.investment = data.investment;
                }
                if ("expense" in data && data.expense != undefined) {
                    this.expense = data.expense;
                }
                if ("appartment" in data && data.appartment != undefined) {
                    this.appartment = data.appartment;
                }
                if ("mortgage" in data && data.mortgage != undefined) {
                    this.mortgage = data.mortgage;
                }
            }
        }
        get employment() {
            return pb_1.Message.getWrapperField(this, Employment, 1) as Employment;
        }
        set employment(value: Employment) {
            pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
        }
        get has_employment() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get checking_account() {
            return pb_1.Message.getWrapperField(this, CheckingAccount, 2) as CheckingAccount;
        }
        set checking_account(value: CheckingAccount) {
            pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
        }
        get has_checking_account() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get investment() {
            return pb_1.Message.getWrapperField(this, Investment, 3) as Investment;
        }
        set investment(value: Investment) {
            pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
        }
        get has_investment() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get expense() {
            return pb_1.Message.getWrapperField(this, Expense, 4) as Expense;
        }
        set expense(value: Expense) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
        }
        get has_expense() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get appartment() {
            return pb_1.Message.getWrapperField(this, Appartment, 5) as Appartment;
        }
        set appartment(value: Appartment) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
        }
        get has_appartment() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get mortgage() {
            return pb_1.Message.getWrapperField(this, Mortgage, 6) as Mortgage;
        }
        set mortgage(value: Mortgage) {
            pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
        }
        get has_mortgage() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get element() {
            const cases: {
                [index: number]: "none" | "employment" | "checking_account" | "investment" | "expense" | "appartment" | "mortgage";
            } = {
                0: "none",
                1: "employment",
                2: "checking_account",
                3: "investment",
                4: "expense",
                5: "appartment",
                6: "mortgage"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6])];
        }
        static fromObject(data: {
            employment?: ReturnType<typeof Employment.prototype.toObject>;
            checking_account?: ReturnType<typeof CheckingAccount.prototype.toObject>;
            investment?: ReturnType<typeof Investment.prototype.toObject>;
            expense?: ReturnType<typeof Expense.prototype.toObject>;
            appartment?: ReturnType<typeof Appartment.prototype.toObject>;
            mortgage?: ReturnType<typeof Mortgage.prototype.toObject>;
        }): ModelElement {
            const message = new ModelElement({});
            if (data.employment != null) {
                message.employment = Employment.fromObject(data.employment);
            }
            if (data.checking_account != null) {
                message.checking_account = CheckingAccount.fromObject(data.checking_account);
            }
            if (data.investment != null) {
                message.investment = Investment.fromObject(data.investment);
            }
            if (data.expense != null) {
                message.expense = Expense.fromObject(data.expense);
            }
            if (data.appartment != null) {
                message.appartment = Appartment.fromObject(data.appartment);
            }
            if (data.mortgage != null) {
                message.mortgage = Mortgage.fromObject(data.mortgage);
            }
            return message;
        }
        toObject() {
            const data: {
                employment?: ReturnType<typeof Employment.prototype.toObject>;
                checking_account?: ReturnType<typeof CheckingAccount.prototype.toObject>;
                investment?: ReturnType<typeof Investment.prototype.toObject>;
                expense?: ReturnType<typeof Expense.prototype.toObject>;
                appartment?: ReturnType<typeof Appartment.prototype.toObject>;
                mortgage?: ReturnType<typeof Mortgage.prototype.toObject>;
            } = {};
            if (this.employment != null) {
                data.employment = this.employment.toObject();
            }
            if (this.checking_account != null) {
                data.checking_account = this.checking_account.toObject();
            }
            if (this.investment != null) {
                data.investment = this.investment.toObject();
            }
            if (this.expense != null) {
                data.expense = this.expense.toObject();
            }
            if (this.appartment != null) {
                data.appartment = this.appartment.toObject();
            }
            if (this.mortgage != null) {
                data.mortgage = this.mortgage.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_employment)
                writer.writeMessage(1, this.employment, () => this.employment.serialize(writer));
            if (this.has_checking_account)
                writer.writeMessage(2, this.checking_account, () => this.checking_account.serialize(writer));
            if (this.has_investment)
                writer.writeMessage(3, this.investment, () => this.investment.serialize(writer));
            if (this.has_expense)
                writer.writeMessage(4, this.expense, () => this.expense.serialize(writer));
            if (this.has_appartment)
                writer.writeMessage(5, this.appartment, () => this.appartment.serialize(writer));
            if (this.has_mortgage)
                writer.writeMessage(6, this.mortgage, () => this.mortgage.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ModelElement {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ModelElement();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.employment, () => message.employment = Employment.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.checking_account, () => message.checking_account = CheckingAccount.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.investment, () => message.investment = Investment.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.expense, () => message.expense = Expense.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.appartment, () => message.appartment = Appartment.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.mortgage, () => message.mortgage = Mortgage.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ModelElement {
            return ModelElement.deserialize(bytes);
        }
    }
    export class RealEstatePurchaseEvent extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            time?: number;
            appartment?: Appartment;
            mortgage?: Mortgage;
            contribution?: number;
            start_of_period?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("time" in data && data.time != undefined) {
                    this.time = data.time;
                }
                if ("appartment" in data && data.appartment != undefined) {
                    this.appartment = data.appartment;
                }
                if ("mortgage" in data && data.mortgage != undefined) {
                    this.mortgage = data.mortgage;
                }
                if ("contribution" in data && data.contribution != undefined) {
                    this.contribution = data.contribution;
                }
                if ("start_of_period" in data && data.start_of_period != undefined) {
                    this.start_of_period = data.start_of_period;
                }
            }
        }
        get time() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set time(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get appartment() {
            return pb_1.Message.getWrapperField(this, Appartment, 2) as Appartment;
        }
        set appartment(value: Appartment) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get has_appartment() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get mortgage() {
            return pb_1.Message.getWrapperField(this, Mortgage, 3) as Mortgage;
        }
        set mortgage(value: Mortgage) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get has_mortgage() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get contribution() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set contribution(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get start_of_period() {
            return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
        }
        set start_of_period(value: boolean) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data: {
            time?: number;
            appartment?: ReturnType<typeof Appartment.prototype.toObject>;
            mortgage?: ReturnType<typeof Mortgage.prototype.toObject>;
            contribution?: number;
            start_of_period?: boolean;
        }): RealEstatePurchaseEvent {
            const message = new RealEstatePurchaseEvent({});
            if (data.time != null) {
                message.time = data.time;
            }
            if (data.appartment != null) {
                message.appartment = Appartment.fromObject(data.appartment);
            }
            if (data.mortgage != null) {
                message.mortgage = Mortgage.fromObject(data.mortgage);
            }
            if (data.contribution != null) {
                message.contribution = data.contribution;
            }
            if (data.start_of_period != null) {
                message.start_of_period = data.start_of_period;
            }
            return message;
        }
        toObject() {
            const data: {
                time?: number;
                appartment?: ReturnType<typeof Appartment.prototype.toObject>;
                mortgage?: ReturnType<typeof Mortgage.prototype.toObject>;
                contribution?: number;
                start_of_period?: boolean;
            } = {};
            if (this.time != null) {
                data.time = this.time;
            }
            if (this.appartment != null) {
                data.appartment = this.appartment.toObject();
            }
            if (this.mortgage != null) {
                data.mortgage = this.mortgage.toObject();
            }
            if (this.contribution != null) {
                data.contribution = this.contribution;
            }
            if (this.start_of_period != null) {
                data.start_of_period = this.start_of_period;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.time != 0)
                writer.writeInt32(1, this.time);
            if (this.has_appartment)
                writer.writeMessage(2, this.appartment, () => this.appartment.serialize(writer));
            if (this.has_mortgage)
                writer.writeMessage(3, this.mortgage, () => this.mortgage.serialize(writer));
            if (this.contribution != 0)
                writer.writeFloat(4, this.contribution);
            if (this.start_of_period != false)
                writer.writeBool(5, this.start_of_period);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RealEstatePurchaseEvent {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RealEstatePurchaseEvent();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.time = reader.readInt32();
                        break;
                    case 2:
                        reader.readMessage(message.appartment, () => message.appartment = Appartment.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.mortgage, () => message.mortgage = Mortgage.deserialize(reader));
                        break;
                    case 4:
                        message.contribution = reader.readFloat();
                        break;
                    case 5:
                        message.start_of_period = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RealEstatePurchaseEvent {
            return RealEstatePurchaseEvent.deserialize(bytes);
        }
    }
    export class RemoveElementEvent extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            time?: number;
            element_name?: number;
            start_of_period?: boolean;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("time" in data && data.time != undefined) {
                    this.time = data.time;
                }
                if ("element_name" in data && data.element_name != undefined) {
                    this.element_name = data.element_name;
                }
                if ("start_of_period" in data && data.start_of_period != undefined) {
                    this.start_of_period = data.start_of_period;
                }
            }
        }
        get time() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set time(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get element_name() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set element_name(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get start_of_period() {
            return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
        }
        set start_of_period(value: boolean) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data: {
            time?: number;
            element_name?: number;
            start_of_period?: boolean;
        }): RemoveElementEvent {
            const message = new RemoveElementEvent({});
            if (data.time != null) {
                message.time = data.time;
            }
            if (data.element_name != null) {
                message.element_name = data.element_name;
            }
            if (data.start_of_period != null) {
                message.start_of_period = data.start_of_period;
            }
            return message;
        }
        toObject() {
            const data: {
                time?: number;
                element_name?: number;
                start_of_period?: boolean;
            } = {};
            if (this.time != null) {
                data.time = this.time;
            }
            if (this.element_name != null) {
                data.element_name = this.element_name;
            }
            if (this.start_of_period != null) {
                data.start_of_period = this.start_of_period;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.time != 0)
                writer.writeInt32(1, this.time);
            if (this.element_name != 0)
                writer.writeInt32(2, this.element_name);
            if (this.start_of_period != false)
                writer.writeBool(3, this.start_of_period);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RemoveElementEvent {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RemoveElementEvent();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.time = reader.readInt32();
                        break;
                    case 2:
                        message.element_name = reader.readInt32();
                        break;
                    case 3:
                        message.start_of_period = reader.readBool();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): RemoveElementEvent {
            return RemoveElementEvent.deserialize(bytes);
        }
    }
    export class ModelEvent extends pb_1.Message {
        #one_of_decls: number[][] = [[1, 2]];
        constructor(data?: any[] | ({} & (({
            real_estate_purchase?: RealEstatePurchaseEvent;
            remove_element?: never;
        } | {
            real_estate_purchase?: never;
            remove_element?: RemoveElementEvent;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("real_estate_purchase" in data && data.real_estate_purchase != undefined) {
                    this.real_estate_purchase = data.real_estate_purchase;
                }
                if ("remove_element" in data && data.remove_element != undefined) {
                    this.remove_element = data.remove_element;
                }
            }
        }
        get real_estate_purchase() {
            return pb_1.Message.getWrapperField(this, RealEstatePurchaseEvent, 1) as RealEstatePurchaseEvent;
        }
        set real_estate_purchase(value: RealEstatePurchaseEvent) {
            pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
        }
        get has_real_estate_purchase() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get remove_element() {
            return pb_1.Message.getWrapperField(this, RemoveElementEvent, 2) as RemoveElementEvent;
        }
        set remove_element(value: RemoveElementEvent) {
            pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
        }
        get has_remove_element() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get event() {
            const cases: {
                [index: number]: "none" | "real_estate_purchase" | "remove_element";
            } = {
                0: "none",
                1: "real_estate_purchase",
                2: "remove_element"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1, 2])];
        }
        static fromObject(data: {
            real_estate_purchase?: ReturnType<typeof RealEstatePurchaseEvent.prototype.toObject>;
            remove_element?: ReturnType<typeof RemoveElementEvent.prototype.toObject>;
        }): ModelEvent {
            const message = new ModelEvent({});
            if (data.real_estate_purchase != null) {
                message.real_estate_purchase = RealEstatePurchaseEvent.fromObject(data.real_estate_purchase);
            }
            if (data.remove_element != null) {
                message.remove_element = RemoveElementEvent.fromObject(data.remove_element);
            }
            return message;
        }
        toObject() {
            const data: {
                real_estate_purchase?: ReturnType<typeof RealEstatePurchaseEvent.prototype.toObject>;
                remove_element?: ReturnType<typeof RemoveElementEvent.prototype.toObject>;
            } = {};
            if (this.real_estate_purchase != null) {
                data.real_estate_purchase = this.real_estate_purchase.toObject();
            }
            if (this.remove_element != null) {
                data.remove_element = this.remove_element.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_real_estate_purchase)
                writer.writeMessage(1, this.real_estate_purchase, () => this.real_estate_purchase.serialize(writer));
            if (this.has_remove_element)
                writer.writeMessage(2, this.remove_element, () => this.remove_element.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ModelEvent {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ModelEvent();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.real_estate_purchase, () => message.real_estate_purchase = RealEstatePurchaseEvent.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.remove_element, () => message.remove_element = RemoveElementEvent.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ModelEvent {
            return ModelEvent.deserialize(bytes);
        }
    }
    export class FinancialModel extends pb_1.Message {
        #one_of_decls: number[][] = [[3], [4]];
        constructor(data?: any[] | ({
            elements?: ModelElement[];
            events?: ModelEvent[];
        } & (({
            start_time?: number;
        }) | ({
            end_time?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("elements" in data && data.elements != undefined) {
                    this.elements = data.elements;
                }
                if ("events" in data && data.events != undefined) {
                    this.events = data.events;
                }
                if ("start_time" in data && data.start_time != undefined) {
                    this.start_time = data.start_time;
                }
                if ("end_time" in data && data.end_time != undefined) {
                    this.end_time = data.end_time;
                }
            }
        }
        get elements() {
            return pb_1.Message.getRepeatedWrapperField(this, ModelElement, 1) as ModelElement[];
        }
        set elements(value: ModelElement[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        get events() {
            return pb_1.Message.getRepeatedWrapperField(this, ModelEvent, 2) as ModelEvent[];
        }
        set events(value: ModelEvent[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        get start_time() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set start_time(value: number) {
            pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0], value);
        }
        get has_start_time() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get end_time() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set end_time(value: number) {
            pb_1.Message.setOneofField(this, 4, this.#one_of_decls[1], value);
        }
        get has_end_time() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get _start_time() {
            const cases: {
                [index: number]: "none" | "start_time";
            } = {
                0: "none",
                3: "start_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3])];
        }
        get _end_time() {
            const cases: {
                [index: number]: "none" | "end_time";
            } = {
                0: "none",
                4: "end_time"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4])];
        }
        static fromObject(data: {
            elements?: ReturnType<typeof ModelElement.prototype.toObject>[];
            events?: ReturnType<typeof ModelEvent.prototype.toObject>[];
            start_time?: number;
            end_time?: number;
        }): FinancialModel {
            const message = new FinancialModel({});
            if (data.elements != null) {
                message.elements = data.elements.map(item => ModelElement.fromObject(item));
            }
            if (data.events != null) {
                message.events = data.events.map(item => ModelEvent.fromObject(item));
            }
            if (data.start_time != null) {
                message.start_time = data.start_time;
            }
            if (data.end_time != null) {
                message.end_time = data.end_time;
            }
            return message;
        }
        toObject() {
            const data: {
                elements?: ReturnType<typeof ModelElement.prototype.toObject>[];
                events?: ReturnType<typeof ModelEvent.prototype.toObject>[];
                start_time?: number;
                end_time?: number;
            } = {};
            if (this.elements != null) {
                data.elements = this.elements.map((item: ModelElement) => item.toObject());
            }
            if (this.events != null) {
                data.events = this.events.map((item: ModelEvent) => item.toObject());
            }
            if (this.start_time != null) {
                data.start_time = this.start_time;
            }
            if (this.end_time != null) {
                data.end_time = this.end_time;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.elements.length)
                writer.writeRepeatedMessage(1, this.elements, (item: ModelElement) => item.serialize(writer));
            if (this.events.length)
                writer.writeRepeatedMessage(2, this.events, (item: ModelEvent) => item.serialize(writer));
            if (this.has_start_time)
                writer.writeInt32(3, this.start_time);
            if (this.has_end_time)
                writer.writeInt32(4, this.end_time);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FinancialModel {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FinancialModel();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.elements, () => pb_1.Message.addToRepeatedWrapperField(message, 1, ModelElement.deserialize(reader), ModelElement));
                        break;
                    case 2:
                        reader.readMessage(message.events, () => pb_1.Message.addToRepeatedWrapperField(message, 2, ModelEvent.deserialize(reader), ModelEvent));
                        break;
                    case 3:
                        message.start_time = reader.readInt32();
                        break;
                    case 4:
                        message.end_time = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): FinancialModel {
            return FinancialModel.deserialize(bytes);
        }
    }
    export class Metric extends pb_1.Message {
        #one_of_decls: number[][] = [[2]];
        constructor(data?: any[] | ({} & (({
            net_worth?: number;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("net_worth" in data && data.net_worth != undefined) {
                    this.net_worth = data.net_worth;
                }
            }
        }
        get net_worth() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set net_worth(value: number) {
            pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0], value);
        }
        get has_net_worth() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get metric() {
            const cases: {
                [index: number]: "none" | "net_worth";
            } = {
                0: "none",
                2: "net_worth"
            };
            return cases[pb_1.Message.computeOneofCase(this, [2])];
        }
        static fromObject(data: {
            net_worth?: number;
        }): Metric {
            const message = new Metric({});
            if (data.net_worth != null) {
                message.net_worth = data.net_worth;
            }
            return message;
        }
        toObject() {
            const data: {
                net_worth?: number;
            } = {};
            if (this.net_worth != null) {
                data.net_worth = this.net_worth;
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_net_worth)
                writer.writeFloat(2, this.net_worth);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Metric {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Metric();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 2:
                        message.net_worth = reader.readFloat();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): Metric {
            return Metric.deserialize(bytes);
        }
    }
    export class ModelElementState extends pb_1.Message {
        #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6]];
        constructor(data?: any[] | ({} & (({
            employment_state?: EmploymentState;
            checking_account_state?: never;
            investment_state?: never;
            expense_state?: never;
            appartment_state?: never;
            mortgage_state?: never;
        } | {
            employment_state?: never;
            checking_account_state?: CheckingAccountState;
            investment_state?: never;
            expense_state?: never;
            appartment_state?: never;
            mortgage_state?: never;
        } | {
            employment_state?: never;
            checking_account_state?: never;
            investment_state?: InvestmentState;
            expense_state?: never;
            appartment_state?: never;
            mortgage_state?: never;
        } | {
            employment_state?: never;
            checking_account_state?: never;
            investment_state?: never;
            expense_state?: ExpenseState;
            appartment_state?: never;
            mortgage_state?: never;
        } | {
            employment_state?: never;
            checking_account_state?: never;
            investment_state?: never;
            expense_state?: never;
            appartment_state?: AppartmentState;
            mortgage_state?: never;
        } | {
            employment_state?: never;
            checking_account_state?: never;
            investment_state?: never;
            expense_state?: never;
            appartment_state?: never;
            mortgage_state?: MortgageState;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("employment_state" in data && data.employment_state != undefined) {
                    this.employment_state = data.employment_state;
                }
                if ("checking_account_state" in data && data.checking_account_state != undefined) {
                    this.checking_account_state = data.checking_account_state;
                }
                if ("investment_state" in data && data.investment_state != undefined) {
                    this.investment_state = data.investment_state;
                }
                if ("expense_state" in data && data.expense_state != undefined) {
                    this.expense_state = data.expense_state;
                }
                if ("appartment_state" in data && data.appartment_state != undefined) {
                    this.appartment_state = data.appartment_state;
                }
                if ("mortgage_state" in data && data.mortgage_state != undefined) {
                    this.mortgage_state = data.mortgage_state;
                }
            }
        }
        get employment_state() {
            return pb_1.Message.getWrapperField(this, EmploymentState, 1) as EmploymentState;
        }
        set employment_state(value: EmploymentState) {
            pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0], value);
        }
        get has_employment_state() {
            return pb_1.Message.getField(this, 1) != null;
        }
        get checking_account_state() {
            return pb_1.Message.getWrapperField(this, CheckingAccountState, 2) as CheckingAccountState;
        }
        set checking_account_state(value: CheckingAccountState) {
            pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0], value);
        }
        get has_checking_account_state() {
            return pb_1.Message.getField(this, 2) != null;
        }
        get investment_state() {
            return pb_1.Message.getWrapperField(this, InvestmentState, 3) as InvestmentState;
        }
        set investment_state(value: InvestmentState) {
            pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0], value);
        }
        get has_investment_state() {
            return pb_1.Message.getField(this, 3) != null;
        }
        get expense_state() {
            return pb_1.Message.getWrapperField(this, ExpenseState, 4) as ExpenseState;
        }
        set expense_state(value: ExpenseState) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0], value);
        }
        get has_expense_state() {
            return pb_1.Message.getField(this, 4) != null;
        }
        get appartment_state() {
            return pb_1.Message.getWrapperField(this, AppartmentState, 5) as AppartmentState;
        }
        set appartment_state(value: AppartmentState) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0], value);
        }
        get has_appartment_state() {
            return pb_1.Message.getField(this, 5) != null;
        }
        get mortgage_state() {
            return pb_1.Message.getWrapperField(this, MortgageState, 6) as MortgageState;
        }
        set mortgage_state(value: MortgageState) {
            pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0], value);
        }
        get has_mortgage_state() {
            return pb_1.Message.getField(this, 6) != null;
        }
        get state() {
            const cases: {
                [index: number]: "none" | "employment_state" | "checking_account_state" | "investment_state" | "expense_state" | "appartment_state" | "mortgage_state";
            } = {
                0: "none",
                1: "employment_state",
                2: "checking_account_state",
                3: "investment_state",
                4: "expense_state",
                5: "appartment_state",
                6: "mortgage_state"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6])];
        }
        static fromObject(data: {
            employment_state?: ReturnType<typeof EmploymentState.prototype.toObject>;
            checking_account_state?: ReturnType<typeof CheckingAccountState.prototype.toObject>;
            investment_state?: ReturnType<typeof InvestmentState.prototype.toObject>;
            expense_state?: ReturnType<typeof ExpenseState.prototype.toObject>;
            appartment_state?: ReturnType<typeof AppartmentState.prototype.toObject>;
            mortgage_state?: ReturnType<typeof MortgageState.prototype.toObject>;
        }): ModelElementState {
            const message = new ModelElementState({});
            if (data.employment_state != null) {
                message.employment_state = EmploymentState.fromObject(data.employment_state);
            }
            if (data.checking_account_state != null) {
                message.checking_account_state = CheckingAccountState.fromObject(data.checking_account_state);
            }
            if (data.investment_state != null) {
                message.investment_state = InvestmentState.fromObject(data.investment_state);
            }
            if (data.expense_state != null) {
                message.expense_state = ExpenseState.fromObject(data.expense_state);
            }
            if (data.appartment_state != null) {
                message.appartment_state = AppartmentState.fromObject(data.appartment_state);
            }
            if (data.mortgage_state != null) {
                message.mortgage_state = MortgageState.fromObject(data.mortgage_state);
            }
            return message;
        }
        toObject() {
            const data: {
                employment_state?: ReturnType<typeof EmploymentState.prototype.toObject>;
                checking_account_state?: ReturnType<typeof CheckingAccountState.prototype.toObject>;
                investment_state?: ReturnType<typeof InvestmentState.prototype.toObject>;
                expense_state?: ReturnType<typeof ExpenseState.prototype.toObject>;
                appartment_state?: ReturnType<typeof AppartmentState.prototype.toObject>;
                mortgage_state?: ReturnType<typeof MortgageState.prototype.toObject>;
            } = {};
            if (this.employment_state != null) {
                data.employment_state = this.employment_state.toObject();
            }
            if (this.checking_account_state != null) {
                data.checking_account_state = this.checking_account_state.toObject();
            }
            if (this.investment_state != null) {
                data.investment_state = this.investment_state.toObject();
            }
            if (this.expense_state != null) {
                data.expense_state = this.expense_state.toObject();
            }
            if (this.appartment_state != null) {
                data.appartment_state = this.appartment_state.toObject();
            }
            if (this.mortgage_state != null) {
                data.mortgage_state = this.mortgage_state.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.has_employment_state)
                writer.writeMessage(1, this.employment_state, () => this.employment_state.serialize(writer));
            if (this.has_checking_account_state)
                writer.writeMessage(2, this.checking_account_state, () => this.checking_account_state.serialize(writer));
            if (this.has_investment_state)
                writer.writeMessage(3, this.investment_state, () => this.investment_state.serialize(writer));
            if (this.has_expense_state)
                writer.writeMessage(4, this.expense_state, () => this.expense_state.serialize(writer));
            if (this.has_appartment_state)
                writer.writeMessage(5, this.appartment_state, () => this.appartment_state.serialize(writer));
            if (this.has_mortgage_state)
                writer.writeMessage(6, this.mortgage_state, () => this.mortgage_state.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ModelElementState {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ModelElementState();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.employment_state, () => message.employment_state = EmploymentState.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.checking_account_state, () => message.checking_account_state = CheckingAccountState.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.investment_state, () => message.investment_state = InvestmentState.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.expense_state, () => message.expense_state = ExpenseState.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.appartment_state, () => message.appartment_state = AppartmentState.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.mortgage_state, () => message.mortgage_state = MortgageState.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): ModelElementState {
            return ModelElementState.deserialize(bytes);
        }
    }
    export class SimulationState extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            time?: number;
            metrics?: Metric[];
            state_by_element?: Map<string, ModelElementState>;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("time" in data && data.time != undefined) {
                    this.time = data.time;
                }
                if ("metrics" in data && data.metrics != undefined) {
                    this.metrics = data.metrics;
                }
                if ("state_by_element" in data && data.state_by_element != undefined) {
                    this.state_by_element = data.state_by_element;
                }
            }
            if (!this.state_by_element)
                this.state_by_element = new Map();
        }
        get time() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set time(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get metrics() {
            return pb_1.Message.getRepeatedWrapperField(this, Metric, 2) as Metric[];
        }
        set metrics(value: Metric[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        get state_by_element() {
            return pb_1.Message.getField(this, 3) as any as Map<string, ModelElementState>;
        }
        set state_by_element(value: Map<string, ModelElementState>) {
            pb_1.Message.setField(this, 3, value as any);
        }
        static fromObject(data: {
            time?: number;
            metrics?: ReturnType<typeof Metric.prototype.toObject>[];
            state_by_element?: {
                [key: string]: ReturnType<typeof ModelElementState.prototype.toObject>;
            };
        }): SimulationState {
            const message = new SimulationState({});
            if (data.time != null) {
                message.time = data.time;
            }
            if (data.metrics != null) {
                message.metrics = data.metrics.map(item => Metric.fromObject(item));
            }
            if (typeof data.state_by_element == "object") {
                message.state_by_element = new Map(Object.entries(data.state_by_element).map(([key, value]) => [key, ModelElementState.fromObject(value)]));
            }
            return message;
        }
        toObject() {
            const data: {
                time?: number;
                metrics?: ReturnType<typeof Metric.prototype.toObject>[];
                state_by_element?: {
                    [key: string]: ReturnType<typeof ModelElementState.prototype.toObject>;
                };
            } = {};
            if (this.time != null) {
                data.time = this.time;
            }
            if (this.metrics != null) {
                data.metrics = this.metrics.map((item: Metric) => item.toObject());
            }
            if (this.state_by_element != null) {
                data.state_by_element = (Object.fromEntries)((Array.from)(this.state_by_element).map(([key, value]) => [key, value.toObject()]));
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.time != 0)
                writer.writeInt32(1, this.time);
            if (this.metrics.length)
                writer.writeRepeatedMessage(2, this.metrics, (item: Metric) => item.serialize(writer));
            for (const [key, value] of this.state_by_element) {
                writer.writeMessage(3, this.state_by_element, () => {
                    writer.writeString(1, key);
                    writer.writeMessage(2, value, () => value.serialize(writer));
                });
            }
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SimulationState {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SimulationState();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.time = reader.readInt32();
                        break;
                    case 2:
                        reader.readMessage(message.metrics, () => pb_1.Message.addToRepeatedWrapperField(message, 2, Metric.deserialize(reader), Metric));
                        break;
                    case 3:
                        reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.state_by_element as any, reader, reader.readString, () => {
                            let value;
                            reader.readMessage(message, () => value = ModelElementState.deserialize(reader));
                            return value;
                        }));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SimulationState {
            return SimulationState.deserialize(bytes);
        }
    }
    export class SimulationResult extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            states?: SimulationState[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("states" in data && data.states != undefined) {
                    this.states = data.states;
                }
            }
        }
        get states() {
            return pb_1.Message.getRepeatedWrapperField(this, SimulationState, 1) as SimulationState[];
        }
        set states(value: SimulationState[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data: {
            states?: ReturnType<typeof SimulationState.prototype.toObject>[];
        }): SimulationResult {
            const message = new SimulationResult({});
            if (data.states != null) {
                message.states = data.states.map(item => SimulationState.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: {
                states?: ReturnType<typeof SimulationState.prototype.toObject>[];
            } = {};
            if (this.states != null) {
                data.states = this.states.map((item: SimulationState) => item.toObject());
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.states.length)
                writer.writeRepeatedMessage(1, this.states, (item: SimulationState) => item.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SimulationResult {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SimulationResult();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.states, () => pb_1.Message.addToRepeatedWrapperField(message, 1, SimulationState.deserialize(reader), SimulationState));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static deserializeBinary(bytes: Uint8Array): SimulationResult {
            return SimulationResult.deserialize(bytes);
        }
    }
}
