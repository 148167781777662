import React from 'react';
import Typography from '@mui/material/Typography';
import MainCard from 'components/MainCard';

const PrivacyNotice = () => {
  return (
    <MainCard title="Privacy Notice">
      <Typography variant="body1">Effective Date: February 10, 2024</Typography>
      <div>
        <Typography variant="body1">
          Welcome to financial model simulator, your go-to resource for building
          financial models. We are committed to protecting your privacy and
          handling your personal data transparently and securely. This Privacy
          Notice explains how we collect, use, share, and protect your
          information when you visit our website.
        </Typography>

        <Typography variant="h4">Information We Collect</Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Data You Provide: We may collect information you provide directly
              to us when you use our website, such as when you fill out forms or
              interact with our tools. This may include personal details like
              your name, email address, and any other information you choose to
              provide.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Data We Collect Automatically: When you visit our site, we may
              automatically collect information about your device and usage of
              our site, including IP addresses, browser type, language settings,
              and clickstream data. We gather this information using cookies and
              similar technologies to improve our website and your experience.
            </Typography>
          </li>
        </ul>

        <Typography variant="h4">How We Use Your Information</Typography>
        <ul>
          <li>
            <Typography variant="body1">
              To provide, maintain, and improve our website and services.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              To communicate with you, respond to your inquiries, and provide
              customer service.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              To analyze how our website is used, identifying trends and usage
              patterns.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              For security purposes, including preventing unauthorized access to
              our website and services.
            </Typography>
          </li>
        </ul>

        <Typography variant="h4">Sharing of Information</Typography>
        <Typography variant="body1">
          We may share your information with third-party service providers who
          perform services on our behalf, such as hosting, analytics, and
          content management. These third parties are prohibited from using your
          information for any purpose other than to provide these services to
          us.
        </Typography>

        <Typography variant="h4">Age Restriction</Typography>
        <Typography variant="body1">
          Our website and services are not directed to individuals under the age
          of 18. We do not knowingly collect personal information from anyone
          under 18. If you are under 18, please do not use our website or
          provide any personal information to us. If we learn that we have
          collected personal information from a child under 18, we will take
          steps to delete such information from our servers immediately.
        </Typography>

        <Typography variant="h4">Your Rights and Choices</Typography>
        <Typography variant="body1">
          Under GDPR, you have the right to access, rectify, or erase your
          personal data, restrict processing, object to processing, and, if
          applicable, the right to data portability.
        </Typography>

        <Typography variant="h4">International Transfers</Typography>
        <Typography variant="body1">
          We operate globally and may transfer your personal information to
          third-party service providers in countries outside of the European
          Economic Area (EEA) for the purposes described in this notice. We
          ensure that transfers outside the EEA are protected by appropriate
          safeguards.
        </Typography>

        <Typography variant="h4">Changes to Our Privacy Notice</Typography>
        <Typography variant="body1">
          We reserve the right to update or modify this Privacy Notice at any
          time and without prior notice, in accordance with applicable
          legislation. We encourage you to review this notice periodically to
          stay informed about how we are protecting the personal information we
          collect. Your continued use of the website after any changes or
          revisions to this Privacy Notice indicates your agreement with the
          terms of such revised Privacy Notice.
        </Typography>

        <Typography variant="h4">Contact Us</Typography>
      </div>
    </MainCard>
  );
};

export default PrivacyNotice;
