/** Redux slice for current project state */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  NET_WORTH,
  SimulationResult,
  SimulationResultUtils
} from 'clipper/model_elements';
import {
  Project,
  ProjectSettings,
  Scenario,
  ScenarioStatus,
  UserInputFormState
} from 'clipper/project';
import { StatusUtils } from 'clipper/status_utils';
import { ProjectUtils } from 'clipper/project_utils';
import { merge } from 'lodash';
import { createSelector } from 'reselect';
import { fetchAndUpdateSimulationResults } from 'store/reducers/project_middleware';
import { ClipperApplicationById } from 'clipper/application';

// The proto model is not serializable by redux, so we drop it before saving the state as a workaround
// instead we use the already serialized proto_model_serialized when needed.
export const remove_non_serialisable = (scenario: Scenario): Scenario => {
  return {
    ...scenario,
    model_definition: {
      financial_model: null,
      ...scenario.model_definition,
      proto_model: undefined // Drop the proto model
    }
  };
};

const projectSlice = createSlice({
  name: 'project',
  initialState: ProjectUtils.defaultProject(),
  reducers: {
    setProject(state, action: PayloadAction<Project>) {
      return action.payload; // Replace the entire state with the new project
    },
    addScenario(
      state,
      action: PayloadAction<{ scenarioName: string; scenario: Scenario }>
    ) {
      state.scenarios[action.payload.scenarioName] = action.payload.scenario;
    },
    removeScenario(state, action: PayloadAction<string>) {
      delete state.scenarios[action.payload];
    },
    setSimulationResultForScenario(
      state,
      action: PayloadAction<{
        scenarioName: string;
        simulationResult: SimulationResult;
      }>
    ) {
      const scenario = state.scenarios[action.payload.scenarioName];
      if (scenario === undefined) {
        throw new Error('Scenario not found: ' + action.payload.scenarioName);
      }

      scenario.simulation_result = action.payload.simulationResult;
      scenario.bankrupcy_time = SimulationResultUtils.identifyBankrupcy(
        scenario.simulation_result
      );
    },
    setSimulationStatusForScenario(
      state,
      action: PayloadAction<{ scenarioName: string; status: ScenarioStatus }>
    ) {
      const scenario = state.scenarios[action.payload.scenarioName];
      if (scenario === undefined) {
        throw new Error('Scenario not found: ' + action.payload.scenarioName);
      }
      scenario.scenario_status = action.payload.status;

      // Update the project status
      const new_project_status = StatusUtils.aggregateScenarioStatus(
        Object.values(state.scenarios) as Scenario[]
      );
      if (new_project_status.status !== state.project_status.status) {
        state.project_status = new_project_status;
      }
    },
    setSimulationStatusForProject(
      state,
      action: PayloadAction<ScenarioStatus>
    ) {
      state.project_status = action.payload;
    },

    updateProjectSettings(state, action: PayloadAction<ProjectSettings>) {
      state.project_settings = action.payload;
    },
    // The form state is updated by merging the new state with the old state
    // This is to allow partial updates of the form state for complex forms
    updateUserInputFormState(state, action: PayloadAction<UserInputFormState>) {
      const newFormState = action.payload;
      const oldFormState = state.userInputFormStates[newFormState.name];
      const merged = merge({}, oldFormState, newFormState);
      state.userInputFormStates[newFormState.name] = merged;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAndUpdateSimulationResults.fulfilled, // this is called when the backend fetch initialted by the middleware is finished.
      (state, action) => {
        const scenarios: Scenario[] = action.payload;
        scenarios.forEach((scenario) => {
          state.scenarios[scenario.name] = remove_non_serialisable(scenario);
        });

        // Update the project status
        const new_project_status = StatusUtils.aggregateScenarioStatus(
          Object.values(state.scenarios) as Scenario[]
        );
        if (new_project_status.status !== state.project_status.status) {
          state.project_status = new_project_status;
        }
      }
    );
  }
});

export const selectUserInputFormStates = (state: {
  project: Project;
}): { [key: string]: UserInputFormState } => {
  return state.project.userInputFormStates;
};

export const selectProjectSettings = (state: {
  project: Project;
}): ProjectSettings => {
  return state.project.project_settings;
};

/**Creates a selector for a scenario with a given name */
export const makeSelectScenarioByName = (scenarioName: string) => {
  return (state: { project: Project }): Scenario | undefined => {
    return state.project.scenarios[scenarioName];
  };
};

/** Creates a selector for the networth of a scenario with a given name */
export const makeSelectNetWorthByScenarioName = (scenarioName: string) => {
  return createSelector(
    (state: { project: Project }) => state.project.scenarios[scenarioName],
    (scenario) => {
      if (scenario === undefined) {
        return {
          points: [],
          name: NET_WORTH
        };
      }

      const points = scenario.simulation_result?.states.map((state) => {
        const netWorth = state.metrics.find(
          (metric) => metric.name === NET_WORTH
        );
        return { time: state.time, value: netWorth?.value };
      });

      return { points: points ?? [], name: NET_WORTH };
    }
  );
};

export const selectProjectStatus = (state: { project: Project }) => {
  return state.project.project_status;
};

export const selectApplication = (state: { project: Project }) => {
  return ClipperApplicationById[state.project.registered_application_id];
};

export const {
  setProject,
  addScenario,
  removeScenario,
  setSimulationResultForScenario,
  setSimulationStatusForScenario,
  setSimulationStatusForProject,
  updateUserInputFormState,
  updateProjectSettings
} = projectSlice.actions;

export default projectSlice.reducer;
