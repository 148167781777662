// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  // const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Mantis" width="100" />
     *
     */
    <>
      {/* <svg width="118" height="35" viewBox="0 0 118 35" fill="none" xmlns="http://www.w3.org/2000/svg"> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="60"
        height="60"
        viewBox="0.00 0.00 304.00 312.00"
      >
        <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
          <path
            stroke="#858889"
            vectorEffect="non-scaling-stroke"
            d="
  M 158.41 26.07
  A 12.00 12.00 0.0 0 0 146.41 14.07
  A 12.00 12.00 0.0 0 0 134.41 26.07
  A 12.00 12.00 0.0 0 0 146.41 38.07
  A 12.00 12.00 0.0 0 0 158.41 26.07"
          />
          <path
            stroke="#858889"
            vectorEffect="non-scaling-stroke"
            d="
  M 137.22 127.25
  C 133.43 139.88 120.73 144.37 114.95 155.69
  A 0.66 0.66 0.0 0 1 113.76 155.64
  C 109.45 145.29 100.58 142.02 91.55 136.46
  C 86.66 133.44 84.31 128.63 82.47 123.44
  Q 80.09 116.74 74.98 111.17
  C 71.88 107.79 69.46 104.13 67.39 99.95
  A 0.03 0.03 0.0 0 0 67.33 99.98
  C 68.89 104.80 72.04 109.14 75.25 113.57
  C 77.15 116.19 78.92 119.41 79.67 122.33
  C 81.55 129.64 82.86 136.28 89.62 140.86
  C 96.30 145.40 103.05 149.48 106.72 157.05
  C 108.87 161.48 108.96 166.77 109.09 171.54
  Q 109.40 182.85 114.96 192.18
  Q 120.46 201.43 127.43 208.03
  Q 130.03 210.50 126.70 209.17
  C 115.48 204.70 103.68 203.66 91.58 204.13
  A 1.76 1.76 0.0 0 1 89.79 202.75
  C 88.89 198.68 90.53 193.29 91.55 189.24
  C 94.10 179.16 90.13 172.00 81.83 166.45
  Q 81.70 166.37 81.61 166.49
  L 81.61 166.50
  Q 81.52 166.62 81.64 166.72
  Q 84.94 169.32 87.09 172.57
  C 91.08 178.61 90.19 184.57 87.25 190.86
  Q 84.41 196.94 83.74 203.53
  Q 83.66 204.35 82.83 204.39
  Q 75.03 204.74 67.12 204.09
  C 54.94 203.11 45.98 198.81 43.19 185.54
  Q 42.07 180.22 42.35 175.71
  Q 42.36 175.52 42.17 175.53
  L 41.84 175.54
  A 0.31 0.30 2.3 0 0 41.56 175.79
  Q 40.72 181.27 41.87 187.13
  C 44.27 199.31 52.47 206.20 64.22 208.80
  Q 71.40 210.39 77.73 210.63
  C 87.38 211.00 98.17 210.30 108.42 211.80
  Q 116.13 212.93 120.68 214.62
  Q 135.78 220.23 140.31 235.27
  Q 141.87 240.45 141.53 251.95
  Q 141.21 262.89 141.37 276.37
  C 141.52 288.84 139.75 296.30 131.45 305.12
  C 130.28 306.37 128.65 306.82 127.00 306.83
  Q 88.77 307.06 26.02 307.00
  Q 15.91 306.99 6.54 307.29
  A 0.47 0.46 -90.0 0 0 6.09 307.75
  L 6.09 308.02
  Q 6.09 308.15 6.21 308.15
  C 10.90 308.44 15.60 308.73 20.30 308.74
  Q 147.34 308.83 270.93 308.73
  C 279.34 308.72 287.70 308.39 296.05 308.32
  A 0.38 0.38 0.0 0 0 296.43 307.96
  L 296.45 307.63
  Q 296.49 307.02 295.88 307.02
  L 169.56 306.94
  A 2.37 2.34 71.5 0 1 167.67 305.98
  Q 166.35 304.20 164.72 302.50
  C 160.30 297.90 157.22 291.02 156.67 285.25
  Q 156.04 278.56 156.17 271.62
  Q 156.40 259.91 156.00 246.66
  Q 155.60 233.67 157.36 226.60
  C 162.27 206.93 180.62 195.15 200.50 193.97
  Q 204.58 193.73 207.81 193.84
  C 221.50 194.29 234.38 194.24 246.56 191.13
  C 259.40 187.85 265.96 176.67 265.44 163.84
  Q 265.44 163.68 265.27 163.68
  L 265.26 163.68
  Q 265.14 163.68 265.14 163.80
  C 264.80 173.84 259.61 183.72 248.77 185.41
  Q 241.44 186.56 231.85 186.06
  Q 231.08 186.02 231.22 185.26
  C 233.60 173.00 229.14 165.24 222.25 156.11
  C 221.32 154.89 221.01 153.33 220.01 152.17
  A 0.33 0.32 -67.6 0 0 219.44 152.41
  C 219.73 156.78 222.65 161.10 224.54 165.03
  C 227.62 171.45 226.37 178.63 222.97 184.80
  A 1.15 1.15 0.0 0 1 221.93 185.39
  Q 208.44 184.86 197.12 185.08
  C 175.76 185.49 156.20 194.89 144.67 213.05
  A 0.38 0.37 -46.5 0 1 144.05 213.07
  Q 142.91 211.53 141.61 210.25
  Q 135.49 204.20 130.23 198.70
  C 121.16 189.22 117.14 174.54 122.76 162.57
  Q 127.10 153.35 135.86 146.36
  Q 141.60 141.78 148.17 137.35"
          />
          <path
            stroke="#a1a2a2"
            vectorEffect="non-scaling-stroke"
            d="
  M 148.17 137.35
  Q 156.41 132.71 166.07 124.32
  Q 175.95 115.76 180.13 105.42
  C 182.97 98.40 184.83 91.65 184.08 83.89
  Q 184.07 83.78 184.05 83.89
  Q 183.30 88.06 182.45 91.96
  Q 181.69 95.42 179.92 99.35
  Q 178.37 102.80 175.79 105.88
  Q 166.68 116.75 154.89 124.11
  Q 150.85 126.63 146.98 129.00
  Q 145.55 129.88 145.67 128.21
  C 146.40 117.42 141.02 111.50 133.29 105.13
  Q 126.36 99.42 127.44 89.37
  Q 128.43 80.09 133.22 72.09
  Q 137.26 65.33 140.79 58.95
  C 141.53 57.61 142.47 55.65 142.83 54.13
  Q 143.06 53.17 142.66 54.07
  Q 142.12 55.29 141.61 56.59
  Q 141.12 57.83 140.30 58.95
  C 138.68 61.17 137.39 63.61 135.62 65.71
  Q 129.09 73.46 125.34 83.19
  C 123.47 88.03 122.79 93.09 123.27 98.22
  C 124.44 110.70 140.65 111.81 137.22 127.25"
          />
          <path
            stroke="#282b2d"
            vectorEffect="non-scaling-stroke"
            d="
  M 148.17 137.35
  Q 142.86 138.96 138.27 142.85
  Q 137.77 143.28 137.13 143.13
  Q 136.29 142.94 135.57 143.34
  Q 135.23 143.54 135.11 143.16
  Q 134.64 141.67 132.87 141.65
  Q 131.52 141.64 131.82 140.32
  Q 132.34 138.06 133.66 136.61
  C 135.39 134.71 136.02 132.65 137.27 130.53
  Q 137.70 129.80 137.80 128.94
  Q 137.97 127.62 137.22 127.25"
          />
          <path
            stroke="#f7dfc5"
            vectorEffect="non-scaling-stroke"
            d="
  M 193.5033 65.3826
  A 11.28 11.09 -23.0 0 0 178.7868 59.5816
  A 11.28 11.09 -23.0 0 0 172.7367 74.1974
  A 11.28 11.09 -23.0 0 0 187.4532 79.9984
  A 11.28 11.09 -23.0 0 0 193.5033 65.3826"
          />
          <path
            stroke="#858889"
            vectorEffect="non-scaling-stroke"
            d="
  M 70.0414 87.2090
  A 6.94 6.78 5.2 0 0 63.7445 79.8279
  A 6.94 6.78 5.2 0 0 56.2186 85.9510
  A 6.94 6.78 5.2 0 0 62.5155 93.3321
  A 6.94 6.78 5.2 0 0 70.0414 87.2090"
          />
          <path
            stroke="#f7dfc5"
            vectorEffect="non-scaling-stroke"
            d="
  M 224.45 139.86
  A 11.27 11.27 0.0 0 0 213.18 128.59
  A 11.27 11.27 0.0 0 0 201.91 139.86
  A 11.27 11.27 0.0 0 0 213.18 151.13
  A 11.27 11.27 0.0 0 0 224.45 139.86"
          />
          <path
            stroke="#f7dfc5"
            vectorEffect="non-scaling-stroke"
            d="
  M 269.1448 147.7864
  A 3.82 3.67 -36.9 0 0 263.8865 147.1452
  A 3.82 3.67 -36.9 0 0 263.0352 152.3736
  A 3.82 3.67 -36.9 0 0 268.2935 153.0148
  A 3.82 3.67 -36.9 0 0 269.1448 147.7864"
          />
          <path
            stroke="#f7dfc5"
            vectorEffect="non-scaling-stroke"
            d="
  M 173.7440 158.1750
  A 10.38 10.13 -13.0 0 0 161.3512 150.6396
  A 10.38 10.13 -13.0 0 0 153.5160 162.8450
  A 10.38 10.13 -13.0 0 0 165.9088 170.3804
  A 10.38 10.13 -13.0 0 0 173.7440 158.1750"
          />
        </g>
        <path
          fill="#fefefe"
          d="
  M 0.00 0.00
  L 304.00 0.00
  L 304.00 312.00
  L 0.00 312.00
  L 0.00 0.00
  Z
  M 158.41 26.07
  A 12.00 12.00 0.0 0 0 146.41 14.07
  A 12.00 12.00 0.0 0 0 134.41 26.07
  A 12.00 12.00 0.0 0 0 146.41 38.07
  A 12.00 12.00 0.0 0 0 158.41 26.07
  Z
  M 137.22 127.25
  C 133.43 139.88 120.73 144.37 114.95 155.69
  A 0.66 0.66 0.0 0 1 113.76 155.64
  C 109.45 145.29 100.58 142.02 91.55 136.46
  C 86.66 133.44 84.31 128.63 82.47 123.44
  Q 80.09 116.74 74.98 111.17
  C 71.88 107.79 69.46 104.13 67.39 99.95
  A 0.03 0.03 0.0 0 0 67.33 99.98
  C 68.89 104.80 72.04 109.14 75.25 113.57
  C 77.15 116.19 78.92 119.41 79.67 122.33
  C 81.55 129.64 82.86 136.28 89.62 140.86
  C 96.30 145.40 103.05 149.48 106.72 157.05
  C 108.87 161.48 108.96 166.77 109.09 171.54
  Q 109.40 182.85 114.96 192.18
  Q 120.46 201.43 127.43 208.03
  Q 130.03 210.50 126.70 209.17
  C 115.48 204.70 103.68 203.66 91.58 204.13
  A 1.76 1.76 0.0 0 1 89.79 202.75
  C 88.89 198.68 90.53 193.29 91.55 189.24
  C 94.10 179.16 90.13 172.00 81.83 166.45
  Q 81.70 166.37 81.61 166.49
  L 81.61 166.50
  Q 81.52 166.62 81.64 166.72
  Q 84.94 169.32 87.09 172.57
  C 91.08 178.61 90.19 184.57 87.25 190.86
  Q 84.41 196.94 83.74 203.53
  Q 83.66 204.35 82.83 204.39
  Q 75.03 204.74 67.12 204.09
  C 54.94 203.11 45.98 198.81 43.19 185.54
  Q 42.07 180.22 42.35 175.71
  Q 42.36 175.52 42.17 175.53
  L 41.84 175.54
  A 0.31 0.30 2.3 0 0 41.56 175.79
  Q 40.72 181.27 41.87 187.13
  C 44.27 199.31 52.47 206.20 64.22 208.80
  Q 71.40 210.39 77.73 210.63
  C 87.38 211.00 98.17 210.30 108.42 211.80
  Q 116.13 212.93 120.68 214.62
  Q 135.78 220.23 140.31 235.27
  Q 141.87 240.45 141.53 251.95
  Q 141.21 262.89 141.37 276.37
  C 141.52 288.84 139.75 296.30 131.45 305.12
  C 130.28 306.37 128.65 306.82 127.00 306.83
  Q 88.77 307.06 26.02 307.00
  Q 15.91 306.99 6.54 307.29
  A 0.47 0.46 -90.0 0 0 6.09 307.75
  L 6.09 308.02
  Q 6.09 308.15 6.21 308.15
  C 10.90 308.44 15.60 308.73 20.30 308.74
  Q 147.34 308.83 270.93 308.73
  C 279.34 308.72 287.70 308.39 296.05 308.32
  A 0.38 0.38 0.0 0 0 296.43 307.96
  L 296.45 307.63
  Q 296.49 307.02 295.88 307.02
  L 169.56 306.94
  A 2.37 2.34 71.5 0 1 167.67 305.98
  Q 166.35 304.20 164.72 302.50
  C 160.30 297.90 157.22 291.02 156.67 285.25
  Q 156.04 278.56 156.17 271.62
  Q 156.40 259.91 156.00 246.66
  Q 155.60 233.67 157.36 226.60
  C 162.27 206.93 180.62 195.15 200.50 193.97
  Q 204.58 193.73 207.81 193.84
  C 221.50 194.29 234.38 194.24 246.56 191.13
  C 259.40 187.85 265.96 176.67 265.44 163.84
  Q 265.44 163.68 265.27 163.68
  L 265.26 163.68
  Q 265.14 163.68 265.14 163.80
  C 264.80 173.84 259.61 183.72 248.77 185.41
  Q 241.44 186.56 231.85 186.06
  Q 231.08 186.02 231.22 185.26
  C 233.60 173.00 229.14 165.24 222.25 156.11
  C 221.32 154.89 221.01 153.33 220.01 152.17
  A 0.33 0.32 -67.6 0 0 219.44 152.41
  C 219.73 156.78 222.65 161.10 224.54 165.03
  C 227.62 171.45 226.37 178.63 222.97 184.80
  A 1.15 1.15 0.0 0 1 221.93 185.39
  Q 208.44 184.86 197.12 185.08
  C 175.76 185.49 156.20 194.89 144.67 213.05
  A 0.38 0.37 -46.5 0 1 144.05 213.07
  Q 142.91 211.53 141.61 210.25
  Q 135.49 204.20 130.23 198.70
  C 121.16 189.22 117.14 174.54 122.76 162.57
  Q 127.10 153.35 135.86 146.36
  Q 141.60 141.78 148.17 137.35
  Q 156.41 132.71 166.07 124.32
  Q 175.95 115.76 180.13 105.42
  C 182.97 98.40 184.83 91.65 184.08 83.89
  Q 184.07 83.78 184.05 83.89
  Q 183.30 88.06 182.45 91.96
  Q 181.69 95.42 179.92 99.35
  Q 178.37 102.80 175.79 105.88
  Q 166.68 116.75 154.89 124.11
  Q 150.85 126.63 146.98 129.00
  Q 145.55 129.88 145.67 128.21
  C 146.40 117.42 141.02 111.50 133.29 105.13
  Q 126.36 99.42 127.44 89.37
  Q 128.43 80.09 133.22 72.09
  Q 137.26 65.33 140.79 58.95
  C 141.53 57.61 142.47 55.65 142.83 54.13
  Q 143.06 53.17 142.66 54.07
  Q 142.12 55.29 141.61 56.59
  Q 141.12 57.83 140.30 58.95
  C 138.68 61.17 137.39 63.61 135.62 65.71
  Q 129.09 73.46 125.34 83.19
  C 123.47 88.03 122.79 93.09 123.27 98.22
  C 124.44 110.70 140.65 111.81 137.22 127.25
  Z
  M 193.5033 65.3826
  A 11.28 11.09 -23.0 0 0 178.7868 59.5816
  A 11.28 11.09 -23.0 0 0 172.7367 74.1974
  A 11.28 11.09 -23.0 0 0 187.4532 79.9984
  A 11.28 11.09 -23.0 0 0 193.5033 65.3826
  Z
  M 70.0414 87.2090
  A 6.94 6.78 5.2 0 0 63.7445 79.8279
  A 6.94 6.78 5.2 0 0 56.2186 85.9510
  A 6.94 6.78 5.2 0 0 62.5155 93.3321
  A 6.94 6.78 5.2 0 0 70.0414 87.2090
  Z
  M 224.45 139.86
  A 11.27 11.27 0.0 0 0 213.18 128.59
  A 11.27 11.27 0.0 0 0 201.91 139.86
  A 11.27 11.27 0.0 0 0 213.18 151.13
  A 11.27 11.27 0.0 0 0 224.45 139.86
  Z
  M 269.1448 147.7864
  A 3.82 3.67 -36.9 0 0 263.8865 147.1452
  A 3.82 3.67 -36.9 0 0 263.0352 152.3736
  A 3.82 3.67 -36.9 0 0 268.2935 153.0148
  A 3.82 3.67 -36.9 0 0 269.1448 147.7864
  Z
  M 173.7440 158.1750
  A 10.38 10.13 -13.0 0 0 161.3512 150.6396
  A 10.38 10.13 -13.0 0 0 153.5160 162.8450
  A 10.38 10.13 -13.0 0 0 165.9088 170.3804
  A 10.38 10.13 -13.0 0 0 173.7440 158.1750
  Z"
        />
        <circle fill="#0c1113" cx="146.41" cy="26.07" r="12.00" />
        <path
          fill="#444546"
          d="
  M 148.17 137.35
  Q 142.86 138.96 138.27 142.85
  Q 137.77 143.28 137.13 143.13
  Q 136.29 142.94 135.57 143.34
  Q 135.23 143.54 135.11 143.16
  Q 134.64 141.67 132.87 141.65
  Q 131.52 141.64 131.82 140.32
  Q 132.34 138.06 133.66 136.61
  C 135.39 134.71 136.02 132.65 137.27 130.53
  Q 137.70 129.80 137.80 128.94
  Q 137.97 127.62 137.22 127.25
  C 140.65 111.81 124.44 110.70 123.27 98.22
  C 122.79 93.09 123.47 88.03 125.34 83.19
  Q 129.09 73.46 135.62 65.71
  C 137.39 63.61 138.68 61.17 140.30 58.95
  Q 141.12 57.83 141.61 56.59
  Q 142.12 55.29 142.66 54.07
  Q 143.06 53.17 142.83 54.13
  C 142.47 55.65 141.53 57.61 140.79 58.95
  Q 137.26 65.33 133.22 72.09
  Q 128.43 80.09 127.44 89.37
  Q 126.36 99.42 133.29 105.13
  C 141.02 111.50 146.40 117.42 145.67 128.21
  Q 145.55 129.88 146.98 129.00
  Q 150.85 126.63 154.89 124.11
  Q 166.68 116.75 175.79 105.88
  Q 178.37 102.80 179.92 99.35
  Q 181.69 95.42 182.45 91.96
  Q 183.30 88.06 184.05 83.89
  Q 184.07 83.78 184.08 83.89
  C 184.83 91.65 182.97 98.40 180.13 105.42
  Q 175.95 115.76 166.07 124.32
  Q 156.41 132.71 148.17 137.35
  Z"
        />
        <ellipse
          fill="#efbf8b"
          cx="0.00"
          cy="0.00"
          transform="translate(183.12,69.79) rotate(-23.0)"
          rx="11.28"
          ry="11.09"
        />
        <ellipse
          fill="#0c1113"
          cx="0.00"
          cy="0.00"
          transform="translate(63.13,86.58) rotate(5.2)"
          rx="6.94"
          ry="6.78"
        />
        <path
          fill="#0c1113"
          d="
  M 137.22 127.25
  Q 137.97 127.62 137.80 128.94
  Q 137.70 129.80 137.27 130.53
  C 136.02 132.65 135.39 134.71 133.66 136.61
  Q 132.34 138.06 131.82 140.32
  Q 131.52 141.64 132.87 141.65
  Q 134.64 141.67 135.11 143.16
  Q 135.23 143.54 135.57 143.34
  Q 136.29 142.94 137.13 143.13
  Q 137.77 143.28 138.27 142.85
  Q 142.86 138.96 148.17 137.35
  Q 141.60 141.78 135.86 146.36
  Q 127.10 153.35 122.76 162.57
  C 117.14 174.54 121.16 189.22 130.23 198.70
  Q 135.49 204.20 141.61 210.25
  Q 142.91 211.53 144.05 213.07
  A 0.38 0.37 -46.5 0 0 144.67 213.05
  C 156.20 194.89 175.76 185.49 197.12 185.08
  Q 208.44 184.86 221.93 185.39
  A 1.15 1.15 0.0 0 0 222.97 184.80
  C 226.37 178.63 227.62 171.45 224.54 165.03
  C 222.65 161.10 219.73 156.78 219.44 152.41
  A 0.33 0.32 -67.6 0 1 220.01 152.17
  C 221.01 153.33 221.32 154.89 222.25 156.11
  C 229.14 165.24 233.60 173.00 231.22 185.26
  Q 231.08 186.02 231.85 186.06
  Q 241.44 186.56 248.77 185.41
  C 259.61 183.72 264.80 173.84 265.14 163.80
  Q 265.14 163.68 265.26 163.68
  L 265.27 163.68
  Q 265.44 163.68 265.44 163.84
  C 265.96 176.67 259.40 187.85 246.56 191.13
  C 234.38 194.24 221.50 194.29 207.81 193.84
  Q 204.58 193.73 200.50 193.97
  C 180.62 195.15 162.27 206.93 157.36 226.60
  Q 155.60 233.67 156.00 246.66
  Q 156.40 259.91 156.17 271.62
  Q 156.04 278.56 156.67 285.25
  C 157.22 291.02 160.30 297.90 164.72 302.50
  Q 166.35 304.20 167.67 305.98
  A 2.37 2.34 71.5 0 0 169.56 306.94
  L 295.88 307.02
  Q 296.49 307.02 296.45 307.63
  L 296.43 307.96
  A 0.38 0.38 0.0 0 1 296.05 308.32
  C 287.70 308.39 279.34 308.72 270.93 308.73
  Q 147.34 308.83 20.30 308.74
  C 15.60 308.73 10.90 308.44 6.21 308.15
  Q 6.09 308.15 6.09 308.02
  L 6.09 307.75
  A 0.47 0.46 -90.0 0 1 6.54 307.29
  Q 15.91 306.99 26.02 307.00
  Q 88.77 307.06 127.00 306.83
  C 128.65 306.82 130.28 306.37 131.45 305.12
  C 139.75 296.30 141.52 288.84 141.37 276.37
  Q 141.21 262.89 141.53 251.95
  Q 141.87 240.45 140.31 235.27
  Q 135.78 220.23 120.68 214.62
  Q 116.13 212.93 108.42 211.80
  C 98.17 210.30 87.38 211.00 77.73 210.63
  Q 71.40 210.39 64.22 208.80
  C 52.47 206.20 44.27 199.31 41.87 187.13
  Q 40.72 181.27 41.56 175.79
  A 0.31 0.30 2.3 0 1 41.84 175.54
  L 42.17 175.53
  Q 42.36 175.52 42.35 175.71
  Q 42.07 180.22 43.19 185.54
  C 45.98 198.81 54.94 203.11 67.12 204.09
  Q 75.03 204.74 82.83 204.39
  Q 83.66 204.35 83.74 203.53
  Q 84.41 196.94 87.25 190.86
  C 90.19 184.57 91.08 178.61 87.09 172.57
  Q 84.94 169.32 81.64 166.72
  Q 81.52 166.62 81.61 166.50
  L 81.61 166.49
  Q 81.70 166.37 81.83 166.45
  C 90.13 172.00 94.10 179.16 91.55 189.24
  C 90.53 193.29 88.89 198.68 89.79 202.75
  A 1.76 1.76 0.0 0 0 91.58 204.13
  C 103.68 203.66 115.48 204.70 126.70 209.17
  Q 130.03 210.50 127.43 208.03
  Q 120.46 201.43 114.96 192.18
  Q 109.40 182.85 109.09 171.54
  C 108.96 166.77 108.87 161.48 106.72 157.05
  C 103.05 149.48 96.30 145.40 89.62 140.86
  C 82.86 136.28 81.55 129.64 79.67 122.33
  C 78.92 119.41 77.15 116.19 75.25 113.57
  C 72.04 109.14 68.89 104.80 67.33 99.98
  A 0.03 0.03 0.0 0 1 67.39 99.95
  C 69.46 104.13 71.88 107.79 74.98 111.17
  Q 80.09 116.74 82.47 123.44
  C 84.31 128.63 86.66 133.44 91.55 136.46
  C 100.58 142.02 109.45 145.29 113.76 155.64
  A 0.66 0.66 0.0 0 0 114.95 155.69
  C 120.73 144.37 133.43 139.88 137.22 127.25
  Z"
        />
        <circle fill="#efbf8b" cx="213.18" cy="139.86" r="11.27" />
        <ellipse
          fill="#efbf8b"
          cx="0.00"
          cy="0.00"
          transform="translate(266.09,150.08) rotate(-36.9)"
          rx="3.82"
          ry="3.67"
        />
        <ellipse
          fill="#efbf8b"
          cx="0.00"
          cy="0.00"
          transform="translate(163.63,160.51) rotate(-13.0)"
          rx="10.38"
          ry="10.13"
        />
      </svg>
    </>
  );
};

export default Logo;
