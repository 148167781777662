import { clipper } from 'clipper/generated_simulation_model';

export const NET_WORTH = 'net_worth';

export interface Metric {
  name: string;
  value: number | undefined;
}

export class MetricUtils {
  static fromProto(proto: clipper.Metric): Metric {
    if (proto.metric == 'net_worth') {
      return { name: proto.metric, value: proto.net_worth };
    } else {
      throw new Error('Unknown metric type: ' + proto.metric);
    }
  }
}

export interface EmploymentState {
  current_salary: number;
}

class EmploymentStateUtils {
  static fromProto(proto: clipper.EmploymentState): EmploymentState {
    return { current_salary: proto.current_salary };
  }
}

export interface CheckingAccountState {
  current_balance: number;
}

class CheckingAccountStateUtils {
  static fromProto(proto: clipper.CheckingAccountState): CheckingAccountState {
    return { current_balance: proto.current_balance };
  }
}

export interface InvestmentState {
  current_balance: number;
}

class InvestmentStateUtils {
  static fromProto(proto: clipper.InvestmentState): InvestmentState {
    return { current_balance: proto.current_balance };
  }
}

export interface ExpenseState {
  current_amount: number;
}

class ExpenseStateUtils {
  static fromProto(proto: clipper.ExpenseState): ExpenseState {
    return { current_amount: proto.current_amount };
  }
}

export interface AppartmentState {
  current_value: number;
}

class AppartmentStateUtils {
  static fromProto(proto: clipper.AppartmentState): AppartmentState {
    return { current_value: proto.current_value };
  }
}

export interface MortgageState {
  current_principal: number;
  current_interest_rate: number;
  interest_paid: number;
  principal_paid: number;
}

class MortgageStateUtils {
  static fromProto(proto: clipper.MortgageState): MortgageState {
    return {
      current_principal: proto.current_principal,
      current_interest_rate: proto.current_interest_rate,
      interest_paid: proto.interest_paid,
      principal_paid: proto.principal_paid
    };
  }
}

export enum ModelElementStateTypes {
  employment_state = 'employment_state',
  checking_account_state = 'checking_account_state',
  investment_state = 'investment_state',
  expense_state = 'expense_state',
  appartment_state = 'appartment_state',
  mortgage_state = 'mortgage_state'
}

// Classes of existing model elements
export enum ModelElementTypes {
  employment = 'employment',
  checking_account = 'checking_account',
  investment = 'investment',
  expense = 'expense',
  appartment = 'appartment',
  mortgage = 'mortgage'
}

export const ModelElementTypeByStateType = {
  [ModelElementStateTypes.employment_state]: ModelElementTypes.employment,
  [ModelElementStateTypes.checking_account_state]:
    ModelElementTypes.checking_account,
  [ModelElementStateTypes.investment_state]: ModelElementTypes.investment,
  [ModelElementStateTypes.expense_state]: ModelElementTypes.expense,
  [ModelElementStateTypes.appartment_state]: ModelElementTypes.appartment,
  [ModelElementStateTypes.mortgage_state]: ModelElementTypes.mortgage
};

export enum BalanceSheetCategory {
  Asset,
  Liability,
  None
}

export enum ElementCategory {
  CashAndEquivalent,
  Investment,
  RealEstate,
  Property,
  RetirementAccount,
  Debt,
  Other
}

export const AssetCategories = [
  ElementCategory.CashAndEquivalent,
  ElementCategory.Investment,
  ElementCategory.RealEstate,
  ElementCategory.Property,
  ElementCategory.RetirementAccount
];

export const LiabilityCategories = [ElementCategory.Debt];

export const ElementCategoryByType = {
  [ModelElementTypes.employment]: ElementCategory.Other,
  [ModelElementTypes.checking_account]: ElementCategory.CashAndEquivalent,
  [ModelElementTypes.investment]: ElementCategory.Investment,
  [ModelElementTypes.expense]: ElementCategory.Other,
  [ModelElementTypes.appartment]: ElementCategory.RealEstate,
  [ModelElementTypes.mortgage]: ElementCategory.Debt
};

export type ModelElementState =
  | EmploymentState
  | CheckingAccountState
  | InvestmentState
  | ExpenseState
  | AppartmentState
  | MortgageState;

export interface ModelElementStateWrapper {
  state:
    | { type: ModelElementStateTypes.employment_state; value: EmploymentState }
    | {
        type: ModelElementStateTypes.checking_account_state;
        value: CheckingAccountState;
      }
    | { type: ModelElementStateTypes.investment_state; value: InvestmentState }
    | { type: ModelElementStateTypes.expense_state; value: ExpenseState }
    | { type: ModelElementStateTypes.appartment_state; value: AppartmentState }
    | { type: ModelElementStateTypes.mortgage_state; value: MortgageState };
}

// A class which links the Model element with it's state data types
// and provides description of the element to simplify the analysis.
class ModelElementDefinition {
  element_type: ModelElementTypes;
  state_type: ModelElementStateTypes;
  category: ElementCategory;
  balance_sheet_category: BalanceSheetCategory;
  value_property_in_state: string | undefined;

  constructor(
    element_type: ModelElementTypes,
    state_type: ModelElementStateTypes,
    category: ElementCategory,
    value_property_in_state?: string
  ) {
    this.element_type = element_type;
    this.state_type = state_type;
    this.category = category;
    this.value_property_in_state = value_property_in_state;
    this.balance_sheet_category = AssetCategories.includes(category)
      ? BalanceSheetCategory.Asset
      : LiabilityCategories.includes(category)
      ? BalanceSheetCategory.Liability
      : BalanceSheetCategory.None;
  }
}

export const ModelElementDefinitions: ModelElementDefinition[] = [
  new ModelElementDefinition(
    ModelElementTypes.employment,
    ModelElementStateTypes.employment_state,
    ElementCategory.Other
  ),
  new ModelElementDefinition(
    ModelElementTypes.checking_account,
    ModelElementStateTypes.checking_account_state,
    ElementCategory.CashAndEquivalent,
    'current_balance'
  ),
  new ModelElementDefinition(
    ModelElementTypes.investment,
    ModelElementStateTypes.investment_state,
    ElementCategory.Investment,
    'current_balance'
  ),
  new ModelElementDefinition(
    ModelElementTypes.expense,
    ModelElementStateTypes.expense_state,
    ElementCategory.Other
  ),
  new ModelElementDefinition(
    ModelElementTypes.appartment,
    ModelElementStateTypes.appartment_state,
    ElementCategory.RealEstate,
    'current_value'
  ),
  new ModelElementDefinition(
    ModelElementTypes.mortgage,
    ModelElementStateTypes.mortgage_state,
    ElementCategory.Debt,
    'current_principal'
  )
];

export const ModelElementDefinitionsByStateType: Record<
  ModelElementStateTypes,
  ModelElementDefinition
> = Object.fromEntries(
  ModelElementDefinitions.map((definition) => [
    definition.state_type as ModelElementStateTypes,
    definition
  ])
) as Record<ModelElementStateTypes, ModelElementDefinition>;

console.log(
  'ModelElementDefinitionsByStateType',
  ModelElementDefinitionsByStateType
);

export const ModelElementDefinitionsByType: Record<
  ModelElementTypes,
  ModelElementDefinition
> = Object.fromEntries(
  ModelElementDefinitions.map((definition) => [
    definition.element_type as ModelElementTypes,
    definition
  ])
) as Record<ModelElementTypes, ModelElementDefinition>;

class ModelElementStateWrapperUtils {
  static fromProto(proto: any): ModelElementStateWrapper {
    if (proto.employment_state) {
      return {
        state: {
          type: ModelElementStateTypes.employment_state,
          value: EmploymentStateUtils.fromProto(proto.employment_state)
        }
      };
    } else if (proto.checking_account_state) {
      return {
        state: {
          type: ModelElementStateTypes.checking_account_state,
          value: CheckingAccountStateUtils.fromProto(
            proto.checking_account_state
          )
        }
      };
    } else if (proto.investment_state) {
      return {
        state: {
          type: ModelElementStateTypes.investment_state,
          value: InvestmentStateUtils.fromProto(proto.investment_state)
        }
      };
    } else if (proto.expense_state) {
      return {
        state: {
          type: ModelElementStateTypes.expense_state,
          value: ExpenseStateUtils.fromProto(proto.expense_state)
        }
      };
    } else if (proto.appartment_state) {
      return {
        state: {
          type: ModelElementStateTypes.appartment_state,
          value: AppartmentStateUtils.fromProto(proto.appartment_state)
        }
      };
    } else if (proto.mortgage_state) {
      return {
        state: {
          type: ModelElementStateTypes.mortgage_state,
          value: MortgageStateUtils.fromProto(proto.mortgage_state)
        }
      };
    } else {
      throw new Error('Unknown state type in ModelElementState message');
    }
  }
}

export interface SimulationState {
  // This looks ineficient. Let's use an object instead of an array
  metrics: Metric[];
  time: number;
  state_by_element: { [key: string]: ModelElementStateWrapper };
}

export class SimulationStateUtils {
  static fromProto(proto: clipper.SimulationState): SimulationState {
    const metrics = proto.metrics.map((metric) =>
      MetricUtils.fromProto(metric)
    );
    const state_by_element: { [key: string]: ModelElementStateWrapper } = {};
    for (const [
      element_name,
      element_state
    ] of proto.state_by_element.entries()) {
      state_by_element[element_name] =
        ModelElementStateWrapperUtils.fromProto(element_state);
    }

    return { metrics, time: proto.time, state_by_element };
  }

  static getCheckingAccountState(
    simulation_state: SimulationState
  ): CheckingAccountState | undefined {
    const checkingAccountState = Object.values(
      simulation_state.state_by_element
    ).find(
      (elementState) => elementState.state.type === 'checking_account_state'
    )?.state.value;

    return checkingAccountState as CheckingAccountState | undefined;
  }
}

export interface SimulationResult {
  states: SimulationState[];
}

export class SimulationResultUtils {
  static fromProto(proto: clipper.SimulationResult): SimulationResult {
    const states = proto.states.map((state) =>
      SimulationStateUtils.fromProto(state)
    ); // Assuming you have a similar method in SimulationStateUtils
    return { states };
  }

  static identifyBankrupcy(
    simulation_result: SimulationResult
  ): number | undefined {
    // iterate over the states and find the first time when the CheckingAccount.current_value is negative
    if (!simulation_result) {
      return undefined;
    }
    // TODO: here the checking acccount is hardcoded, it should be a parameter. But how?
    // Maybe Checking account should be special cased in the model and be easily discovered
    const bankrupcyTime = simulation_result.states.find((state) => {
      const checkingAccountState =
        SimulationStateUtils.getCheckingAccountState(state);
      return (checkingAccountState?.current_balance ?? Infinity) < 0;
    });
    return bankrupcyTime ? bankrupcyTime?.time : undefined;
  }

  // Returns { element_name: element_value } for a given time for elements which are Assets or Liabilities.
  static _getBalanceSheetValuesFromSimulationState(
    simulation_state: SimulationState,
    balance_sheet_category: BalanceSheetCategory
  ): { [key: string]: number } {
    const state = simulation_state.state_by_element;
    const balance_sheet_values: { [key: string]: number } = {};
    for (const [element_name, element_state] of Object.entries(state)) {
      const elementDefinition =
        ModelElementDefinitionsByStateType[element_state.state.type];
      if (elementDefinition.balance_sheet_category === balance_sheet_category) {
        const value_property = elementDefinition.value_property_in_state;
        if (value_property) {
          balance_sheet_values[element_name] = (
            element_state.state.value as any
          )[value_property];
        } else {
          console.warn(
            `No value property found for element ${element_name} in state.`
          );
        }
      }
    }
    return balance_sheet_values;
  }

  // Returns { element_name: element_value } for a given time for elements which are Assets or Liabilities.
  static getBalanceSheetValuesForTime(
    simulation_result: SimulationResult,
    balance_sheet_category: BalanceSheetCategory,
    time: number
  ): { [key: string]: number } {
    const state = simulation_result.states.find((state) => state.time === time);
    if (!state) {
      console.warn(`No state found for time ${time} in simulation result.`);
      return {};
    }

    return SimulationResultUtils._getBalanceSheetValuesFromSimulationState(
      state,
      balance_sheet_category
    );
  }

  // returns array of { time, { 'element_name': element_value } } for elements which are Assets or Liabilities.
  static getBalanceSheetValues(
    simulation_result: SimulationResult,
    balance_sheet_category: BalanceSheetCategory
  ): { time: number; [key: string]: number }[] {
    return simulation_result.states.map((state) => {
      const balance_sheet_values =
        SimulationResultUtils._getBalanceSheetValuesFromSimulationState(
          state,
          balance_sheet_category
        );
      return { time: state.time, ...balance_sheet_values };
    });
  }

  static getFinalTime(
    simulation_result: SimulationResult | null
  ): number | undefined {
    if (!simulation_result) return undefined;
    return simulation_result?.states[simulation_result?.states.length - 1]
      ?.time;
  }

  static getStartTime(
    simulation_result: SimulationResult | null
  ): number | undefined {
    if (!simulation_result) return undefined;
    return simulation_result?.states[0]?.time;
  }
}

// Wrapper classes for the model definition

interface TimePeriod {
  start_time?: number;
  end_time?: number;
}

export interface ElementMetadata {
  id?: number;
  name: string;
  type: ElementCategory;
  export_final_state: boolean;
  time_period?: TimePeriod;
}

class ElementMetadataHelper {
  static toProto(metadata: ElementMetadata): clipper.ElementMetadata {
    return new clipper.ElementMetadata({
      id: metadata.id,
      name: metadata.name,
      type: metadata.type as any,
      export_final_state: metadata.export_final_state,
      time_period: metadata.time_period
        ? TimePeriodHelper.toProto(metadata.time_period)
        : undefined
    });
  }
}

class TimePeriodHelper {
  static toProto(timePeriod: TimePeriod): clipper.ElementMetadata.TimePeriod {
    return new clipper.ElementMetadata.TimePeriod({
      start_time: timePeriod.start_time,
      end_time: timePeriod.end_time
    });
  }
}

export interface Employment {
  metadata: ElementMetadata;
  salary: number;
}

class EmploymentHelper {
  static toProto(employment: Employment): clipper.Employment {
    return new clipper.Employment({
      metadata: ElementMetadataHelper.toProto(employment.metadata),
      salary: employment.salary
    });
  }
}

export interface CheckingAccount {
  metadata: ElementMetadata;
  balance: number;
  reinvest_savings: boolean;
}

class CheckingAccountHelper {
  static toProto(checkingAccount: CheckingAccount): clipper.CheckingAccount {
    return new clipper.CheckingAccount({
      metadata: ElementMetadataHelper.toProto(checkingAccount.metadata),
      balance: checkingAccount.balance,
      reinvest_savings: checkingAccount.reinvest_savings
    });
  }
}

export interface Investment {
  metadata: ElementMetadata;
  balance: number;
  interest_rate: number;
  periodic_deposit: number;
}

class InvestmentHelper {
  static toProto(investment: Investment): clipper.Investment {
    return new clipper.Investment({
      metadata: ElementMetadataHelper.toProto(investment.metadata),
      balance: investment.balance,
      interest_rate: investment.interest_rate,
      periodic_deposit: investment.periodic_deposit
    });
  }
}

export interface Expense {
  metadata: ElementMetadata;
  amount: number;
}

class ExpenseHelper {
  static toProto(expense: Expense): clipper.Expense {
    return new clipper.Expense({
      metadata: ElementMetadataHelper.toProto(expense.metadata),
      amount: expense.amount
    });
  }
}

export interface Appartment {
  metadata: ElementMetadata;
  value: number;
  growth_rate: number;
  maintenance_cost: number;
  purchase_fee: number;
  tax: number;
}

class AppartmentHelper {
  static toProto(appartment: Appartment): clipper.Appartment {
    return new clipper.Appartment({
      metadata: ElementMetadataHelper.toProto(appartment.metadata),
      value: appartment.value,
      growth_rate: appartment.growth_rate,
      maintenance_cost: appartment.maintenance_cost,
      purchase_fee: appartment.purchase_fee,
      tax: appartment.tax
    });
  }
}

export interface Mortgage {
  metadata: ElementMetadata;
  principal: number;
  interest_rate: number;
  duration_years: number;
}

class MortgageHelper {
  static toProto(mortgage: Mortgage): clipper.Mortgage {
    return new clipper.Mortgage({
      metadata: ElementMetadataHelper.toProto(mortgage.metadata),
      principal: mortgage.principal,
      interest_rate: mortgage.interest_rate,
      duration_years: mortgage.duration_years
    });
  }
}

// This is a wrapper class for the proto message ModelElement
export interface ModelElementWrapper {
  element:
    | { type: ModelElementTypes.employment; value: Employment }
    | { type: ModelElementTypes.checking_account; value: CheckingAccount }
    | { type: ModelElementTypes.investment; value: Investment }
    | { type: ModelElementTypes.expense; value: Expense }
    | { type: ModelElementTypes.appartment; value: Appartment }
    | { type: ModelElementTypes.mortgage; value: Mortgage };
}

export class ModelElementWrapperUtils {
  static toProto(modelElement: ModelElementWrapper): clipper.ModelElement {
    switch (modelElement.element.type) {
      case ModelElementTypes.employment:
        return new clipper.ModelElement({
          employment: EmploymentHelper.toProto(modelElement.element.value)
        });
      case ModelElementTypes.checking_account:
        return new clipper.ModelElement({
          checking_account: CheckingAccountHelper.toProto(
            modelElement.element.value
          )
        });
      case ModelElementTypes.investment:
        return new clipper.ModelElement({
          investment: InvestmentHelper.toProto(modelElement.element.value)
        });
      case ModelElementTypes.expense:
        return new clipper.ModelElement({
          expense: ExpenseHelper.toProto(modelElement.element.value)
        });
      case ModelElementTypes.appartment:
        return new clipper.ModelElement({
          appartment: AppartmentHelper.toProto(modelElement.element.value)
        });
      case ModelElementTypes.mortgage:
        return new clipper.ModelElement({
          mortgage: MortgageHelper.toProto(modelElement.element.value)
        });
      default:
        throw new Error(`Unknown type of element : ${modelElement.element}`);
    }
  }

  static getName(modelElement: ModelElementWrapper): string {
    return modelElement.element.value.metadata.name ?? '';
  }

  static getType(modelElement: ModelElementWrapper): ModelElementTypes {
    return modelElement.element.type;
  }

  static getCategory(modelElement: ModelElementWrapper): ElementCategory {
    return ElementCategoryByType[modelElement.element.type];
  }

  static getBalanceSheetCategory(
    modelElement: ModelElementWrapper
  ): BalanceSheetCategory | undefined {
    const category = ModelElementWrapperUtils.getCategory(modelElement);
    if (AssetCategories.includes(category)) {
      return BalanceSheetCategory.Asset;
    } else if (LiabilityCategories.includes(category)) {
      return BalanceSheetCategory.Liability;
    } else {
      return undefined;
    }
  }
}

export interface RealEstatePurchaseEvent {
  time: number;
  appartment: Appartment;
  mortgage: Mortgage;
  contribution: number;
  start_of_period: boolean;
}

class RealEstatePurchaseEventHelper {
  static toProto(
    realEstatePurchaseEvent: RealEstatePurchaseEvent
  ): clipper.RealEstatePurchaseEvent {
    return new clipper.RealEstatePurchaseEvent({
      time: realEstatePurchaseEvent.time,
      appartment: AppartmentHelper.toProto(realEstatePurchaseEvent.appartment),
      mortgage: MortgageHelper.toProto(realEstatePurchaseEvent.mortgage),
      contribution: realEstatePurchaseEvent.contribution,
      start_of_period: realEstatePurchaseEvent.start_of_period
    });
  }
}

export interface RemoveElementEvent {
  time: number;
  element_name?: string;
  start_of_period: boolean;
}

class RemoveElementEventHelper {
  static toProto(
    removeElementEvent: RemoveElementEvent
  ): clipper.RemoveElementEvent {
    return new clipper.RemoveElementEvent({
      time: removeElementEvent.time,
      start_of_period: removeElementEvent.start_of_period
    });
  }
}

export enum ModelEventTypes {
  real_estate_purchase = 'real_estate_purchase',
  remove_element = 'remove_element'
}

export interface ModelEventWrapper {
  event:
    | {
        type: ModelEventTypes.real_estate_purchase;
        value: RealEstatePurchaseEvent;
      }
    | { type: ModelEventTypes.remove_element; value: RemoveElementEvent };
}

class ModelEventWrapperHelper {
  static toProto(modelEvent: ModelEventWrapper): clipper.ModelEvent {
    switch (modelEvent.event.type) {
      case ModelEventTypes.real_estate_purchase:
        return new clipper.ModelEvent({
          real_estate_purchase: RealEstatePurchaseEventHelper.toProto(
            modelEvent.event.value
          )
        });
      case ModelEventTypes.remove_element:
        return new clipper.ModelEvent({
          remove_element: RemoveElementEventHelper.toProto(
            modelEvent.event.value
          )
        });
      default:
        throw new Error(`Unknown type for event: ${modelEvent.event}`);
    }
  }
}

export type ModelElement =
  | Employment
  | CheckingAccount
  | Investment
  | Expense
  | Appartment
  | Mortgage;

export interface FinancialModel {
  elements: ModelElementWrapper[];
  events: ModelEventWrapper[];
  start_time?: number;
  end_time?: number;

  // extra fields present only on the frontend

  // Events can create elements dynamically, so these elements
  // are not present in the elements array. This map is used to
  // keep track of all elements, including ones created by events.
  featuring_element_type_by_name: { [key: string]: ModelElementTypes };
}

export class FinancialModelHelper {
  static toProto(financialModel: FinancialModel): clipper.FinancialModel {
    const model = new clipper.FinancialModel();
    model.elements = financialModel.elements.map((element) =>
      ModelElementWrapperUtils.toProto(element)
    );
    model.events = financialModel.events.map((event) =>
      ModelEventWrapperHelper.toProto(event)
    );
    if (financialModel.start_time) {
      model.start_time = financialModel.start_time;
    }

    if (financialModel.end_time) {
      model.end_time = financialModel.end_time;
    }
    return model;
  }
}

// A container with model definition and the serialized proto object
export interface ModelDefinition {
  financial_model: FinancialModel | null;
  // ok, I messed up here, because this turned out not serializable by redux. I will have to
  // rewrte this part. but for now, will make it work to reduce the calls to backend.
  proto_model: clipper.FinancialModel | undefined;
  proto_model_serialized?: string | undefined;
}
