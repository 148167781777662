//eslint-disable-next-line
import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { UserInputFormState } from 'clipper/project';
import {
  NumericTextInput,
  RateInput
} from 'components/user_inputs/NumericTextInput';
//eslint-disable-next-line
import debounce from 'lodash/debounce';

export interface PropertyFormState extends UserInputFormState {
  inputs: {
    purchase_value: number;
    planned_year_of_purchase: number;
    planned_year_of_purchase_error_message: string;
    maintenance_cost: number;
    purchase_fee: number;
    tax: number;
    growth_rate: number;
  };
}

export interface PropertyFormProps {
  formState: PropertyFormState;
  onFormStateUpdate: (newState: PropertyFormState) => void;
}

const PropertyForm: React.FC<PropertyFormProps> = ({
  formState,
  //eslint-disable-next-line
  onFormStateUpdate
}) => {
  const {
    inputs: {
      purchase_value,
      planned_year_of_purchase,
      planned_year_of_purchase_error_message,
      maintenance_cost,
      purchase_fee,
      tax,
      growth_rate
    }
  } = formState;

  const handlePurchaseValueChange = (value: number | undefined) => {
    const newState = {
      ...formState,
      inputs: {
        ...formState.inputs,
        purchase_value: value === undefined ? 0 : value
      }
    };
    onFormStateUpdate(newState);
  };

  const handlePlannedYearOfPurchaseChange = (e: Date | null) => {
    if (e) {
      const planned_year_of_purchase = e.getFullYear();
      const newState = {
        ...formState,
        inputs: {
          ...formState.inputs,
          planned_year_of_purchase,
          planned_year_of_purchase_error_message: ''
        }
      };
      onFormStateUpdate(newState);
    } else {
      const newState = {
        ...formState,
        inputs: {
          ...formState.inputs,
          planned_year_of_purchase_error_message: 'required'
        }
      };
      onFormStateUpdate(newState);
    }
  };

  const handleMaintenanceCostChange = (value: number | undefined) => {
    if (value === undefined) {
      throw new Error('Maintenance cost cannot be here');
    }
    const newState = {
      ...formState,
      inputs: {
        ...formState.inputs,
        maintenance_cost: value
      }
    };
    onFormStateUpdate(newState);
  };

  const handlePurchaseFeeChange = (value: number | undefined) => {
    if (value === undefined) {
      throw new Error('Purchase fee cannot be undefined');
    }
    const newState = {
      ...formState,
      inputs: {
        ...formState.inputs,
        purchase_fee: value
      }
    };
    onFormStateUpdate(newState);
    // debouncedUpdateFormState(newState);
  };

  const handleTaxChange = (value: number | undefined) => {
    if (value === undefined) {
      throw new Error('Tax cannot be undefined');
    }
    const newState = {
      ...formState,
      inputs: {
        ...formState.inputs,
        tax: value
      }
    };
    onFormStateUpdate(newState);
    // debouncedUpdateFormState(newState);
  };

  const updateRate = (value: number) => {
    const newState = {
      ...formState,
      inputs: { ...formState.inputs, growth_rate: value }
    };
    onFormStateUpdate(newState);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {/* TODO(valru): set the available time range to Project simulation range */}
          <DatePicker
            minDate={new Date(2024, 0)}
            maxDate={new Date(2055, 0)}
            label="Year of Purchase"
            value={new Date(planned_year_of_purchase, 0)}
            onChange={handlePlannedYearOfPurchaseChange}
            views={['year']}
            sx={{ maxWidth: '150px' }}
            slotProps={{
              textField: {
                helperText: planned_year_of_purchase_error_message,
                error: planned_year_of_purchase_error_message !== '',
                inputProps: { style: { textAlign: 'right' } }
              }
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <NumericTextInput
          value={purchase_value?.toString() ?? ''}
          onChangeCustom={handlePurchaseValueChange}
          label="Purchase Value"
          missingValue={undefined}
        />
      </Grid>
      <Grid item xs={12}>
        <NumericTextInput
          value={maintenance_cost.toString()}
          onChangeCustom={handleMaintenanceCostChange}
          label="Maintenance Cost"
        />
      </Grid>
      <Grid item xs={12}>
        <NumericTextInput
          value={purchase_fee.toString()}
          onChangeCustom={handlePurchaseFeeChange}
          label="Purchase Fee"
        />
      </Grid>
      <Grid item xs={12}>
        <NumericTextInput
          value={tax.toString()}
          onChangeCustom={handleTaxChange}
          label="Tax"
        />
      </Grid>
      <Grid item xs={12}>
        <RateInput
          label="Growth Rate"
          value={growth_rate.toString()}
          onChangeCustom={updateRate}
        />
      </Grid>
    </Grid>
  );
};

export default PropertyForm;
