// assets
import {
  DashboardOutlined,
  InfoOutlined,
  HomeOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  InfoOutlined,
  HomeOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Buy vs Rent',
      type: 'item',
      url: '/buy-vs-rent',
      icon: icons.HomeOutlined,
      breadcrumbs: false
    },
    {
      id: 'support',
      title: 'About',
      type: 'item',
      url: '/about',
      icon: icons.InfoOutlined,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
