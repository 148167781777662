/**
 * Input form for real estate purchase event.
 * It is a composition of PropertyForm (to be renamed as RealEstate)
 * and FinancingPlanForm
 */
//eslint-disable-next-line
import { Typography, Paper, Box } from '@mui/material';
//eslint-disable-next-line
import React, { useState, useCallback, useEffect } from 'react';
import PropertyForm, {
  PropertyFormState
} from 'components/user_inputs/PropertyForm';
import FinancingPlanForm, {
  FinancingPlanFormState
} from 'components/user_inputs/FinancingPlanForm';
import { UserInputFormState } from 'clipper/project';
// eslint-disable-next-line
import debounce from 'lodash/debounce';
import Divider from '@mui/material/Divider';

export interface RealEstatePurchaseFormState extends UserInputFormState {
  inputs: {
    property: PropertyFormState;
    financingPlan: FinancingPlanFormState;
  };
  isValid: boolean;
}

export interface RealEstatePurchaseFormProps {
  formState: RealEstatePurchaseFormState;
  onFormStateUpdate: (newState: RealEstatePurchaseFormState) => void;
}

const RealEstatePurchaseForm: React.FC<RealEstatePurchaseFormProps> = ({
  formState,
  onFormStateUpdate
}) => {
  // validates the candidate state and returns a modified state and updates the validity status and error messages
  // if necessary
  const validateNewState = (
    newState: RealEstatePurchaseFormState
  ): RealEstatePurchaseFormState => {
    const propertyState = newState.inputs.property;
    const financingState = newState.inputs.financingPlan;
    if (!propertyState.isValid || !financingState.isValid) {
      return { ...newState, isValid: false };
    }
    const total = propertyState.inputs.purchase_value!;

    let principalErrorMessage = '';
    let isValid = true;

    if (
      total !=
      financingState.inputs.equity + financingState.inputs.mortgage.principal
    ) {
      principalErrorMessage = 'Equity + Principal should equal property price';
      isValid = false;
    }
    const newFinancingState = {
      ...financingState,
      inputs: {
        ...financingState.inputs,
        mortgage: {
          ...financingState.inputs.mortgage,
          principalErrorMessage: principalErrorMessage
        }
      }
    };
    return {
      ...newState,
      isValid: isValid,
      inputs: {
        ...newState.inputs,
        financingPlan: newFinancingState
      }
    };
  };

  const handlePropertyFormStateUpdate = (propertyState: PropertyFormState) => {
    const newFormState = validateNewState({
      ...formState,
      inputs: {
        ...formState.inputs,
        property: propertyState
      }
    });
    onFormStateUpdate(newFormState);
  };

  const handleFinancingPlanFormStateUpdate = (
    financingPlanState: FinancingPlanFormState
  ) => {
    const newFormState = validateNewState({
      ...formState,
      inputs: {
        ...formState.inputs,
        financingPlan: financingPlanState
      }
    });
    onFormStateUpdate(newFormState);
  };

  return (
    <div>
      <Box mb={2}>
        <Box mb={2}>
          <Typography variant="h6">Real Estate</Typography>
        </Box>
        <PropertyForm
          formState={formState.inputs.property}
          onFormStateUpdate={handlePropertyFormStateUpdate}
        />
      </Box>
      <Divider />
      <Box mb={2}>
        <Box mb={2}>
          <Typography variant="h6">Financing Plan</Typography>
        </Box>
        <FinancingPlanForm
          target_value={formState.inputs.property.inputs.purchase_value!}
          formState={formState.inputs.financingPlan}
          onFormStateUpdate={handleFinancingPlanFormStateUpdate}
        />
      </Box>
    </div>
  );
};

export default RealEstatePurchaseForm;
