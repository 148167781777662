import React from 'react';
import { Typography } from '@mui/material';
import InformationalCard, {
  ShortInfo,
  LongInfo
} from 'components/analytics/InformationalCard';

const BuyVsRentLongInfoHelp = () => {
  return (
    <div>
      <Typography variant="h5">What is the Buy vs Rent application?</Typography>
      <Typography variant="body1">
        The application compares two scenarios:
        <ul>
          <li>
            Baseline scenario (&quot;Rent&quot;): Renting the primary residence
            thoughout the simulation period.
          </li>
          <li>
            Main scenario (&quot;Buy&quot;): Buying the property by
            (potentially) taking a mortgage.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5">How are the scenarios compared?</Typography>
      <Typography variant="body1">
        We use the parameters you provide to simulate the values of assets and
        liabilities over time. Here are the assumptions that we use:
        <ul>
          <li>
            For scenario &quot;Rent&quot;, we assume that your assets are a
            checking account and potentially an investment account. We assume
            that you do not have any liabilities.
          </li>
          <li>
            For scenario &quot;Buy&quot; we assume that your assets are a real
            estate property, a checking account, and potentially an investment
            account. We assume that your only liability is a mortgage.
          </li>
          <li>
            The simulation starts at the beginning of the year and runs for 30
            years by default. You can adjust the time range of the simulation in
            the project settings.
          </li>
          <li>
            The simulation is run on a yearly basis. Every year, the situation
            on January 1 is reported. Example: if the real estate is purchased
            in 2024, we assume that the purchase happened 1 January 2024.
          </li>
        </ul>
        After we simulate the values of assets and liabilities over time, we
        compare the scenarios by Net Worth. Net Worth is the sum of all assets
        minus the sum of all liabilities at a specific point in time. You can
        read more about Net Worth in{' '}
        <a
          href="https://en.wikipedia.org/wiki/Net_worth"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wikipedia
        </a>
        . The scenario with the higher Net Worth is considered to be the better
        scenario (from the financial point of view). The results should be
        interpreted with caution. After comparing the scenarios by Net Worth, we
        explain the result by looking at dynamic of the assets and liabilities
        over time. Net worth is not the only metric that you should consider
        when making a decision, that is why we provide additional metrics to
        help you understand the financial situation better. Other sections of
        the analysis include:
        <ul>
          <li>
            Mortgage Details: Deep dive into the repayment of principal and
            interest payed.
          </li>
          <li>
            Cashflow structure. This section shows how will your available for
            discretionary spending cash evolve and what is your expense
            structure.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5">What are the model parameters?</Typography>
      <Typography variant="body1">
        To compare the scenarios, you need to configure the input parameters on
        the right panel. The main parameters are:
        <ul>
          <li>
            Net Income: Yearly net income after taxes that you would like the
            simulation to run with.
          </li>
          <li>
            Rent: The <>monthly</> rent for a property that you consider
            renting.
          </li>
          <li>
            Other expenses: Average yearly expenses other than rent. This
            includes food, transport vacations, etc. We use this parameter to
            compute your yearly saving rate and understand if you are able to
            pay the rent and interest on mortgage.
          </li>
          <li>
            Purchase value: The market value of the real estate which you
            consider buying.
          </li>
          <li>
            Purchase fee: Buying real estate typically includes paying a form of
            a fee for the transfer of the property rights (known as &quot;Stamp
            tax&quot;). This is a one-time payment which happens at the moment
            of the purchase.
          </li>
          <li>
            Maintainance Cost: Yearly amount of money spent on small
            repairments. Typically estimated at 1% of the property values.
          </li>
          <li>
            Tax: Buying real estate typically leads to an extra tax. You can
            specify the amount of this tax in absolute amount or relative to the
            value of the property.
          </li>
          <li>
            Growth rate: Real estate typically appreciates over time (the market
            value increases). Growth rate specifies in percent the change of the
            value property in a year. Attention: this is a very important
            parameter as it can significantly affect the financial outcome of
            the simulation. Make a good research on the historical growth rates
            in your area to get a good estimate.
          </li>
          <li>
            Equity: The amount of money from savings that you are willing to put
            into the property. Note, that this amount is not considered as
            expense in analysis of your project. In is considered a transfer of
            the money from one asset to another: you owned the money in the bank
            account and now you own the money in the property.
          </li>
          <li>
            Principal: The amount of money that you are willing to borrow from
            the bank. This amount is considered as a liability in the analysis.
            You will need to return this money to the bank over time and pay the
            interest on it.
          </li>
          <li>
            Interest rate: The yearly interest rate on the mortgage. This is the
            cost of borrowing the money from the bank.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5">Disclaimer</Typography>
      <Typography variant="body1">
        <blockquote>
          &quot;All models are wrong, but some are useful.&quot;
          <footer>
            —{' '}
            <cite>
              <a
                href="https://en.wikipedia.org/wiki/All_models_are_wrong"
                target="_blank"
                rel="noopener noreferrer"
              >
                George Box
              </a>
            </cite>
          </footer>
        </blockquote>
        The Buy vs Rent application is a simplified model of the real world and
        the conclusions it provides are based on the values of parameters you
        provide and hypothesis that the parameters will remain stable over time.
        There are many other factors which you should take into account when
        taking important decisions, e.g.:
        <ul>
          <li>The quality of life when owning vs renting.</li>
          <li>Flexibility to move to another location.</li>
          <li>
            The feeling of owning your own property and the freedom to make
            changes to it.
          </li>
          <li>
            The increase responsibility of owning the property: mowning the law,
            fixing the roof, etc...
          </li>
        </ul>
      </Typography>
    </div>
  );
};

export default BuyVsRentLongInfoHelp;

export const BuyVsRentInfoCard = () => {
  return (
    <InformationalCard>
      <ShortInfo>
        Buy vs Rent application helps comparing the financial outcomes of buying
        the primary residence real estate property vs renting it.
      </ShortInfo>
      <LongInfo>
        <BuyVsRentLongInfoHelp />
      </LongInfo>
    </InformationalCard>
  );
};

export const BuyVsRentNotesCard = () => {
  return (
    <InformationalCard>
      <ShortInfo>
        <Typography variant="body1">
          <blockquote>
            &quot;All models are wrong, but some are useful.&quot;
            <footer>
              —{' '}
              <cite>
                <a
                  href="https://en.wikipedia.org/wiki/All_models_are_wrong"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  George Box
                </a>
              </cite>
            </footer>
          </blockquote>
          The Buy vs Rent application is a simplified model of the real world
          and the conclusions it provides are based on the values of parameters
          you provide and hypothesis that the parameters will remain stable over
          time. There are many other factors which you should take into account
          when taking important decisions, e.g.:
          <ul>
            <li>The quality of life when owning vs renting.</li>
            <li>Flexibility to move to another location.</li>
            <li>
              The feeling of owning your own property and the freedom to make
              changes to it.
            </li>
            <li>
              The increase responsibility of owning the property: mowning the
              law, fixing the roof, etc...
            </li>
          </ul>
        </Typography>
      </ShortInfo>
    </InformationalCard>
  );
};
