import { createSlice } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
//eslint-disable-next-line
import { SimulationStatus } from 'clipper/project';

const initialState = {
  status: SimulationStatus.STALE, // initial status
  status_message: 'Stale'
};

const identifyBankrupcy = (currentSimulationResult) => {
  // iterate over the states and find the first time when the CheckingAccount.current_value is negative
  if (!currentSimulationResult) {
    return null;
  }

  const bankrupcyTime = currentSimulationResult.states.find(
    (state) => state?.state_by_element?.['CheckingAccount']?.current_value < 0
  );
  return bankrupcyTime ? bankrupcyTime?.time : null;
};

const simulationResultsMiddleware = (storeAPI) => (next) => (action) => {
  // Call the next dispatch method in the middleware chain.
  let returnValue = next(action);

  // If the dispatched action is setSimulationResults, dispatch updateSimulationResult
  if (action.type === 'simulation/setSimulationResults') {
    const debouncedSetSimulationStatus = debounce(
      (status) => {
        storeAPI.dispatch(setSimulationStatus(status));
      },
      1000,
      { leading: false, trailing: true }
    );
    const bankrupcyTime = storeAPI.getState().simulation.bankrupcyTime;
    if (bankrupcyTime != null) {
      debouncedSetSimulationStatus({
        status: SimulationStatus.FAILED,
        status_message: `Bankrupcy in ${bankrupcyTime}`
      });
    } else {
      debouncedSetSimulationStatus({
        status: SimulationStatus.UP_TO_DATE,
        status_message: 'Up to date'
      });
    }
  }

  // This will likely be the action itself, unless
  // a middleware further in chain changed it.
  return returnValue;
};

const simulationSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    setSimulationStatus: (state, action) => {
      state.status = action.payload.status;
      state.status_message = action.payload.status_message;
    },
    setSimulationResults: (state, action) => {
      state.simulationResult = action.payload.simulationResult;
      state.baselineSimulationResult = action.payload.baselineSimulationResult;
      const bankrupcyTime = identifyBankrupcy(state.simulationResult);
      state.bankrupcyTime = bankrupcyTime;
    },
    setSimulationResultsProto: (state, action) => {
      state.simulationResultProto = action.payload.simulationResult;
      state.baselineSimulationResultProto =
        action.payload.baselineSimulationResult;
      const bankrupcyTime = identifyBankrupcy(state.simulationResultProto);
      state.bankrupcyTimeProto = bankrupcyTime;
    }
  }
});

export const {
  setSimulationStatus,
  setSimulationResults,
  setSimulationResultsProto
} = simulationSlice.actions;
export { SimulationStatus, simulationResultsMiddleware };
export default simulationSlice.reducer;
