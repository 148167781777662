import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import MainCard from 'components/MainCard';

const About = () => {
  return (
    <MainCard title="About Our Application">
      <Box sx={{ padding: 2 }}>
        <Typography variant="body1">
          This is an application designed to assist users in building financial
          models. It is intended for educational and informational purposes
          only, does not constitute financial advice, nor should it be construed
          as a substitute for professional advice from a qualified financial
          advisor.
        </Typography>
        <Typography variant="body1">
          The outputs generated by this application are based on the data input
          by the user and assumptions predetermined by the software. These
          outputs are to be used as a guide only and are subject to the
          limitations of the modeling process. Users are encouraged to conduct
          their own analysis and consult with professional advisors to support
          their decision-making process.
        </Typography>
        <Typography variant="body1">
          By using this application, users acknowledge and agree that the
          responsibility for any financial decisions made, based on the use of
          the application or its outputs, lies solely with the user. The
          creators, developers, and distributors of the application disclaim all
          liability for any loss or damage which may arise directly or
          indirectly from the use of this application or reliance on any
          information it generates.
        </Typography>
        <Typography variant="body1">
          We make no representations or warranties of any kind, express or
          implied, about the completeness, accuracy, reliability, suitability,
          or availability with respect to the application or the information,
          products, services, or related graphics contained on the application
          for any purpose. Any reliance you place on such information is
          therefore strictly at your own risk.
        </Typography>
        <Typography variant="body1">
          Please review our <Link to="/privacy-notice">Privacy Policy</Link> and{' '}
          <Link to="/terms-of-service">Terms of Service</Link> for more
          information about how we handle your data and the terms of using our
          application.
        </Typography>
        <Typography variant="body1">
          If you have any inquiries or need further assistance, you can contact
          us by email at <a href="mailto:TODO">TODO</a>.
        </Typography>
      </Box>
    </MainCard>
  );
};

export default About;
