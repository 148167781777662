import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectProjectSettings,
  updateProjectSettings
} from 'store/reducers/project';
// import { throttle } from 'lodash';
import { Slider, Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const ProjectSettingsEditor = () => {
  // const UPDATE_INTERVAL_MS = 1500;
  const project_settings = useSelector(selectProjectSettings);
  const dispatch = useDispatch();

  const start_year = project_settings?.model_start_year || 2024;
  const end_year = project_settings?.model_end_year || 2054;

  const handleChange = (event, newValue) => {
    const updatedProjectSettings = {
      ...project_settings,
      model_start_year: newValue[0],
      model_end_year: newValue[1]
    };

    dispatch(updateProjectSettings(updatedProjectSettings));
  };

  return (
    <Box sx={{ minWidth: 400 }}>
      <Typography id="range-slider" gutterBottom>
        Year range: First and last years when model is simulated.
      </Typography>
      <Slider
        sx={{ pt: 0 }}
        value={[start_year, end_year]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        min={2010}
        max={2070}
        step={1}
        marks
        disableSwap
      />
    </Box>
  );
};

export default ProjectSettingsEditor;
