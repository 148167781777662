// authService.js
import { auth } from './firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth';

export const signUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

// authService.js

export const signInWithGoogle = () => {
  console.log('sign in with google');
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};

// Add more functions as needed
