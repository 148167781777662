import { Checkbox, Grid, Typography } from '@mui/material';
import { UserInputFormState } from 'clipper/project';
import { NumericTextInput } from 'components/user_inputs/NumericTextInput';

export interface AssetFormState extends UserInputFormState {
  name: string;
  inputs: {
    yearly_savings: number;
    savings: number;
    reinvest_savings: boolean;
    monthly_rent: number;
    netIncomeErrorMessage: string;
    rentErrorMessage: string;
  };
}

export interface AssetsFormProps {
  formState: AssetFormState;
  onFormStateUpdate: (newState: AssetFormState) => void;
}

function AssetsForm({ formState, onFormStateUpdate }: AssetsFormProps) {
  // const { t } = useTranslation();
  const handleNetIncomeBeforeRentChange = (value: number | undefined) => {
    // the value cannot be undefined here
    if (value === undefined) {
      throw new Error('Net income before rent cannot be undefined');
    }
    const newState = {
      ...formState,
      inputs: {
        ...formState.inputs,
        yearly_savings: value
      }
    };
    onFormStateUpdate(newState);
  };

  const handleReinvestSavingChange = (value: boolean | undefined) => {
    // the value cannot be undefined here
    if (value === undefined) {
      throw new Error('Net income before rent cannot be undefined');
    }
    const newState = {
      ...formState,
      inputs: {
        ...formState.inputs,
        reinvest_savings: value
      }
    };
    onFormStateUpdate(newState);
  };

  const handleMonthlyRentChange = (value: number | undefined) => {
    if (value === undefined) {
      throw new Error('Monthly rent cannot be undefined');
    }
    const newState = {
      ...formState,
      inputs: {
        ...formState.inputs,
        monthly_rent: value
      }
    };
    onFormStateUpdate(newState);
  };

  const handleSavingsChange = (value: number | undefined) => {
    if (value === undefined) {
      throw new Error('Savings cannot be undefined');
    }
    const newState = {
      ...formState,
      inputs: {
        ...formState.inputs,
        savings: value
      }
    };
    onFormStateUpdate(newState);
  };

  return (
    <Grid container direction="column" spacing={2} >
      <Grid item>
        <NumericTextInput
          value={formState.inputs.yearly_savings.toString()}
          onChangeCustom={handleNetIncomeBeforeRentChange}
          label="Net Income Before Rent"
        />
      </Grid>
      <Grid container direction="row" spacing={2} alignItems="center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid item>
          <Typography variant="body2">Reinvest Savings?</Typography>
        </Grid>
        <Grid item>
          <Checkbox size="small" checked={formState.inputs.reinvest_savings} onChange={(event) => handleReinvestSavingChange(event.target.checked)} ></Checkbox>
        </Grid>
      </Grid>
      <Grid item>
        <NumericTextInput
          value={formState.inputs.savings.toString()}
          onChangeCustom={handleSavingsChange}
          label="Savings"
        />
      </Grid>
      <Grid item>
        <NumericTextInput
          value={formState.inputs.monthly_rent.toString()}
          onChangeCustom={handleMonthlyRentChange}
          label="Monthly Rent"
        />
      </Grid>
    </Grid>
  );
}

export default AssetsForm;
