// ==============================|| THEME CONFIG  ||============================== //

interface Config {
  defaultPath: string;
  fontFamily: string;
  i18n: string;
  miniDrawer: boolean;
  container: boolean;
  mode: string;
  presetColor: string;
  themeDirection: string;
  backendAddress: string | undefined;
  backendToken: string | undefined;
}

const config: Config = {
  defaultPath: '/buy-vs-rent',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
  backendAddress: process.env.REACT_APP_BACKEND_ADDRESS,
  backendToken: process.env.REACT_APP_BACKEND_JWT_TOKEN
};

export default config;
export const drawerWidth = 260;
export const rightDrawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';
