export class SingletonFactory {
  private instances: Map<new () => any, any>;

  constructor() {
    this.instances = new Map();
  }

  get<T>(Type: new () => T): T {
    if (!this.instances.has(Type)) {
      this.instances.set(Type, new Type());
    }
    return this.instances.get(Type) as T;
  }
}

const singletonFactory = new SingletonFactory();

export default singletonFactory;
