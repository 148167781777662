// eslint-disable-next-line
import {
  FinancialModel,
  // ModelDefinition,
  ModelElementState,
  SimulationResult,
  ModelElementWrapperUtils,
  ModelElementStateWrapper
} from 'clipper/model_elements';
import {
  Project,
  Scenario,
  ElementSimulationResult,
  ElementSimulationStateByScenario,
  TimeSeries,
  IndexableType,
  SimulationStatus
} from 'clipper/project';
import { RegisteredApplicationId } from 'clipper/application';
/**Helper to work with Project objects, since they must be primitives to be stored in redux store */
export class ProjectUtils {
  static defaultProject(): Project {
    return {
      name: 'New Project',
      scenarios: {},
      userInputFormStates: {},
      isInputValid: false,
      project_settings: {
        model_start_year: new Date().getFullYear(),
        model_end_year: new Date().getFullYear() + 30
      },
      project_status: {
        status: SimulationStatus.UP_TO_DATE,
        status_message: ''
      },
      registered_application_id: RegisteredApplicationId.Unknown
    };
  }

  static getScenarioByName(
    project: Project,
    name: string
  ): Scenario | undefined {
    return project.scenarios[name];
  }

  static setSimulationResult(
    project: Project,
    scenarioName: string,
    result: SimulationResult
  ): Project {
    const scenario = ProjectUtils.getScenarioByName(project, scenarioName);
    if (scenario === undefined) {
      throw new Error('Scenario not found: ' + scenarioName);
    }

    const newScenario = {
      ...scenario,
      result: result
    };

    return {
      ...project,
      scenarios: {
        ...project.scenarios,
        [scenarioName]: newScenario
      }
    };
  }

  static getElementNames(model: FinancialModel): string[] {
    return model.elements.map((element_wrapper) =>
      ModelElementWrapperUtils.getName(element_wrapper)
    );
  }

  // Extracts the time series for a given element and property from a simulation result
  static timeSeriesFromModelSimulationResult(
    simulation_result: SimulationResult,
    element_name: string,
    property_name: string
  ): TimeSeries {
    const state_in_time = simulation_result.states
      .map((state) => {
        const element_state_wrapper = state.state_by_element[element_name] as
          | ModelElementStateWrapper
          | undefined;
        if (element_state_wrapper === undefined) {
          return null;
        }
        return {
          time: state.time,
          value: (element_state_wrapper.state.value as any)[
            property_name
          ] as number
        };
      })
      .filter((x) => x !== null);
    return {
      points: state_in_time as { time: number; value: number }[],
      name: property_name
    };
  }

  static timeSeriesFromElementSimulationResult<T extends IndexableType>(
    elementSimulationResult: ElementSimulationResult<T>,
    property_name: string,
    round = true
  ): TimeSeries {
    return {
      points: elementSimulationResult.state_in_time.map((s_in_t) => {
        return {
          time: s_in_t.time,
          value: round
            ? Math.round(s_in_t.state[property_name] as number)
            : (s_in_t.state[property_name] as number)
        };
      }),
      name: property_name
    };
  }

  static getElementSimulationResult<T extends ModelElementState>(
    simulation_result: SimulationResult,
    element_name: string
  ): ElementSimulationResult<T> | null {
    const state_in_time = simulation_result.states
      .map((state) => {
        const state_wrapper = state.state_by_element[element_name];
        if (state_wrapper === undefined) {
          return null;
        }
        const element_state = state_wrapper.state.value as T;
        return {
          time: state.time,
          state: element_state
        };
      })
      .filter((x) => x !== null);
    // If there are no states for the element, return null
    if (state_in_time.length === 0) {
      return null;
    }
    return {
      scenario_name: '', //TODO: how to get the scenario name?
      element_name: element_name,
      state_in_time: state_in_time as { time: number; state: T }[]
    };
  }

  static getElementSimulationStateByScenario<T extends ModelElementState>(
    project: Project,
    element_name: string
  ): ElementSimulationStateByScenario<T> {
    const result: ElementSimulationStateByScenario<T> = {};
    for (const scenarioName in project.scenarios) {
      const scenario = project.scenarios[scenarioName];
      const simulation_result = scenario.simulation_result; // Assuming each scenario has a simulation_result property
      if (simulation_result === null) {
        continue;
      }
      const element_simulation_result =
        ProjectUtils.getElementSimulationResult<T>(
          simulation_result,
          element_name
        );
      if (element_simulation_result === null) {
        continue;
      }
      element_simulation_result.scenario_name = scenarioName;
      result[scenarioName] = element_simulation_result;
    }
    return result;
  }

  static formatTimeSeriesNameWithScenario(
    time_series_name: string,
    scenario_name: string
  ): string {
    return `Scenario (${scenario_name}): ${time_series_name} `;
  }
}

//eslint-disable-next-line
export class TimeSeriesUtils {}

// const timeSeriesFromElementSimulationResult = <T extends IndexableType>(elementSimulationResult: ElementSimulationResult<T>, property_name: string): TimeSeries => {
//   return {
//     points: elementSimulationResult.state_in_time.map((s_in_t) => {
//       return {
//         time: s_in_t.time,
//         value: s_in_t.state[property_name] as number
//       };
//     }),
//     name: property_name
//   };
// }
