// import { Typography } from '@mui/material';
import ProjectSettingsDialogButton from './ProjectSettingsDialogButton';
import ShareDialogButton from '../sharing/ShareButton';
// import { useSelector } from 'react-redux';
// import { selectProjectSettings } from 'store/reducers/project';
// import dayjs from 'dayjs';
// import { Grid } from '@mui/material';

const ProjectSettingsHeadline = () => {
  // const project_settings = useSelector(selectProjectSettings);

  // const beforeDate = dayjs(new Date(project_settings?.model_start_year, 1, 1));
  // const afterDate = dayjs(new Date(project_settings?.model_end_year, 1, 1));

  return (<>
    <ProjectSettingsDialogButton />
    <ShareDialogButton/>
    </>
    // <div>
    //   <Grid container row alignItems="center">
    //     <Grid item>
    //       <ProjectSettingsDialogButton />
    //     </Grid>
    //     {/* <Grid item>
    //       <Typography> {beforeDate.format('YYYY')} - {afterDate.format('YYYY')} </Typography>
    //     </Grid> */}
    //   </Grid>
    // </div>
  );
};

export default ProjectSettingsHeadline;
