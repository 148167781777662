import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ProjectSettingsEditor from './ProjectSettingsEditor';
import { useState } from 'react';
import { DialogActions, Button, DialogContent } from '@mui/material';
// import { Box } from '@mui/material';

const ProjectSettingsDialogButton = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <SettingsIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={openDialog}>
        <DialogTitle>Project settings</DialogTitle>
        <DialogContent>
          <ProjectSettingsEditor />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectSettingsDialogButton;
