import { useEffect, RefObject } from 'react';

export function useOutsideClick(ref: RefObject<HTMLElement>, callback: () => void, dependencies: any[]) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, dependencies);
}