/**
 * Registry of all existing user input forms for model elements.
 * Right drawer uses this registry to show the forms for the current project.
 */
import AssetsForm from 'components/user_inputs/AssetsForm';
import RealEstatePurchaseForm from 'components/user_inputs/RealEstatePurchaseForm';

interface ComponentByFormName {
  [formName: string]: React.FC<any>;
}

const COMPOSER_REGISTRY: ComponentByFormName = {
  Assets: AssetsForm,
  RealEstatePurchase: RealEstatePurchaseForm
  // Add other form components here
};

export default COMPOSER_REGISTRY;
