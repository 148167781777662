import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

import NavigationDrawer from './Drawer';
import Header from './Header';

import { openDrawer } from 'store/reducers/menu';

import RightDrawer from './RightDrawer';
import { drawerWidth, rightDrawerWidth } from 'config';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  const computeDrawersWidth = () => {
    let total_width = rightDrawerWidth;
    if (matchDownLG) return total_width;

    if (open) total_width += drawerWidth;
    return total_width;
  };

  return (
    <Box sx={{ display: 'flex', width: '90%' }}>
      <Header
        position="fixed"
        handleDrawerToggle={handleDrawerToggle}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />
      <NavigationDrawer
        matchDownMD={matchDownLG}
        open={open}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Box
        component="main"
        sx={{
          width: `calc(90% - ${computeDrawersWidth()}px)`,
          p: { xs: 2, sm: 3 },
          mt: 3, // this is the hight of the appbar (seems so)
          marginLeft: !isSmallScreen && open ? `${rightDrawerWidth}px` : 0,
        }}
      >
        <Outlet />
      </Box>
      <RightDrawer></RightDrawer>
    </Box>
  );
};

export default MainLayout;
