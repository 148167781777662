import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Logout from 'pages/authentication/logout';
import PrivateRoute from './PrivateRoute';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(
  lazy(() => import('pages/authentication/Register'))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      // This is a temporary measure to prevent the user from registering while the page is is development.
      element: <PrivateRoute child={<AuthRegister />} />
    },
    {
      path: 'logout',
      element: <Logout />
    }
  ]
};

export default LoginRoutes;
