// third-party
import { configureStore } from '@reduxjs/toolkit';
// project import
// import logger from 'redux-logger'
import reducers from './reducers';
import { projectMiddleware } from 'store/reducers/project_middleware';
// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([projectMiddleware])
});

const { dispatch } = store;

export { store, dispatch };
