import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ child }) => {
  const user = useSelector((state) => state.auth.user);
  const auth = useSelector((state) => state.auth);
  console.log('user is ', user);
  console.log('state.auth is ', auth);
  return user ? child : <Navigate to="/login" />;
};

export default PrivateRoute;
