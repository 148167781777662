import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEqual, throttle } from 'lodash';
import { AnyAction, Dispatch, Middleware } from 'redux';

import { Backend } from 'clipper/backend';
import {
  ModelComposer,
  ModelDefinitionByScenario
} from 'clipper/model_composer';
import { COMPOSER_REGISTRY } from 'clipper/model_composer_registry';
import {
  SimulationResult,
  SimulationResultUtils
} from 'clipper/model_elements';
import { Project, Scenario } from 'clipper/project';
import { StatusUtils } from 'clipper/status_utils';
import {
  remove_non_serialisable,
  setSimulationStatusForProject,
  setSimulationStatusForScenario
} from 'store/reducers/project';

const onScenarioSimulationResultReady = (
  scenario: Scenario,
  simulationResult: SimulationResult | null,
  _dispatch: Dispatch<AnyAction>
): Scenario => {
  if (simulationResult == null) {
    return {
      ...scenario,
      simulation_result: null,
      scenario_status: StatusUtils.makeFailedStatus(),
      bankrupcy_time: undefined
    };
  }

  const bankrupcyTime =
    SimulationResultUtils.identifyBankrupcy(simulationResult);
  const status =
    bankrupcyTime != null
      ? StatusUtils.makeBankrupcyStatus(bankrupcyTime)
      : StatusUtils.makeUpToDateStatus();

  return remove_non_serialisable({
    ...scenario,
    simulation_result: simulationResult,
    scenario_status: status,
    bankrupcy_time: bankrupcyTime
  });
};

const onScenarioSimulationResultError = (
  scenario: Scenario,
  _error: string,
  _dispatch: Dispatch<AnyAction>
): Scenario => {
  const status = StatusUtils.makeFailedStatus();

  return {
    ...scenario,
    scenario_status: status,
    bankrupcy_time: undefined,
    simulation_result: null
  };
};

function updateSimulationResultForScenario(
  backend: Backend,
  scenario: Scenario,
  dispatch: Dispatch<AnyAction>
): Promise<Scenario> {
  // set the simulation status for this scenario to pending

  dispatch(
    setSimulationStatusForScenario({
      scenarioName: scenario.name,
      status: StatusUtils.makePendingUpdateStatus()
    })
  );

  if (scenario.model_definition?.proto_model === undefined) {
    throw new Error(
      'Model definition is undefined for scenario: ' + scenario.name
    );
  }
  const backend_response = backend.run_model(
    scenario.model_definition?.proto_model
  );

  return backend_response
    .then((result) => {
      return onScenarioSimulationResultReady(scenario, result, dispatch);
    })
    .catch((error) => {
      console.error(
        'Server when calling backend from middleware error:',
        error
      );
      return onScenarioSimulationResultError(scenario, error, dispatch);
    });
}

// Returns the names of the scenarios which have updated model definitions.
// This is used to avoid calling the backend if the model definition has not changed.
function getUpdatedScenarios(
  project: Project,
  modelDefinitionByScenario: ModelDefinitionByScenario
): string[] {
  return Object.entries(modelDefinitionByScenario)
    .filter(([scenario_name, newModelDefinition]) => {
      const oldModelDefinition =
        project.scenarios[scenario_name]?.model_definition;
      return (
        oldModelDefinition === undefined ||
        oldModelDefinition.proto_model_serialized === undefined ||
        !isEqual(
          newModelDefinition.proto_model_serialized,
          oldModelDefinition.proto_model_serialized
        )
      );
    })
    .map(([scenario_name]) => scenario_name);
}

// Build proto mode definition from user inputs,calls the backend to get the new
// simulation results, and updates the simulation results in the redux store.
export const fetchAndUpdateSimulationResults = createAsyncThunk(
  'project/fetchAndUpdateSimulationResults',
  async (project: Project, thunkAPI): Promise<Scenario[]> => {
    if (project.model_composer_name === undefined) {
      console.warn('Model composer name is undefined');
      return [];
    }
    const model_composer: ModelComposer =
      COMPOSER_REGISTRY[project.model_composer_name]();
    if (model_composer === undefined) {
      throw new Error(
        'Model composer not found: ' + project.model_composer_name
      );
    }
    console.log('Model composer:', model_composer);
    //convert user inputs to proto model definitions
    const modelDefinitionByScenario: ModelDefinitionByScenario =
      model_composer.composeModelDefinition(project);

    console.log('Model definition by scenario:', modelDefinitionByScenario);

    const updatedModelDefinitionByScenario = getUpdatedScenarios(
      project,
      modelDefinitionByScenario
    );
    console.log('Updated scenarios:', updatedModelDefinitionByScenario);

    if (updatedModelDefinitionByScenario.length === 0) {
      return [];
    }

    const backend = Backend.getInstance();

    const promises = updatedModelDefinitionByScenario.map((scenarioName) =>
      updateSimulationResultForScenario(
        backend,
        {
          ...project.scenarios[scenarioName],
          model_definition: modelDefinitionByScenario[scenarioName]
        },
        thunkAPI.dispatch
      )
    );

    try {
      return await Promise.all(promises);
    } catch (error: any) {
      console.error(
        'Server when calling backend from middleware error:',
        error
      );
      throw thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Create a throttled version of fetchAndUpdateSimulationResults
// This ensures that the backend is not called too often.
const throttledFetchAndUpdate = throttle(
  (project: Project, dispatch: Dispatch<AnyAction>) => {
    dispatch(fetchAndUpdateSimulationResults(project) as any);
  },
  500,
  { leading: false, trailing: true }
);

// Middleware which listens for changes in the project state and triggers
// calls to the backend to update the simulation results when needed.
export const projectMiddleware: Middleware =
  ({ dispatch, getState }) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const returnValue = next(action);
    if (
      action.type === 'project/updateUserInputFormState' ||
      action.type === 'project/setProject' ||
      action.type === 'project/updateProjectSettings'
    ) {
      const project: Project = getState().project;
      const inputForms = Object.values(project.userInputFormStates);
      const isInputValid = inputForms.every((form) => form.isValid);

      if (isInputValid) {
        throttledFetchAndUpdate(project, dispatch);
      } else {
        dispatch(setSimulationStatusForProject(StatusUtils.makeStaleStatus()));
      }
    }

    return returnValue;
  };
