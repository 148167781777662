/**
 * Custom formatting for input fields.
 * Base on library https://s-yadav.github.io/react-number-format/
 * And MUI examples here: https://mui.com/material-ui/react-text-field/#helper-text
 */
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const NumberFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      allowLeadingZeros={false}
      allowNegative={false}
      thousandSeparator=" "
      valueIsNumericString
      decimalScale={2}
      // prefix="$"
    />
  );
});
