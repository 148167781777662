// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  // TODO: THis key is restricted to Identity Toolkit API (Firebase Authentication)
  // But we also need to restrict it to our domain and get a separate key for
  // development which will not be exposed to users.
  apiKey: 'AIzaSyBaOFQnmelx-0Ejj71xvStOEUgl8YAc8tU',
  authDomain: 'clipper-411413.firebaseapp.com',
  projectId: 'clipper-411413',
  storageBucket: 'clipper-411413.appspot.com',
  messagingSenderId: '463339568493',
  appId: '1:463339568493:web:05ae598a9bd18e82856e64',
  measurementId: 'G-M4PQWN44G7'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app, "gs://clipper-411413.appspot.com");

export {app, auth, storage};