// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import auth from './auth';
import simulation from './model_state';
import project from './project';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  simulation,
  auth,
  project
});

export default reducers;
