/*eslint-disable*/
import { useEffect, useRef, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Box,
  Drawer,
  IconButton,
  Toolbar,
  AppBar,
  Tooltip
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import COMPOSER_REGISTRY from 'components/user_inputs/InputFormRegistry';
import { rightDrawerWidth } from 'config';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import {
  selectUserInputFormStates,
  updateUserInputFormState
} from 'store/reducers/project';
import { useOutsideClick } from 'utils/useClickOutside';

// There is a lot of magic here about maanging the expanded/collapsed states of the accordions
// which are used to display the user input forms.
// Basically, since the project in redux can be changed and the number of forms can be changed,
// we need to update the state of the accordions accordingly.
const RightDrawer = () => {
  const userInputFormStates = useSelector(selectUserInputFormStates);
  const project_name = useSelector((state: any) => state.project.name);

  const userInputFormNames = Object.keys(userInputFormStates);

  const refPreviousProjectName = useRef(project_name);
  const refPreviousUserInputFormNames = useRef(userInputFormNames);

  // Copilot magic
  const [accordionExpandedByName, setAccordionExpandedByName] = useState<
    Record<string, boolean>
  >(
    Object.keys(userInputFormNames).reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    )
  );

  const makeDefaultAccordionStates = () => {
    const newAccordionExpandedByName = Object.keys(userInputFormStates).reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    );
    return newAccordionExpandedByName;
  };

  useEffect(() => {
    if (
      refPreviousProjectName.current === project_name &&
      userInputFormNames.every((key) =>
        refPreviousUserInputFormNames.current.includes(key)
      )
    ) {
      return;
    }
    refPreviousProjectName.current = project_name;
    refPreviousUserInputFormNames.current = userInputFormNames;

    const newAccordionExpandedByName = Object.keys(userInputFormStates).reduce(
      (acc, formName) => ({ ...acc, [formName]: true }),
      {}
    );

    setAccordionExpandedByName(newAccordionExpandedByName);
  }, [userInputFormStates, project_name]);

  let currentAccordionExpandedByName = accordionExpandedByName;
  if (refPreviousProjectName.current !== project_name) {
    currentAccordionExpandedByName = makeDefaultAccordionStates();
  } else if (
    userInputFormNames.some(
      (key) => !refPreviousUserInputFormNames.current.includes(key)
    )
  ) {
    currentAccordionExpandedByName = makeDefaultAccordionStates();
  }
  const handleAccordionChange = (name: string) => (isExpanded: boolean) => {
    const newState = { ...currentAccordionExpandedByName, [name]: isExpanded };
    setAccordionExpandedByName(newState);
  };

  const allExpanded = Object.values(currentAccordionExpandedByName).every(
    (value) => value
  );

  const handleToggleAll = () => {
    if (allExpanded) {
      // collapse all
      setAccordionExpandedByName(
        Object.keys(currentAccordionExpandedByName).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {}
        )
      );
    } else {
      // expand all
      setAccordionExpandedByName(
        Object.keys(currentAccordionExpandedByName).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        )
      );
    }
  };

  const dispatch = useDispatch();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [collapsed, setCollapsed] = useState(isSmallScreen);

  const handleOpenDrawer = () => {
    setCollapsed(false);
  };

  const drawerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    drawerRef,
    () => {
      if (!collapsed && isSmallScreen) {
        setCollapsed(true);
      }
    },
    [collapsed, isSmallScreen]
  );

  return (
    <>
      {/* This looks like conditional rendering without a condition. Very suspicious. */}
      {/* Because it's not a conditional rendering, we show button for small screens, and control drawer visibility using collapsed property */}
      //
      {isSmallScreen && (
        <Drawer
          anchor="right"
          variant="persistent"
          open={collapsed}
          sx={{
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            flexShrink: 0,
            width: '40px',
            [`& .MuiDrawer-paper`]: {
              width: '40px',
              boxSizing: 'border-box',
              overflowX: 'hidden',
              boxShadow: 'none',
              backgroundColor: 'transparent',
              border: 'none'
            }
          }}
        >
          <Toolbar />

          <Toolbar style={{ flexDirection: 'column' }}>
            <IconButton onClick={handleOpenDrawer}>
              <DragIndicatorIcon></DragIndicatorIcon>
            </IconButton>
          </Toolbar>
        </Drawer>
      )}
      {(
        <Drawer
          ref={drawerRef}
          anchor="right"
          variant="persistent"
          open={!collapsed}
          ModalProps={{ keepMounted: true }}
          sx={{
            width: rightDrawerWidth,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: rightDrawerWidth,
              boxSizing: 'border-box',
              overflowX: 'hidden',
              boxShadow: 'none'
            }
          }}
        >
          <Toolbar disableGutters={true} />
          <Box sx={{ overflow: 'auto' }}>
            <AppBar
              position="static"
              sx={{
                backgroundColor: theme.palette.divider,
                boxShadow: 'none'
              }}
            >
              <Toolbar
                sx={{ pl: 2, pr: 3, pt: 0, pb: 0, minHeight: '1' }}
                disableGutters={true}
              >
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  align="left"
                  color="text.primary"
                >
                  Model inputs
                </Typography>

                <Box sx={{ flexGrow: 1 }} />

                <Tooltip title={allExpanded ? 'Collapse All' : 'Expand All'}>
                  <IconButton
                    edge="end"
                    onClick={handleToggleAll}
                    aria-label="toggle"
                  >
                    {allExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </AppBar>
            <>
              {Object.entries(userInputFormStates).map(
                ([formName, formState], index) => {
                  const InputFormComponent = COMPOSER_REGISTRY[formName];
                  if (!InputFormComponent) {
                    throw new Error(
                      `No component found for form name: ${formName}`
                    );
                  }
                  return (
                    <Accordion
                      key={project_name + '_accordion_' + formName}
                      expanded={currentAccordionExpandedByName[formName]}
                      onChange={(event, isExpanded) =>
                        handleAccordionChange(formName)(isExpanded)
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}a-content`}
                        id={`panel${index + 1}a-header`}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {formState.displayName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ overflowX: 'hidden' }}>
                        <InputFormComponent
                          formState={formState}
                          onFormStateUpdate={(new_state: any) =>
                            dispatch(updateUserInputFormState(new_state))
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              )}
            </>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default RightDrawer;
