/* eslint-disable */
/*** Class responsible for building the model definitions for the buy vs rent scenario.
 *
 */
import { ModelComposer } from 'clipper/model_composer';
import { Project } from 'clipper/project';

import {
  ModelDefinition,
  CheckingAccount,
  Employment,
  Appartment,
  Mortgage,
  RealEstatePurchaseEvent,
  Expense,
  FinancialModel,
  ElementCategory,
  FinancialModelHelper,
  ModelElementTypes,
  ModelEventTypes,
  ModelElementWrapperUtils
} from 'clipper/model_elements';
import {
  DEFAULT_BASELINE_SCENARIO_NAME,
  DEFAULT_MAIN_SCENARIO_NAME
} from 'pages/buy_vs_rent/constants';
import { BuyVsRentUserInputs } from 'pages/buy_vs_rent/user_inputs_interface';
import { check } from 'prettier';

// Build the model definition object from the user input forms.
// I don't like how heavy it is now, but let's start from this.
const buildModelDefinition = (forms: BuyVsRentUserInputs): FinancialModel => {
  const assetsForm = forms.Assets;
  const propertyForm = forms.RealEstatePurchase.inputs.property;
  const financingPlanForm = forms.RealEstatePurchase.inputs.financingPlan;

  const checking_account: CheckingAccount = {
    metadata: {
      type: ElementCategory.CashAndEquivalent,
      name: 'Checking Account',
      export_final_state: false
    },
    balance: assetsForm.inputs.savings,
    reinvest_savings: assetsForm.inputs.reinvest_savings
  };
  const employment: Employment = {
    metadata: {
      type: ElementCategory.Other,
      name: 'Employment',
      export_final_state: false
    },
    salary: assetsForm.inputs.yearly_savings
  };
  const property: Appartment = {
    metadata: {
      type: ElementCategory.RealEstate,
      name: 'Appartment',
      export_final_state: false
    },
    value: propertyForm.inputs.purchase_value,
    maintenance_cost: propertyForm.inputs.maintenance_cost,
    purchase_fee: propertyForm.inputs.purchase_fee,
    tax: propertyForm.inputs.tax,
    growth_rate: propertyForm.inputs.growth_rate / 100
  };

  const mortgage: Mortgage = {
    metadata: {
      type: ElementCategory.Debt,
      name: 'Mortgage',
      export_final_state: true
    },
    principal: financingPlanForm.inputs.mortgage.principal,
    interest_rate: financingPlanForm.inputs.mortgage.rate / 100,
    duration_years: financingPlanForm.inputs.mortgage.duration
  };

  const purchaseEvent: RealEstatePurchaseEvent = {
    time: propertyForm.inputs.planned_year_of_purchase,
    start_of_period: true,
    contribution: financingPlanForm.inputs.equity,
    appartment: property,
    mortgage: mortgage
  };

  const rent: Expense = {
    amount: assetsForm.inputs.monthly_rent * 12,
    metadata: {
      type: ElementCategory.Other,
      name: 'Rent',
      time_period: {
        end_time: propertyForm.inputs.planned_year_of_purchase - 1
      },
      export_final_state: false
    }
  };

  const modelDefinition: FinancialModel = {
    elements: [
      {
        element: {
          type: ModelElementTypes.checking_account,
          value: checking_account
        }
      },
      { element: { type: ModelElementTypes.employment, value: employment } },
      { element: { type: ModelElementTypes.expense, value: rent } }
    ],
    events: [
      {
        event: {
          type: ModelEventTypes.real_estate_purchase,
          value: purchaseEvent
        }
      }
    ],

    featuring_element_type_by_name: {
      [checking_account.metadata.name]: ModelElementTypes.checking_account,
      [employment.metadata.name]: ModelElementTypes.employment,
      [property.metadata.name]: ModelElementTypes.appartment,
      [mortgage.metadata.name]: ModelElementTypes.mortgage,
      [rent.metadata.name]: ModelElementTypes.expense
    }
  };

  return modelDefinition;
};

const buildBaselineModelDefinition = (
  forms: BuyVsRentUserInputs
): FinancialModel => {
  const assetsForm = forms.Assets;

  const checking_account: CheckingAccount = {
    metadata: {
      type: ElementCategory.CashAndEquivalent,
      export_final_state: false,
      name: 'Checking Account'
    },
    balance: assetsForm.inputs.savings,
    reinvest_savings: assetsForm.inputs.reinvest_savings
  };
  const employment: Employment = {
    metadata: {
      type: ElementCategory.Other,
      export_final_state: false,
      name: 'Employment'
    },
    salary: assetsForm.inputs.yearly_savings
  };

  const rent: Expense = {
    amount: assetsForm.inputs.monthly_rent * 12,
    metadata: {
      type: ElementCategory.Other,
      name: 'Rent',
      export_final_state: false
    }
  };

  const modelDefinition: FinancialModel = {
    elements: [
      {
        element: {
          type: ModelElementTypes.checking_account,
          value: checking_account
        }
      },
      { element: { type: ModelElementTypes.employment, value: employment } },
      { element: { type: ModelElementTypes.expense, value: rent } }
    ],
    events: [],
    featuring_element_type_by_name: {
      [checking_account.metadata.name]: ModelElementTypes.checking_account,
      [employment.metadata.name]: ModelElementTypes.employment,
      [rent.metadata.name]: ModelElementTypes.expense
    }
  };

  return modelDefinition;
};

const ApplyProjectSettings = (model: FinancialModel, project: Project) => {
  model.start_time = project.project_settings.model_start_year;
  model.end_time = project.project_settings.model_end_year;
};

export class BuyVsRentModelComposer implements ModelComposer {
  // eslint-disable-next-line
  constructor(_args?: any) {}

  composeModelDefinition(project: Project) {
    const forms = project.userInputFormStates as BuyVsRentUserInputs;
    const modelDefinitionByScenario: { [key: string]: ModelDefinition } = {};

    const baselineModelDefinition = buildBaselineModelDefinition(forms);
    ApplyProjectSettings(baselineModelDefinition, project);
    const baseline_model_proto = FinancialModelHelper.toProto(
      baselineModelDefinition
    );

    const mainModelDefinition = buildModelDefinition(forms);
    ApplyProjectSettings(mainModelDefinition, project);
    const main_model_proto = FinancialModelHelper.toProto(mainModelDefinition);

    modelDefinitionByScenario[DEFAULT_BASELINE_SCENARIO_NAME] = {
      financial_model: baselineModelDefinition,
      proto_model: baseline_model_proto,
      proto_model_serialized: Array.from(
        baseline_model_proto.serializeBinary()
      ).join(',')
    };

    modelDefinitionByScenario[DEFAULT_MAIN_SCENARIO_NAME] = {
      financial_model: mainModelDefinition,
      proto_model: main_model_proto,
      proto_model_serialized: Array.from(
        main_model_proto.serializeBinary()
      ).join(',')
    };
    return modelDefinitionByScenario;
  }
}
