import { SimulationResult, ModelDefinition } from 'clipper/model_elements';
import { ColorPalette } from 'clipper/scenario_colors';
export enum SimulationStatus {
  UP_TO_DATE = 'upToDate',
  STALE = 'stale',
  PENDING_UPDATE = 'pendingUpdate',
  FAILED = 'failed'
}
import { RegisteredApplicationId } from 'clipper/application';

export interface ScenarioStatus {
  status: SimulationStatus;
  status_message: string;
}

/** A Scenario describes a single model within a project. */
export interface Scenario {
  name: string;
  color_palette: ColorPalette;
  model_definition?: ModelDefinition;
  simulation_result: SimulationResult | null;
  scenario_status: ScenarioStatus | undefined;
  bankrupcy_time: number | undefined;
}

export interface UserInputFormState {
  name: string; // This is the internal unique name of the form, which is immutable
  displayName?: string; // This is the display name of the form, which can be changed
  inputs: any;
  isValid: boolean; // Checks if the current user input is valid (might be different from the one commited to redux)
}

export interface ProjectSettings {
  model_start_year: number;
  model_end_year: number;
}

/** State of the project */
export interface Project {
  name: string;
  scenarios: { [key: string]: Scenario };

  // For now only project-level forms are supported, but in the future  will support forms at the scenario level
  // to support scenarios with different architectures.
  userInputFormStates: { [key: string]: UserInputFormState };
  isInputValid: boolean;

  // the name of class from composer_registry which is responsible for composing the model definition from user inputs
  model_composer_name?: string;

  project_settings: ProjectSettings;
  project_status: ScenarioStatus; // an aggregate status of all scenarios

  registered_application_id: RegisteredApplicationId;
}

export interface PointInTime {
  time: number;
  value: number | undefined;
}

// General time series representation not tied to any specific library
export interface TimeSeries {
  points: PointInTime[];
  name: string;
}

export type IndexableType = { [key: string]: any };
export interface ElementSimulationResult<T extends IndexableType> {
  scenario_name: string | undefined;
  element_name: string;
  state_in_time: {
    time: number;
    state: T;
  }[];
}

export interface ElementSimulationStateByScenario<T extends IndexableType> {
  [scenarioName: string]: ElementSimulationResult<T>;
}
