import Dialog from '@mui/material/Dialog';
import { DialogActions, Button, DialogContent } from '@mui/material';
import LoginForm from './LoginForm';

const LoginDialogButton = ({open, onClose}) => {

return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <LoginForm></LoginForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
    );
};

export default LoginDialogButton;
