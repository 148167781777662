import { Scenario, ScenarioStatus, SimulationStatus } from 'clipper/project';

export class StatusUtils {
  static aggregateScenarioStatus(scenarios: Scenario[]): ScenarioStatus {
    const statusByScenario: Record<string, ScenarioStatus> = scenarios.reduce(
      (acc, scenario) => {
        if (scenario.scenario_status !== undefined) {
          acc[scenario.name] = scenario.scenario_status;
        }
        return acc;
      },
      {} as Record<string, ScenarioStatus>
    );

    return StatusUtils.aggregateStatus(statusByScenario);
  }
  // TODO: make the description of the status more user-friendly
  static aggregateStatus(
    scenarioStatuses: Record<string, ScenarioStatus>
  ): ScenarioStatus {
    const failedScenarios = Object.entries(scenarioStatuses)
      .filter(([_, status]) => status.status === SimulationStatus.FAILED)
      .map(([name, _]) => name);

    if (failedScenarios.length > 0) {
      return {
        status: SimulationStatus.FAILED,
        status_message: 'Failed scenarios: ' + failedScenarios.join(', ')
      };
    }

    const statuses = Object.values(scenarioStatuses).map(
      (status) => status.status
    );
    if (statuses.includes(SimulationStatus.PENDING_UPDATE)) {
      return {
        status: SimulationStatus.PENDING_UPDATE,
        status_message: 'One or more scenarios are pending update'
      };
    }
    if (statuses.includes(SimulationStatus.STALE)) {
      return {
        status: SimulationStatus.STALE,
        status_message: 'One or more scenarios are stale'
      };
    }
    return {
      status: SimulationStatus.UP_TO_DATE,
      status_message: 'All scenarios are up to date'
    };
  }

  // Default project status for stale inputs
  static makeStaleStatus(): ScenarioStatus {
    return {
      status: SimulationStatus.STALE,
      status_message: 'Model is stale'
    };
  }

  // Default project status for pending update
  static makePendingUpdateStatus(): ScenarioStatus {
    return {
      status: SimulationStatus.PENDING_UPDATE,
      status_message: 'Pending update'
    };
  }

  static makeFailedStatus(): ScenarioStatus {
    return {
      status: SimulationStatus.FAILED,
      status_message: 'Failed'
    };
  }

  static makeUpToDateStatus(): ScenarioStatus {
    return {
      status: SimulationStatus.UP_TO_DATE,
      status_message: 'Up to date'
    };
  }

  static makeBankrupcyStatus(bankrupcyTime: number): ScenarioStatus {
    return {
      status: SimulationStatus.FAILED,
      status_message: `Bankrupcy in ${bankrupcyTime}`
    };
  }
}
