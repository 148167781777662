/**
 * The website will contain several Applications (e.g. Buy vs Rent).
 * This file contains the definition of the Application.
 */
import BuyVsRentLongInfoHelp from 'pages/buy_vs_rent/help';

// The id of the application, stored in redux
export enum RegisteredApplicationId {
  BuyVsRent = 'BuyVsRent',
  Unknown = 'Unknown'
}

// A service prvided to the user, e.g. Buy vs Rent
export interface ClipperApplication {
  id: RegisteredApplicationId;
  display_name: string;
  help_component?: () => JSX.Element;
}

export const ClipperApplicationById: {
  [key in RegisteredApplicationId]: ClipperApplication;
} = {
  [RegisteredApplicationId.BuyVsRent]: {
    id: RegisteredApplicationId.BuyVsRent,
    display_name: 'Buy vs Rent',
    help_component: BuyVsRentLongInfoHelp
  },
  [RegisteredApplicationId.Unknown]: {
    id: RegisteredApplicationId.Unknown,
    display_name: 'Unknown'
  }
};
