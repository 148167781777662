// Color palette for the scenario colors and logic to assign colors to time series

import React from 'react';

export enum ColorPalette {
  Blues = 'blues',
  Greens = 'greens',
  Oranges = 'oranges',
  Violets = 'violets'
}

const SCENARIO_COLORS = {
  blues: {
    Assets: [
      '#f7fbff',
      '#e0ecf7',
      '#cbdef0',
      '#abcfe5',
      '#81bada',
      '#58a1ce',
      '#3787c0',
      '#1b69af'
      // '#084d96'
      // '#08306b'
    ].reverse(),
    Liabilities: [
      '#fff5eb',
      '#fee7d1',
      '#fdd4ab',
      '#fdb97d',
      '#fd9b50',
      '#f77d29',
      '#e95d0d',
      '#cd4401'
      // '#a13403'
      // '#7f2704'
    ].reverse()
  },
  greens: {
    Assets: [
      '#f7fcf5',
      '#e7f5e2',
      '#cdebc7',
      '#addea7',
      '#88cd86',
      '#5db86a',
      '#37a055',
      '#1b843f'
      // '#00682a'
      // '#00441b'
    ].reverse(),
    Liabilities: [
      '#fff5f0',
      '#fee2d5',
      '#fcc3ab',
      '#fc9f81',
      '#fb7b5b',
      '#f5553c',
      '#e32f27',
      '#c2161b'
      // '#9e0d14'
      // '#67000c'
    ].reverse()
  },
  oranges: {
    Assets: [
      '#fff5eb',
      '#fee7d1',
      '#fdd4ab',
      '#fdb97d',
      '#fd9b50',
      '#f77d29',
      '#e95d0d',
      '#cd4401'
      // '#a13403'
      // '#7f2704'
    ].reverse(),
    Liabilities: [
      '#f7fbff',
      '#e0ecf7',
      '#cbdef0',
      '#abcfe5',
      '#81bada',
      '#58a1ce',
      '#3787c0',
      '#1b69af'
      // '#084d96'
      // '#08306b'
    ].reverse()
  },
  violets: {
    Assets: [
      '#fcfbfd',
      '#f0eef5',
      '#dedeed',
      '#c6c6e1',
      '#aba9d0',
      '#908dc1',
      '#786eb2',
      '#65479e'
      // '#51228d'
      // '#3f007d'
    ].reverse(),
    Liabilities: [
      '#f7fcf5',
      '#e7f5e2',
      '#cdebc7',
      '#addea7',
      '#88cd86',
      '#5db86a',
      '#37a055',
      '#1b843f'
      // '#00682a'
      // '#00441b'
    ].reverse()
  }
};

type PaletteName = 'blues' | 'greens' | 'oranges' | 'violets';
export enum ColorCategory {
  Assets = 'Assets',
  Liabilities = 'Liabilities'
}

/**
 * Selects colors from the palette for a given category
 * @param paletteName Name of the palette
 * @param category Category of the colors
 * @param count Number of colors to select
 */
export function selectColors(
  paletteName: PaletteName,
  category: ColorCategory,
  count: number
): string[] {
  const palette = SCENARIO_COLORS[paletteName];
  if (!palette) {
    throw new Error(`Palette "${paletteName}" not found.`);
  }

  const colors = palette[category];
  if (!colors) {
    throw new Error(
      `Category "${category}" not found in palette "${paletteName}".`
    );
  }

  // Select elements with a step of 3
  const selectedColors = colors.filter((_, index) => index % 3 === 0);

  // Select the first 'count' colors from the array
  return selectedColors.slice(0, count);
}

const ColorDisplay: React.FC = () => {
  return (
    <div>
      {Object.entries(SCENARIO_COLORS).map(([scenario, colors]) => (
        <div key={scenario}>
          <h2>{scenario}</h2>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {Object.entries(colors).map(([type, colorArray]) => (
              <div key={type}>
                <h3>{type}</h3>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {colorArray.map((color, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: color,
                        width: '50px',
                        height: '50px',
                        margin: '5px'
                      }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ColorDisplay;
