import { Project, Scenario, SimulationStatus } from 'clipper/project';
import { BuyVsRentUserInputs } from 'pages/buy_vs_rent/user_inputs_interface';
import { UserInputFormState } from 'clipper/project';
import { AssetFormState } from 'components/user_inputs/AssetsForm';
import { RealEstatePurchaseFormState } from 'components/user_inputs/RealEstatePurchaseForm';
import { PropertyFormState } from 'components/user_inputs/PropertyForm';
import { FinancingPlanFormState } from 'components/user_inputs/FinancingPlanForm';
import { ColorPalette } from 'clipper/scenario_colors';
import { RegisteredApplicationId } from 'clipper/application';
export const DEFAULT_MAIN_SCENARIO_NAME = 'Buy';
export const DEFAULT_BASELINE_SCENARIO_NAME = 'Rent';
export const MORTGAGE_ELEMENT_NAME = 'Mortgage';
export const CHECKING_ACCOUNT_ELEMENT_NAME = 'Checking Account';

const initialBaselineScenario: Scenario = {
  name: DEFAULT_BASELINE_SCENARIO_NAME,
  simulation_result: null,
  color_palette: ColorPalette.Blues,
  scenario_status: {
    status: SimulationStatus.STALE,
    status_message: ''
  },
  bankrupcy_time: undefined
};

const initialMainScenario: Scenario = {
  name: DEFAULT_MAIN_SCENARIO_NAME,
  color_palette: ColorPalette.Greens,
  simulation_result: null,
  scenario_status: {
    status: SimulationStatus.STALE,
    status_message: ''
  },
  bankrupcy_time: undefined
};

const initialAssetFormState: AssetFormState = {
  name: 'Assets',
  displayName: 'Assets',
  inputs: {
    yearly_savings: 180000,
    savings: 500000,
    reinvest_savings: false,
    monthly_rent: 2300,
    netIncomeErrorMessage: '',
    rentErrorMessage: ''
  },
  isValid: true
};

const initialPropertyFormState: PropertyFormState = {
  name: 'PropertyForm',
  inputs: {
    purchase_value: 2000000,
    planned_year_of_purchase: 2026,
    planned_year_of_purchase_error_message: '',
    maintenance_cost: 15000,
    purchase_fee: 50000,
    tax: 20000,
    growth_rate: 0.0
  },
  isValid: true
};

const initialFinancingPlanFormState: FinancingPlanFormState = {
  name: 'FinancingPlanForm',
  inputs: {
    equity: 400000,
    equityErrorMessage: '',
    mortgage: {
      principal: 1600000,
      duration: 20,
      rate: 2.5,
      monthly_payment: 0,
      principalErrorMessage: ''
    }
  },
  isValid: true
};

const initalRealEstatePurchaseFormState: RealEstatePurchaseFormState = {
  name: 'RealEstatePurchase',
  displayName: 'Real Estate Purchase',
  inputs: {
    property: initialPropertyFormState,
    financingPlan: initialFinancingPlanFormState
  },
  isValid: true
};

export const initialFormsState: BuyVsRentUserInputs = {
  Assets: initialAssetFormState,
  RealEstatePurchase: initalRealEstatePurchaseFormState
};

export const BuyVsRentInitialProjectState: Project = {
  name: 'Buy vs Rent',
  scenarios: {
    [DEFAULT_BASELINE_SCENARIO_NAME]: initialBaselineScenario,
    [DEFAULT_MAIN_SCENARIO_NAME]: initialMainScenario
  },
  userInputFormStates: initialFormsState as {
    [key: string]: UserInputFormState;
  },
  isInputValid: false,
  model_composer_name: 'BuyVsRent',
  project_settings: {
    model_start_year: new Date().getFullYear(),
    model_end_year: new Date().getFullYear() + 30
  },
  project_status: {
    status: SimulationStatus.STALE,
    status_message: 'Initialized'
  },
  registered_application_id: RegisteredApplicationId.BuyVsRent
};
