import React from 'react';
import Typography from '@mui/material/Typography';
import MainCard from 'components/MainCard';

const TermsOfUse = () => {
  return (
    <MainCard title="Terms of Use">
      <Typography variant="body1">Effective Date: February 10, 2024</Typography>

      <Typography variant="body1">
        Welcome to TODO (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;). By
        accessing or using our website and services, you (&quot;User&quot;,
        &quot;you&quot;) agree to be bound by these Terms of Use
        (&quot;Terms&quot;). If you do not agree to these Terms, please do not
        use our services.
      </Typography>

      <Typography variant="h4">Service Description</Typography>
      <Typography variant="body1">
        Our website provides a simulation tool designed to assist users in
        building financial models (&quot;Service&quot;). The Service functions
        similarly to spreadsheet software like Excel, offering computational
        tools without offering financial advice. Our Service is intended for
        educational and informational purposes only.
      </Typography>

      <Typography variant="h4">Disclaimer of Financial Advice</Typography>
      <Typography variant="body1">
        The Service is provided as a tool only and does not constitute financial
        advice. We are not financial advisors. The information and outputs
        generated by our Service are based on your inputs and are not to be
        interpreted as financial, legal, or tax advice. We make no
        representations or warranties regarding the accuracy or completeness of
        the information provided through the Service.
      </Typography>

      <Typography variant="h4">Use of the Service</Typography>
      <Typography variant="body1">
        You agree to use the Service only for lawful purposes and in accordance
        with these Terms. You are responsible for all information you input into
        the Service. Misuse or unauthorized use of the Service may result in
        termination of your access to the Service.
      </Typography>

      <Typography variant="h4">Intellectual Property Rights</Typography>
      <Typography variant="body1">
        All content, features, and functionality on our site, including the
        simulation tool, are owned by us, our licensors, or other providers of
        such material and are protected by copyright and other intellectual
        property laws.
      </Typography>

      <Typography variant="h4">Limitation of Liability</Typography>
      <Typography variant="body1">
        To the fullest extent permitted by applicable law, we shall not be
        liable for any direct, indirect, incidental, special, consequential, or
        punitive damages arising out of or related to your use of the Service or
        inability to use the Service, any content on the Service, or any
        services obtained through the Service.
      </Typography>

      <Typography variant="h4">Data Protection</Typography>
      <Typography variant="body1">
        Your privacy is important to us. Our Privacy Notice, which details how
        we collect, use, and protect your personal data, is incorporated into
        these Terms by reference. We process all personal data collected through
        the Service in compliance with the General Data Protection Regulation
        (GDPR).
      </Typography>

      <Typography variant="h4">Changes to the Terms</Typography>
      <Typography variant="body1">
        We reserve the right to update or modify these Terms at any time without
        prior notice. Your continued use of the Service after any changes
        indicates your acceptance of the new Terms.
      </Typography>

      <Typography variant="h4">Age Restriction</Typography>
      <Typography variant="body1">
        The Service is not directed to individuals under the age of 18. We do
        not knowingly collect personal information from children under 18. If
        you are under 18, you are not permitted to use our Service or provide
        any personal information to us. If we become aware that we have
        collected personal information from a child under the age of 18 without
        verification of parental consent, we will take steps to remove that
        information from our servers.
      </Typography>

      <Typography variant="h4">Governing Law</Typography>
      <Typography variant="body1">
        These Terms shall be governed by and construed in accordance with the
        laws of Switzerland, without giving effect to any principles of
        conflicts of law.
      </Typography>
    </MainCard>
  );
};

export default TermsOfUse;
