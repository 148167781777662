import Dialog from '@mui/material/Dialog';
import { nanoid } from 'nanoid';
import { DialogActions, Button, DialogContent } from '@mui/material';
import { useSelector } from 'react-redux';
import {  ref, uploadString } from "firebase/storage";
import { useState, useEffect } from 'react';
import { storage } from './../../utils/firebase.js';
import { Link } from '@mui/material';

async function storeSerializedState(serializedState) {
    const uid = nanoid(7);

    const storageRef = ref(storage, `shared_states/${uid}.json`);

    console.log(storageRef)
    
    await uploadString(storageRef, serializedState, 'raw')
    .then((_) => {
      console.log('Uploaded a raw string!');
      return uid;
    });

    console.log("finished storing serialized state")
    
    return uid;
}

const ShareDialog = ({open, onClose}) => {
    // eslint-disable-next-line no-unused-vars
    const { auth, ...stateWithoutAuth } = useSelector(state => state);

    const [id, setId] = useState(0);


    const handleCopy = () => {
        navigator.clipboard.writeText(id)
          .then(() => {
            console.log('Project ID copied to clipboard');
          })
          .catch(err => {
            console.error('Could not copy text: ', err);
          });
      };
    

    useEffect(() => {
        if (open) {
            const serialized = JSON.stringify(stateWithoutAuth);

            storeSerializedState(serialized).then(docId => {
                setId(docId);
            }).catch(error => {
                console.log("Error storing serialized state: ", error)
            });
        }
    }, [open]);

    return (
        <>
            <Dialog open={open}>
                <DialogContent>
                    <div>
                    Project ID: <Link href={`https://your-url/${id}`}>{id}</Link>
                    </div>
                </DialogContent>
                    <DialogActions> 
                        <Button onClick={onClose} color="primary">
                        Close
                        </Button>
                        <Button onClick={handleCopy} color="primary">
                        Copy Project ID
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    );
};

export default ShareDialog;
