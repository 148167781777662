// useAuth.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from 'utils/firebase';
import { setUser } from 'store/reducers/auth';

export const useAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // Simulate an authenticated user in development mode
      const mockUser = {
        uid: '123',
        email: 'test@example.com',
        displayName: 'Test User',
        photoURL: 'https://example.com/test-user.jpg'
      };
      dispatch(setUser(mockUser));
    } else {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          // Create a plain JavaScript object with only the user data you need
          const userData = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL
          };

          dispatch(setUser(userData)); // dispatch the setUser action with the user data
        } else {
          dispatch(setUser(null)); // dispatch the setUser action with null
        }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [dispatch]);

  // No need to return anything, as the auth state can be accessed via Redux
};
