// material-ui
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import ProjectSettingsHeadline from 'components/settings/ProjectSettingsHeadline';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectApplication } from 'store/reducers/project';
import SimulationStatus from './SimulationStatus';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const application = useSelector(selectApplication);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ width: '10px', ml: 1 }} />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Box sx={{ pt: 1 }}>
            <Typography variant="h4">{application.display_name}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ flexGrow: 1 }} />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <IconButton onClick={handleOpen}>
                <HelpIcon />
              </IconButton>
              <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                  {application.help_component && application.help_component()}
                </DialogContent>
                <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item>
              <ProjectSettingsHeadline />
            </Grid>
            <Grid item>
              <Box sx={{ pt: 0 }}>
                <SimulationStatus />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderContent;
