import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import PrivacyNotice from 'pages/legal/PrivacyNotice';
import TermsOfService from 'pages/legal/TermsOfService';
import About from 'pages/About';
import MainLayout from 'layout/MainLayout';
// import PrivateRoute from './PrivateRoute';
// import WrapInPrivateRoute from './PrivateRoute';

//eslint-disable-next-line
const BuyVsRent = Loadable(lazy(() => import('pages/buy_vs_rent')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));
import ColorDisplay from 'clipper/scenario_colors';

// render - utilities
const Typography = Loadable(
  lazy(() => import('pages/components-overview/Typography'))
);
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(
  lazy(() => import('pages/components-overview/AntIcons'))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="buy-vs-rent" replace />,
      index: true
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'buy-vs-rent',
      element: <BuyVsRent />
    },
    {
      path: 'buy-vs-rent/:id',
      element: <BuyVsRent />
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    { path: 'colors', element: <ColorDisplay /> },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    },
    {
      path: 'privacy-notice',
      element: <PrivacyNotice />
    },
    {
      path: 'terms-of-service',
      element: <TermsOfService />
    },
    {
      path: 'about',
      element: <About />
    }
  ]
};

export default MainRoutes;
