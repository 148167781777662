// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { auth } from 'utils/firebase';

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
      auth.signOut();
    }
  }
});

export const { setUser, logout } = authSlice.actions;

export default authSlice.reducer;
