import IosShareIcon from '@mui/icons-material/IosShare';
import IconButton from '@mui/material/IconButton';
import { useState, useEffect } from 'react';
import LoginDialog from '../login/LoginDialog';
import ShareDialog from './ShareDialog';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth();

const ShareDialogButton = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
  
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
          }
        });
    
        return () => unsubscribe();
      }, []);


    const handleOpen = () => {
      setOpenDialog(true);
    };
  
    const handleClose = () => {
      setOpenDialog(false);
    };
  
    return (
      <>
        <IconButton onClick={handleOpen}>
          <IosShareIcon />
        </IconButton>
        {isLoggedIn ? (
          <ShareDialog open={openDialog} onClose={handleClose} />
        ) : (
          <LoginDialog open={openDialog} onClose={handleClose} />
        )}
      </>
    );
  };

export default ShareDialogButton;
