import {
  SimulationResult,
  SimulationResultUtils
} from 'clipper/model_elements';
import { clipper } from 'clipper/generated_simulation_model';
import config from 'config';

/**
 * Use this class to call the backend.
 *
 * Backend is a singleton, so you can use `Backend.getInstance()` to get the instance.
 */
export class Backend {
  private static instance: Backend;
  private baseUrl: string;

  private constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public static getInstance(): Backend {
    if (!Backend.instance) {
      Backend.instance = new Backend(config.backendAddress!);
    }
    return Backend.instance;
  }

  async run_serialized_model(
    serialized_model: Uint8Array
  ): Promise<SimulationResult> {
    const response_data = await this._post_binary(
      '/model_binary',
      serialized_model
    );
    const result = clipper.SimulationResult.deserializeBinary(
      new Uint8Array(response_data)
    );
    return SimulationResultUtils.fromProto(result);
  }

  // TODO: take as input not the raw proto but the wrapper class
  async run_model(model: clipper.FinancialModel): Promise<SimulationResult> {
    return this.run_serialized_model(model.serializeBinary());
  }

  async _post_binary(path: string, data: ArrayBuffer): Promise<ArrayBuffer> {
    const response = await fetch(this.baseUrl + path, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${config.backendToken}`
      }
    });
    if (!response.ok) {
      throw new Error(`POST request failed: ${response.status}`);
    }
    return await response.arrayBuffer();
  }
  // Add more methods for other HTTP methods (PUT, DELETE, etc.) as needed
}
