/**
 * Card component which contains docs and information text for the user.
 */
import { CardProps } from '@mui/material/Card';
import React, { forwardRef, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  // Box
  Grid
} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

// header style
const headerSX = {
  p: 2.5,
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

interface InformationalCardProps extends CardProps {
  border?: boolean;
  boxShadow?: string;
  hasContent?: boolean; // renamed from 'content'
  contentSX?: object;
  darkTitle?: boolean;
  elevation?: number;
  secondary?: React.ReactNode;
  shadow?: string;
  sx?: object;
  title?: string;
  subheader?: string;
  codeHighlight?: boolean;
  children?: React.ReactNode;
  help_dialog_component?: () => JSX.Element;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = (props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  const theme = useTheme();

  return (
    <IconButton
      {...other}
      style={{
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        // marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest
        })
      }}
    />
  );
};

const InformationalCard = forwardRef<HTMLDivElement, InformationalCardProps>(
  (
    {
      border = true,
      boxShadow: boxShadowProp,
      children,
      title,
      subheader,
      sx = {
        mt: 1.5,
        display: 'flex',
        flexDirection: 'column'
      },
      contentSX = {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical'
      },
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    const [shortInfo, longInfo] = React.Children.toArray(children);

    //eslint-disable-next-line
    const [expanded, setExpanded] = useState(false);
    const boxShadow =
      theme.palette.mode === 'dark' ? boxShadowProp || true : boxShadowProp;

    const handleExpandClick = () => {
      requestAnimationFrame(() => {
        setExpanded(!expanded);
      });
    };

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Card
          elevation={0}
          ref={ref}
          {...others}
          sx={{
            border: border ? '1px solid' : 'none',
            borderRadius: 2,
            borderColor: (theme.palette.grey as any)['A800'],
            boxShadow:
              boxShadow && !border
                ? (theme as any).customShadows?.z1
                : 'inherit',
            ':hover': {
              boxShadow: boxShadow
                ? (theme as any).customShadows?.z1
                : 'inherit'
            },
            '& pre': {
              m: 0,
              p: '16px !important',
              fontFamily: theme.typography.fontFamily,
              fontSize: '0.75rem'
            },
            ...sx
          }}
        >
          {/* card header and action */}
          {title && (
            <>
              <CardHeader
                sx={headerSX}
                titleTypographyProps={{ variant: 'subtitle1' }}
                title={title}
                subheader={subheader}
              />
            </>
          )}
          {/* card content */}
          <CardContent
            sx={{
              ...contentSX,
              pb: 0,
              pt: title ? 0 : 2
            }}
          >
            {shortInfo}
          </CardContent>

          {longInfo && (
            <CardActions
              sx={{
                pb: 0,
                pt: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
          )}
          {longInfo && (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>{longInfo}</CardContent>
            </Collapse>
          )}
        </Card>
      </Grid>
    );
  }
);

export default InformationalCard;
type ShortInfoProps = {
  children: React.ReactNode;
};

export const ShortInfo: React.FC<ShortInfoProps> = ({ children }) => (
  <>{children}</>
);

type LongInfoProps = {
  children: React.ReactNode;
};

export const LongInfo: React.FC<LongInfoProps> = ({ children }) => (
  <>{children}</>
);
