// project import
import ThemeRoutes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { useAuth } from 'utils/useAuth';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  useAuth();
  return (
    <>
      <ThemeCustomization>
        <ScrollTop>
          <ThemeRoutes />
        </ScrollTop>
      </ThemeCustomization>
    </>
  );
};

export default App;
